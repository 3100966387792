/* Ana vardiya tablosu stillemesi */
.shift-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.03);
  border: 1px solid #edf2f7;
  background-color: #ffffff;
}

/* Başlık hücreleri */
.shift-table th {
  padding: 16px 12px;
  font-weight: 600;
  vertical-align: middle;
  background-color: #f9fafb;
  border: 1px solid #edf2f7;
  transition: all 0.2s;
  position: sticky;
  top: 0;
  z-index: 10;
  color: #4a5568;
  font-size: 14px;
}

.shift-table th:first-child {
  border-top-left-radius: 12px;
}

.shift-table th:last-child {
  border-top-right-radius: 12px;
}

/* Vardiya satırları ve hücreleri */
.shift-table td {
  padding: 12px;
  vertical-align: middle;
  border: 1px solid #edf2f7;
  transition: all 0.3s ease;
}

/* Vardiya isimlerinin gösterildiği sol sütun */
.shift-table td:first-child {
  background-color: #f9fafb;
  font-weight: 500;
  border-right: 2px solid #edf2f7;
}

/* Vardiya hücreleri */
.shift-table .shift-cell {
  min-height: 100px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/* Daha yumuşak hover efekti */
.shift-table .shift-cell:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(235, 244, 255, 0);
  box-shadow: inset 0 0 0 0 rgba(66, 153, 225, 0);
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  pointer-events: none;
  z-index: 1;
}

.shift-table .shift-cell:hover:before {
  background-color: rgba(235, 244, 255, 0.5);
  box-shadow: inset 0 0 0 1px rgba(66, 153, 225, 0.4);
}

/* Tüm içerik z-index: 2 ile üstte kalmalı */
.shift-cell > * {
  position: relative;
  z-index: 2;
}

/* Vardiya atanmış kişilerin kartları */
.shift-cell .person-card {
  background-color: white;
  border-radius: 8px;
  padding: 8px 10px;
  margin-bottom: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.04);
}

/* YENİ: Daha zarif kart hover efekti */
.shift-cell .person-card:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-color: rgba(66, 153, 225, 0.3);
  background-color: #fafcff;
}

/* Kişi avatarları için stil */
.shift-cell .avatar-xs {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  font-weight: bold;
  color: white;
  font-size: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Boş hücreler için ekle butonu */
.shift-cell .add-shift {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #cbd5e0;
  font-size: 13px;
  min-height: 80px;
  transition: all 0.3s ease;
  opacity: 0.7;
}

.shift-cell:hover .add-shift {
  color: #4299e1;
  opacity: 1;
}

/* Bugünün hücreleri için vurgu */
.shift-cell.today {
  background-color: rgba(235, 244, 255, 0.4);
  box-shadow: inset 0 0 0 1px rgba(66, 153, 225, 0.2);
}

/* Hafta sonu hücreleri */
.shift-cell.weekend {
  background-color: #f9fafc;
}

/* Kişi sayısı badge'i */
.shift-cell .more-badge {
  text-align: center;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 20px;
  background-color: #f1f5f9;
  color: #4a5568;
  display: inline-block;
  margin-top: 5px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.shift-cell:hover .more-badge {
  background-color: #e2e8f0;
}

/* Duyarlı tasarım ayarları */
@media (max-width: 992px) {
  .shift-table {
    min-width: 850px;
    font-size: 13px;
  }
  
  .shift-cell .avatar-xs {
    width: 26px;
    height: 26px;
    font-size: 10px;
  }
}

/* Eğer vardiya sayısı az ise table responsive içinde scroll çubuğunu gizle */
@media (min-width: 1200px) {
  .table-responsive:has(.shift-table tr:nth-child(-n+5)) {
    overflow-y: hidden;
  }
}