.custom-card {
    border: none;
    border-radius: 1rem;
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
}

.form-label {
    color: #344767;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.input-group-text {
    border: none;
    color: #4361ee;
}

.form-control, .form-select {
    border-color: #e9ecef;
    padding: 0.6rem 1rem;
    font-size: 0.875rem;
}

.form-control:focus, .form-select:focus {
    border-color: #4361ee;
    box-shadow: 0 0 0 0.2rem rgba(67, 97, 238, 0.1);
}

select[multiple] {
    background-image: none;
}

.btn-check:checked + .btn-outline-success {
    background-color: #0fb77b !important;
    border-color: #0fb77b !important;
    color: white !important;
}

.btn-check:checked + .btn-outline-warning {
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
    color: white !important;
}

.btn-check:checked + .btn-outline-danger {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    color: white !important;
}

.target-selection {
    background-color: #f8f9fa;
    border-radius: 0.5rem;
}

.custom-target-options {
    padding-top: 1rem;
    border-top: 1px solid #e9ecef;
    margin-top: 1rem;
}

.btn-group {
    gap: 0.5rem;
}

.btn-group .btn {
    border-radius: 0.5rem !important;
    flex: 1;
}

.form-check-input:checked {
    background-color: #4361ee;
    border-color: #4361ee;
}

.btn {
    padding: 0.6rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: all 0.3s ease;
}

.btn-primary {
    background-color: #4361ee;
    border-color: #4361ee;
}

.btn-primary:hover {
    background-color: #2541df;
    border-color: #2541df;
    transform: translateY(-1px);
}

.btn-light {
    background-color: #f8f9fa;
    border-color: #e9ecef;
}

.btn-light:hover {
    background-color: #e9ecef;
    border-color: #dde1e5;
}

.target-card {
    transition: all 0.3s ease;
    cursor: pointer;
    border: 1px solid #e9ecef;
}

.target-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}

.target-card .target-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-select[multiple] {
    background-image: none;
    padding: 0.5rem;
}

.form-select[multiple] option {
    padding: 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.form-select[multiple] option:checked {
    background-color: var(--bs-primary);
    color: white;
}

.form-select[multiple] option:hover {
    background-color: var(--bs-light);
}

.animate__animated {
    animation-duration: 0.3s;
}

.animate__fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Özel scrollbar stilleri */
.form-select[multiple]::-webkit-scrollbar {
    width: 8px;
}

.form-select[multiple]::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.form-select[multiple]::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.form-select[multiple]::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.notification-page {
    background-color: #f5f7f9;
}

.notification-card {
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
}

.notification-tabs {
    margin-top: 20px;
}

.tab-content {
    padding: 16px 4px;
}

.send-methods {
    padding: 16px 0;
}

.send-methods-title {
    margin-bottom: 16px;
}

.send-method-card {
    cursor: pointer;
    height: 100%;
    transition: all 0.3s;
}

.send-method-card.active {
    border-color: #1890ff;
    background-color: rgba(24, 144, 255, 0.05);
}

.send-method-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.send-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.send-icon span {
    font-size: 24px;
}

.info-icon {
    color: #1890ff;
    margin-left: 5px;
    cursor: help;
}

.target-radio {
    width: 100%;
    height: 100%;
    border: none !important;
    background: transparent !important;
}

.target-card {
    height: 100%;
    padding: 8px;
    cursor: pointer;
    transition: all 0.3s;
}

.target-icon-wrapper {
    background-color: rgba(24, 144, 255, 0.1);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}

.target-icon {
    font-size: 24px;
    color: #1890ff;
}

.target-info {
    text-align: center;
}

.priority-radio {
    width: 100%;
    border: none !important;
    background: transparent !important;
}

.priority-card {
    cursor: pointer;
    height: 100%;
    text-align: center;
    transition: all 0.3s;
}

.priority-icon {
    font-size: 24px;
    margin-bottom: 8px;
}

.low .priority-icon {
    color: #52c41a;
}

.medium .priority-icon {
    color: #faad14;
}

.high .priority-icon {
    color: #f5222d;
}

.ant-radio-button-wrapper-checked .priority-card {
    background-color: rgba(24, 144, 255, 0.05);
    border-color: #1890ff;
}

.ant-radio-button-wrapper-checked .target-card {
    background-color: rgba(24, 144, 255, 0.05);
    border-color: #1890ff;
}

.departments-branches-selection,
.users-selection {
    margin-top: 24px;
    animation: fadeIn 0.3s;
}

.selection-card {
    height: 100%;
}

.selection-tip {
    display: block;
    margin-top: 8px;
}

.preview-item {
    margin-bottom: 16px;
}

.preview-header {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    color: #1890ff;
}

.preview-body {
    margin-top: 24px;
}

.method-tags,
.custom-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
}

.form-actions {
    text-align: center;
    margin-top: 30px;
    padding: 16px;
    border-top: 1px solid #f0f0f0;
}

.send-button {
    padding: 0 40px;
    height: 42px;
    font-weight: 500;
}

.breadcrumb-container {
    display: flex;
    align-items: center;
}

.breadcrumb-text {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
}

.breadcrumb-text.active {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 500;
}

@media (max-width: 768px) {
    .notification-tabs {
        margin-top: 10px;
    }
    
    .send-method-card,
    .priority-card,
    .target-card {
        margin-bottom: 16px;
    }
}

/* Adım adım arayüz (Steps) stilleri */
.notification-steps {
    margin-bottom: 20px;
}

.step-content {
    padding: 24px 0;
    min-height: 300px;
}

.step-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid #f0f0f0;
}

.step-actions {
    display: flex;
    gap: 16px;
    margin-left: auto;
}

.send-button {
    background: linear-gradient(90deg, #1890ff, #096dd9);
    font-weight: 500;
}

/* Öncelik seviyesi (Priority Level) stilleri */
.priority-options {
    margin-top: 12px;
}

.priority-option {
    display: flex;
    gap: 12px;
    padding: 16px;
    border-radius: 8px;
    transition: all 0.3s;
    cursor: pointer;
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
}

.priority-option:hover {
    background-color: #f5f5f5;
    transform: translateY(-2px);
}

.priority-badge {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.priority-option.low .priority-badge {
    background-color: rgba(82, 196, 26, 0.1);
    color: #52c41a;
}

.priority-option.medium .priority-badge {
    background-color: rgba(250, 173, 20, 0.1);
    color: #faad14;
}

.priority-option.high .priority-badge {
    background-color: rgba(245, 34, 45, 0.1);
    color: #f5222d;
}

.priority-info {
    flex: 1;
}

.priority-desc {
    margin-top: 4px;
    font-size: 12px;
}

.ant-radio-wrapper:hover .priority-option {
    border-color: #1890ff;
}

.ant-radio-wrapper-checked .priority-option {
    border-color: #1890ff;
    background-color: #e6f7ff;
    box-shadow: 0 2px 8px rgba(24, 144, 255, 0.15);
}

/* Form stilleri */
.message-form, .recipients-form, .preview-content {
    max-width: 100%;
}

/* Önizleme stilleri */
.preview-header {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f0f0f0;
}

.preview-body {
    margin-bottom: 24px;
}

.preview-item {
    margin-bottom: 16px;
}

.message-preview-card, .delivery-preview-card {
    height: 100%;
}

.method-tags, .custom-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
} 