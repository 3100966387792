// Font import
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Base Font
$font-size-base: 14px;
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

// Font Family
$font-family-primary: "Roboto", sans-serif;
$font-awesome: "Fontawesome";
	
// Theme Colors Variables
$primary: #481b73;
$primary-hover: darken($primary, 10%);
$secondary: #3B7080;
$secondary-hover: darken($secondary, 10%);
$success: #03C95A;
$success-hover: darken($success, 10%);
$info: #1B84FF;
$info-hover: darken($info, 10%);
$warning: #FFC107;
$warning-hover: darken($warning, 10%);
$danger: #E70D0D;
$danger-hover: darken($danger, 10%);
$dark: #212529;
$light: #F8F9FA;
$white: #FFF;
$black: #000;
$purple: #AB47BC;
$pink: #FD3995;
$skyblue: #0DCAF0;
$teal: #02a8b5;

$dark-green: #111926;
$night-blue: #0F0F41;
$dark-gray: #2E2E48;
$royal-blue: #0039C6;
$indigo: #391694;
$darkaqua: #116D6E;
$whiterock: #F0E4D7;
$rockblue: #8CB9BD;
$bluehaze: #B5C0D0;
$brightblue:  #2066FF;
$lunargreen: #365E32;
$lavendar: #673AB7;
$magenta: #E83E8C;
$chromeyellow: #FF9B04;
$lavared: #DC2626;
$blackpearl: #080821;
$maroon: #710034;
$bluegem: #3E1C95;
$firefly: #053333;

$violet: red;
$orange: orange;
$green: green;
$red: red;

// Primary
$primary-100: #7338A0;
$primary-200: #FCE0D3;
$primary-300: #FBD1BD;
$primary-400: #FAC1A7;
$primary-500: #F9B291;
$primary-600: #F7A37A;
$primary-700: #F69364;
$primary-800: #F5844E;
$primary-900: #7338a0;

// Secondary
$secondary-100: #DCE5E8;
$secondary-200: #CAD8DD;
$secondary-300: #B9CBD1;
$secondary-400: #A7BFC6;
$secondary-500: #95B2BB;
$secondary-600: #84A5AF;
$secondary-700: #7298A4;
$secondary-800: #618B98;
$secondary-900: #4F7E8D;

// Pink
$pink-100: #FFDBEC;
$pink-200: #FECAE3;
$pink-300: #FEB8D9;
$pink-400: #FEA6D0;
$pink-500: #FE94C6;
$pink-600: #FE82BD;
$pink-700: #FD71B3;
$pink-800: #FD5FAA;
$pink-900: #FD4DA0;

// Light
$light-100: #FDFDFE;
$light-200: #FBFBFD;
$light-300: #F8FAFC;
$light-400: #F6F8FB;
$light-500: #F4F6FA;
$light-600: #F2F4F8;
$light-700: #F0F2F7;
$light-800: #EDF1F6;
$light-900: #E9EDF4;

// Gray
$gray-100: #F3F4F6;
$gray-200: #E5E7EB;
$gray-300: #D1D5DB;
$gray-400: #9CA3AF;
$gray-500: #6B7280;
$gray-600: #4B5563;
$gray-700: #374151;
$gray-800: #1F2937;
$gray-900: #111827;

// Info
$info-100: #D6E9FF;
$info-200: #C2DEFF;
$info-300: #ADD3FF;
$info-400: #99C8FF;
$info-500: #84BCFF;
$info-600: #70B1FF;
$info-700: #5BA6FF;
$info-800: #479BFF;
$info-900: #3290FF;

// Success
$success-100: #D2F5E1;
$success-200: #BBF0D3;
$success-300: #A4EBC4;
$success-400: #8EE7B5;
$success-500: #77E2A6;
$success-600: #60DD97;
$success-700: #49D889;
$success-800: #33D37A;
$success-900: #1CCE6B;

// Danger
$danger-100: #F6CECE;
$danger-200: #F1B6B6;
$danger-300: #EC9E9E;
$danger-400: #E88686;
$danger-500: #E36D6D;
$danger-600: #DE5555;
$danger-700: #D93D3D;
$danger-800: #D52424;
$danger-900: #D00C0C;

// Warning
$warning-100: #FFF4D2;
$warning-200: #FFEEBC;
$warning-300: #FFE9A6;
$warning-400: #FFE390;
$warning-500: #FFDD79;
$warning-600: #FFD863;
$warning-700: #FFD24D;
$warning-800: #FFCD36;
$warning-900: #FFC720;

// Purple
$purple-100: #F0DEF3;
$purple-200: #E8CDED;
$purple-300: #E1BDE7;
$purple-400: #D9ACE1;
$purple-500: #D19BDB;
$purple-600: #CA8BD5;
$purple-700: #C27ACF;
$purple-800: #BB6AC9;
$purple-900: #B359C3;

// Skyblue
$skyblue-100: #D3F5FC;
$skyblue-200: #BEF1FB;
$skyblue-300: #A8ECFA;
$skyblue-400: #92E7F9;
$skyblue-500: #7CE2F7;
$skyblue-600: #66DDF6;
$skyblue-700: #51D9F5;
$skyblue-800: #3BD4F3;
$skyblue-900: #25CFF2;

// Darkmode Base
$darkmode-light: #131313;
$darkmode-dark: #D6DADE;
$darkmode-white: #0D0D0D;

// Darkmode Gray
$darkmode-gray-900: #D8DFEE;
$darkmode-gray-800: #C8D2E0;
$darkmode-gray-700: #374151;
$darkmode-gray-600: #4B5563;
$darkmode-gray-500: #6B7280;
$darkmode-gray-400: #9CA3AF;
$darkmode-gray-300: #2F343C;
$darkmode-gray-200: #1F2228;
$darkmode-gray-100: #171717;

// Darkmode Light
$darkmode-light-900: #181818;
$darkmode-light-800: #121212;
$darkmode-light-700: #111111;
$darkmode-light-600: #101010;
$darkmode-light-500: #0F0F0F;
$darkmode-light-400: #0E0E0E;
$darkmode-light-300: #0C0C0C;
$darkmode-light-200: #0B0B0B;
$darkmode-light-100: #0A0A0A;

// Darkmode Transparent
$darkmode-primary-transparent: #100601;
$darkmode-secondary-transparent: #030D11;
$darkmode-success-transparent: #04150C;
$darkmode-warning-transparent: #171203;
$darkmode-danger-transparent: #240505;
$darkmode-info-transparent: #050C15;
$darkmode-purple-transparent: #17051B;
$darkmode-skyblue-transparent: #03191E;
$darkmode-pink-transparent: #240917;
$darkmode-dark-transparent: #212324;
$darkmode-light-transparent: #0B0F14;

$colors: (
  "skyblue":    $skyblue,
  "purple":     $purple,
  "pink":       $pink,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "dark": $dark,
    "light": $light,
    "black": $black,
    "purple": $purple
);

$text-color: #6B7280;
$title-color: #202C4B;
$sub-title: #6B7280;
$body-bg: #fff;
$text-muted: #677788;
$default-background: #F8F9FA;
$black-bg: #141432;
$input-bg: #2c2c50;
$form-control-bg: #ffffff;

// Social Icons Colors
$facebook: #3B5998;
$twitter: #00ACEE;
$google: #DD4B39;
$linkedin: #0E76A8;
$youtube: #C4302B;
$instagram: #3F729B;
$pinterest: #cc2127;
$skype: #00AFF0;
$email: #6567A5;
$appstore: #000;

// Gradient Variables
$primary-transparent: #e1dbe8;
$secondary-transparent: #EDF2F4;
$success-transparent: #E8FAF0;
$warning-transparent: #FFF9E9;
$danger-transparent: #FAE7E7;
$info-transparent: #EBF4FF;
$pink-transparent: #FFEDF6;
$light-transparent: #F9FAFE;
$dark-transparent: #E8E9EA;
$teal-transparent: #E6F9FF;
$purple-transparent: #F7EEF9;
$skyblue-transparent: #E9FAFE;
$primary-gradient: linear-gradient(90deg, #1E0847 0%, #1E0847 100%);
$secondary-gradient: linear-gradient(180deg, #3B7080 0%, #3D90A9 100%);
$success-gradient: linear-gradient(180deg, #2DCB73 0%, #0DA952 100%);
$warning-gradient: linear-gradient(180deg, #FFC107 0%, #FFE8A5 100%);
$danger-gradient: linear-gradient(180deg, #E70D0D 0%, #810707 100%);
$info-gradient: linear-gradient(180deg, #177DBC 0%, #1A91DB 100%);
$light-gradient: linear-gradient(180deg, #F8F9FA 0%, #EDEDED 100%);
$dark-gradient: linear-gradient(180deg, #F3F6FF 0%, #FFFFFF 100%);
$pink-gradient: linear-gradient(180deg, #FFA795 0%, #FFFFFF 100%);
$purple-gradient: linear-gradient(180deg, #4B3088 0%, #5C2DC8 100%);
$teal-gradient: linear-gradient(180deg, #E6F9FF 0%, #FFFFFF 100%);
$orange-gradient: linear-gradient(180deg, #FF9945 0%, #FC6173 100%);
$gradient-purple: linear-gradient(180deg, #667CE8 0%, #754EA7 100%);
$blue-gradient: linear-gradient(180deg, #00C0F9 0%, #0257CE 100%);
$maroon-gradient: linear-gradient(180deg, #EF3B4A 0%, #513A8F 100%);

// Style Anchor Elements
$link-color: $primary;
$link-decoration: none;
$link-shade-percentage: 20%;
$link-hover-color: darken($link-color, 10%);
$link-hover-decoration: null;
$spacer: 1rem;

// Border
$border-width: 1px;
$border-style: solid;
$border-color: $gray-200;
$default-border: $gray-200;
$input-border: $gray-200;

// Border Radius
$border-radius: 4px;
$border-radius-sm: 3px;
$border-radius-lg: 5px;
$border-radius-xl: 8px;
$border-radius-xxl: 12px;
$rounded: 50%;
$rounded-pill: 1.5rem;

// Box Shadow
$box-shadow: 0px 4px 54px 0px #E0E0E040;
$box-shadow-xs: 0px 1px 1px 1px rgba(198, 198, 198, 0.2);
$box-shadow-sm: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
$box-shadow-md: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
$box-shadow-lg: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
$drop-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

// Font Size
$font-size-8: 8px;
$font-size-9: 9px;
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-34: 34px;
$font-size-36: 36px;
$font-size-38: 38px;
$font-size-40: 40px;
$font-size-42: 42px;
$font-size-50: 50px;
$font-size-54: 54px;
$font-size-60: 60px;

$h1-font-size: 28px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 14px;
