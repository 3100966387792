
.chat-wrapper {
    display: flex;
	display: -webkit-flex;
    width: 100% !important;
    .chat-users-wrap {
        .chat-list {
            position: relative;
            .chat-dropdown {
                position: absolute;
                right: 20px;
                bottom: 20px;
                display: none;
                & > a {
                    color: $gray-500;
                }
                &:hover {                
                    .chat-pin {
                        margin-right: 15px;
                        @include transition(all .5s ease);
                    }
                    .chat-user-list {
                        box-shadow: $box-shadow;
                    }
                }
            }
        }
        .chat-user-list {
            padding: 20px;
            display: flex;
            display: -webkit-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 20px;
            margin-bottom: 8px;
            background-color: $white;
            box-shadow: 0px 1px 5px 1px rgba(243, 243, 243, 1);
            @include rounded(8px);
            position: relative;
            @include transition(all .5s ease);
            color: $gray-500;
            @include respond-below(lg) {
                padding: 16px;
            }
            .chat-user-info {
                flex: 1;
                position: relative;
                min-width: 0px;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .chat-user-msg {
                    min-width: 0;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    h6 {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    p {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin-bottom: 0;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                    }
                }
                .chat-user-time {
                    text-align: end;
                    .time {
                        display: inline-block;
                        margin: 0 0 2px 4px;
                    }
                    .count-message {
                        background-color: $danger;
                        color: $white;
                        padding: 1px 8px;
                        @include rounded(15px);
                    }
                    .chat-pin {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        justify-content: end;
                        -webkit-justify-content: end;
                        -ms-flex-pack: end;
                        @include transition(all .5s ease);
                    }
                }
            }
            &:hover {
                box-shadow: $box-shadow;
            }
        }
        .chat-list {
            &:hover {
                .chat-dropdown {
                    display: block;
                    @include transition(all .5s ease);
                }
                .chat-pin {
                    margin-right: 15px;
                    @include transition(all .5s ease);
                }
            }
        }
    }
    .search-wrap {
        .form-control {
            border-color: $white;
            min-height: 48px;
            border-color: $border-color;
            border-right: 0;
            @include respond-below(lg) {
                min-height: 38px;
            }
        }
        .input-group-text {
            border-color: $border-color;
            background-color: $white;
            color: $gray-500;
            border-left: 0;
        }
        &.contact-search {
            .form-control {
                border-color: $border-color;
                border-right: 0;
            }
            .input-group-text { 
                border-color: $border-color;
            }
        }
    }
    .sidebar-content {
        display: flex;
        flex-direction: column;
    }
    .chat-search-header {
        padding: 20px 20px 0;         
        @include respond-below(lg) {
            padding: 16px 16px 0; 
        }
    }
    .top-online-contacts {
        padding: 24px 24px 0;         
        @include respond-below(lg) {
            padding: 16px 16px 0; 
        }
    }
    .sidebar-body {
        flex: 1;
        overflow: auto;
        width: 100%;
        padding: 20px 20px 16px;          
        @include respond-below(lg) {
            padding: 16px 16px 8px; 
        }        
        @include respond-below(md) {
            padding: 16px 16px 8px; 
        }
    }
    .chat-status {
        display: block;
        color: $gray-500;
        .avatar {
            margin: 0 auto 4px;
        }
    }
    .chat-search {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        @include transition(ease all 0.5s);
        opacity: 0;
        visibility: hidden;
        padding: 16px;
        &.visible-chat {
            opacity: 1;
            visibility: visible;
            top: 100%;
            @include transition(ease all 0.5s);
            z-index: 9;
        }
    }
    .sidebar-group {
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        position: relative;
        z-index: 99;
        width: 400px;
        background-color: $white;
        @include rounded(10px);
        @include transition(all .5s ease);
        @include respond-below(xxl) {
            width: 330px;
        }
        @include respond-below(xl) {
            width: 300px;
        }
        @include respond-below(lg) {
            width: 100%;
        }
        @include respond-below(md) {
            width: 100%;
            margin-left: 0;
        }
        .slimScrollDiv {
            height: calc(100vh - 170px) !important;   
            @include respond-below(lg) {                
                height: calc(100vh - 150px) !important;   
            }
    
        }
        .slimscroll {
            height: calc(100vh - 170px) !important;   
            @include respond-below(lg) {                
                height: calc(100vh - 150px) !important;   
            }
        }
    }
.animate-typing .dot {
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	margin-right: -1px;
	background: $gray-500;
	-webkit-animation: wave 1.3s linear infinite;
	animation: wave 1.3s linear infinite;
	opacity: .6
}
.animate-typing .dot:nth-child(2) {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s
}
.animate-typing .dot:nth-child(3) {
	-webkit-animation-delay: -.9s;
	animation-delay: -.9s
}
@-webkit-keyframes wave {
	0%,
	100%,
	60% {
		-webkit-transform: initial;
		transform: initial
	}
	30% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px)
	}
}
@keyframes wave {
	0%,
	100%,
	60% {
		-webkit-transform: initial;
		transform: initial
	}
	30% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px)
	}
}
.chat {    
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    width: 100%;
    height: 100%;
    background: $white;
    @include respond-below(lg) {
        position: fixed;
        top: 60px;
        left: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        visibility: hidden;
        transform: translateX(100%);
        transition: visibility .4s ease,transform .4s ease;
        order: 3;
        z-index: 999;
        background-color: $white;
        margin: 0 15px 15px;
    }
    &.show {
        visibility: visible;
        transform: translateX(0);
    }
    &.chat-messages {
        @include rounded(10px);
        margin-left: 20px;
        @include respond-below(lg) {
            margin-left: 0;
        }
        .slimScrollDiv {
            height: calc(100vh - 235px) !important;    
            @include respond-below(lg) {
                height: calc(100vh - 140px) !important;                   
            }
    
        }
        .slimscroll {
            height: calc(100vh - 320px) !important;        
            @include respond-below(lg) {
                height: calc(100vh - 216px) !important;
            }
        }

    }
    .slimscroll {
       height: calc(100vh - 65px) !important;        
        @include respond-below(sm) {
            height: calc(100vh - 90px) !important;
        }
    }
    .chat-header {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        padding: 8px 16px;
        align-items: center;
        background: $white;
        border-bottom: 1px solid $border-color;
        position: relative;
        box-shadow: $box-shadow-xs;
        @include rounded(10px 10px 0 0);
        @include respond-below(sm) {
            flex-wrap: wrap;
        }
        .user-details { 
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
            overflow: hidden;
            h6 {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        .chat-options {
            & > ul {
                margin-bottom: 0;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                & > li {
                    margin-right: 4px;
                    & > a {                        
                        font-size: 14px;
                        color: $gray-500;
                        cursor: pointer;
                        width: 32px;
                        height: 32px;
                        padding: 0;
                        @include rounded(27px);
                        display: flex;
                        display: -webkit-flex;
                        align-items: center;
                        -webkit-align-items: center;
                        justify-content: center;
                        -webkit-justify-content: center;
                        background: $white;
                        &:hover {
                            background: $light;
                        }
                    }
                    .avatar-group-md {
                        .avatar {
                            width: 36px;
                            height: 36px;
                            @include respond-below(lg) {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .slimScrollDiv {
        @include respond-below(sm) {
            height: calc(100vh - 95px) !important;
        }
    }
    .chat-body {
        flex: 1;
        padding: 16px 16px 0;
        .messages {       
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            -webkit-flex-direction: column;
            align-items: flex-start;
            -webkit-align-items: flex-start;                 
            .chat-profile-name {
                h6 {
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                    -webkit-align-items: center;
                    font-size: $font-size-14;
                    font-weight: $font-weight-normal;
                    .chat-time {
                        color: $gray-500;
                    }
                    i {
                        color: $dark-transparent;
                    }
                    span {
                        font-weight: $font-weight-normal;
                    }
                    .msg-read {
                        margin-left: 8px;
                        i {
                            color: $gray-500;
                        }
                        &.success {
                            i {
                                color: $success;
                            }
                        }
                    }
                }
            }            
            .chats {
                max-width: 75%;
                margin-bottom: 26px;
                display: flex;
                display: -webkit-flex;
                align-items: end;
                &:last-child {
                    margin-bottom: 0;
                }
                @include respond-below(lg) {
                    max-width: 95%;
                }
                .chat-avatar {
                    padding-right: 8px;
                    display: flex;
                    display: -webkit-flex;
                    align-items: flex-start;
                    -webkit-align-items: flex-start;
                    flex-shrink: 0;
                    img {
                        width: 48px;
                        height: 48px;
                    }
                }
                .chat-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .chat-actions {
                        margin-left: 8px;
                    }
                }
                .chat-content {
                    width: 80%;
                    .message-content {
                        background-color: $light;
                        border-radius: 16px 16px 16px 0;
                        padding: 16px;
                        font-size: $font-size-14;
                        margin-bottom: 4px;
                        width: 100%;   
                        .chat-actions {
                            position: absolute;
                            right: 10px;
                        }
                        .chat-img {
                            max-width: 450px;
                        }
                        .file-attach {
                            display: flex;
                            display: -webkit-flex;
                            align-items: center;
                            -webkit-align-items: center;
                            overflow: hidden;
                            .file-icon {
                                width: 44px;
                                height: 44px;
                                display: flex;
                                display: -webkit-flex;
                                align-items: center;
                                -webkit-align-items: center;
                                justify-content: center;
                                -webkit-justify-content: center;
                                @include rounded(50%);
                                background: $white;
                                font-size: $font-size-22;
                                color: $gray-900;
                                flex-shrink: 0;
                            }
                            h6 {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            p {
                                font-size: $font-size-14;
                            }
                            .download-icon {
                                width: 24px;
                                height: 24px;
                                display: flex;
                                display: -webkit-flex;
                                align-items: center;
                                -webkit-align-items: center;
                                justify-content: center;
                                -webkit-justify-content: center;
                                @include rounded(9px);
                                background: $white;
                                color: $gray-500;
                                margin-left: 40px;
                                flex-shrink: 0;
                            }
                        }
                        .view-all {
                            font-size: $font-size-13;
                            font-weight: $font-weight-medium;
                        }
                    }                    
                    .emonji-wrap {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        margin-top: 4px;
                        a {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            padding: 8px;
                            color: $gray-500;
                        }
                    }
                }
                &.chats-right {
                    margin-left: auto;
                    flex-direction: row-reverse;
                    -webkit-flex-direction: row-reverse;
                    .chat-avatar {
                        padding: 0 0 0 8px;
                    }
                    .chat-profile-name {
                        justify-content: end;
                        -webkit-justify-content: end;
                        -ms-flex-pack: end;
                    }
                    .chat-info {
                        justify-content: end;
                    }
                    .chat-actions {
                        margin-right: 8px;
                    }
                    .chat-content {
                        order: 1;
                        -webkit-order: 1;
                    }
                    .chat-profile-name {
                        h6 {
                            flex-direction: row-reverse;
                            .msg-read {
                                margin: 0 8px 0 0;
                            }
                        }
                    }
                    .message-content {
                        background-color: $light;
                        color: $gray-900;
                        border-radius: 16px 16px 0px 16px;
                        padding: 16px;
                        .file-attach {
                            .file-icon {
                                color: $primary;
                            }
                            h6 {
                                color: $white;
                            }
                        }
                        .emoj-group {
                            left: -110px;
                            width: max-content;
                        }
                    }                                        
                    .emonji-wrap {
                        justify-content: end;
                        -webkit-justify-content: end;
                        -ms-flex-pack: end;
                    }
                }
            } 
        }
    }
}
.chats {
    max-width: 75%;
    margin-bottom: 30px;
    display: flex;
    display: -webkit-flex;
    align-items: end;
    .chat-avatar {
        padding-right: 8px;
        display: flex;
        display: -webkit-flex;
        align-items: flex-start;
        -webkit-align-items: flex-start;
        flex-shrink: 0;
        img {
            width: 48px;
            height: 48px;
        }
    }
    .chat-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .chat-actions {
            margin-left: 8px;
        }
    }
    .chat-profile-name {
        h6 {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
            font-size: $font-size-14;
            .chat-time {
                color: $gray-500;
            }
            i {
                color: $dark-transparent;
            }
            span {
                font-weight: $font-weight-normal;
            }
            .msg-read {
                margin-left: 8px;
                i {
                    color: $gray-500;
                }
                &.success {
                    i {
                        color: $success;
                    }
                }
            }
        }
    }   
    .chat-content {
        .message-content {
            background-color: $light;
            border-radius: 16px 16px 16px 0;
            padding: 16px;
            font-size: $font-size-16;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
            .chat-actions {
                position: absolute;
                right: 10px;
            }
            .chat-img {
                max-width: 450px;
            }
            .file-attach {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                .file-icon {
                    width: 44px;
                    height: 44px;
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                    -webkit-align-items: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    @include rounded(50%);
                    background: $white;
                    font-size: $font-size-22;
                    color: $gray-900;
                }
                p {
                    font-size: $font-size-14;
                }
                .download-icon {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                    -webkit-align-items: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    @include rounded(9px);
                    background: $white;
                    color: $gray-500;
                    margin-left: 40px;
                }
            }
            .view-all {
                font-size: $font-size-13;
                font-weight: $font-weight-medium;
            }
        } 
    }
    &.chats-right {
        .message-content {
            background-color: $primary;
            color: $white;
            border-radius: 16px 16px 0px 16px;
            padding: 16px;
        }
    }
} 
.chat-line {
    margin: 0px 0 20px;
    position: relative;
    text-align: center;
    width: 100%;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        background-color: $border-color;
        width: 100%;
        height: 1px;
    }
}
.chat-date {
    background-color: $dark;
    color: $white;
    font-weight: $font-weight-semibold;
    padding: 2px 16px;
    border-radius: 15px;
    display: inline-block;
    position: relative;
}
.chat-profile-name {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 4px;
}
.favourite-chats {
    .message-audio {
        audio {                
            @include respond-below(xxl) {
                max-width: 180px;
            }
        }
    }
}
.info-chats {
    .chats {
        max-width: 100%;
        &.chats-right {
            .chat-avatar {
                padding: 0 0 0 8px;
            }
        }
    }
}
.emoj-group {
    & > ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        & > li {
            & > a {
                width: 34px;
                height: 34px;
                background: $white;
                box-shadow: $box-shadow-xs;
                @include rounded(50px);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                color: $gray-500;
                font-size: $font-size-16;
                &:hover {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    } 
}
.emoj-group ul li+li {
	margin-left: 10px;
}
.message-content {
	position: relative;
}
.message-content:hover .emoj-group {
	opacity: 1;
    @include transition(all 0.5s ease);
	
}
.emoj-group {
	position: absolute;
	top: 50%;
	right: -100px;
	opacity: 0;
    @include transition(all 0.5s ease);
	-webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.rig-emoji-group {
	position: absolute;
	right: 210px;
}
.wrap-emoji-group {
	position: absolute;
	right: 290px;
	z-index: 9;
}
.emoj-group-list-foot {
    left: 0;
    ul {
        li {
            & + li {
                margin-left: 8px;
            }
        }
    }
}
.emoj-group-list,
.emoj-group-list-foot {
	display: none;
	position: absolute;
	top: -50px;
	left: -90px;
    @include transition(all 0.5s ease);
    ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: $white;
        border: 1px solid $border-color;
        @include rounded(8px);
        padding: 8px;
        li {
            a {
                padding-right: 8px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                width: 16px;
                &:last-child {
                    padding-right: 0;
                }
            }
            &.add-emoj {
                a {                    
                    width: 24px;
                    height: 24px;
                    background: $white;
                    @include rounded(50px);
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    background-color: $primary;
                    color: $white;
                    font-size: $font-size-14;
                    &:hover {
                        background-color: $primary-hover;
                        color: $white;
                    }
                }
            }
        }
    }
}
.chat-img{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .img-wrap{
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        flex: 1 1 120px;
        height: 120px;
        img{
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
        .img-overlay{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($black, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            opacity: 0;
            visibility: hidden;
            transform: scale(1.2);
            @include transition(all .5s ease);
            a {
                font-size: $font-size-14;
                color: $white;
            }
        }
        &:hover {
            .img-overlay{
                opacity: 1;
                visibility: visible;
                @include transform(scale(1));
            }
        }
    }
}
.message-link {
    overflow: hidden;
    .link-img {
        background: $white;
        padding: 8px;
        @include rounded(8px);
        text-align: center;
    }
    a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.chat-forward {
    .forward-text {
        border-bottom: 1px solid $border-color;
        padding-bottom: 8px;
        margin-bottom: 8px;
    }
}
.message-reply {
    background: $white;
    @include rounded(8px);
    border-left: 3px solid $secondary;
    padding: 16px;
    color: $gray-900;
    margin-bottom: 8px;
}
.emoj-group {
    position: absolute;
    top: 50%;
    right: -100px;
    opacity: 0;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.chat .chat-footer {
	position: relative;
    .footer-form {
        display: flex;
        display: -webkit-flex;
        align-items: end;
        -webkit-align-items: end;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        padding: 24px;
        background-color: $white;
        border-top: 1px solid $border-color;
        padding: 10px 16px;        
        @include rounded(0 0 10px 10px);
        .chat-footer-wrap {
            display: flex;
            display: -webkit-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background: $light;
            @include rounded(8px);
            padding: 8px;
            width: 100%;
            .form-wrap {
                flex: 1;
                .form-control {
                    background: transparent;
                    min-height: 32px;
                    height: 32px;
                    padding-left: 0;
                    border: none;
                    &::placeholder {
                        color: $gray-500;
                    }
                }
                .chats {
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    width: 100%;
                    background: $light;
                    max-width: 100%;
                    padding: 16px;
                    margin-bottom: 0;
                    display: none;
                    .message-content {
                        padding: 0;
                    }
                }
            }
            .form-item {
                margin-right: 2px;
                position: relative;
                & > a {
                    width: 32px;
                    height: 32px;
                    @include rounded(8px);
                    color: $gray-500;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    justify-content: center;
                }
            }
            .form-btn {
                .btn {
                    width: 32px;
                    height: 32px;
                    @include rounded(8px);
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                }
            }
        }
    }
}
.close-replay {
    position: absolute;
    top: 16px;
    right: 16px;
    &:hover {
        color: $danger;
    }
}
.favourite-chats {
    .chats {
        max-width: 100%;
        border: 1px solid $border-color;
        @include rounded(8px);
        padding: 16px;
        margin-bottom: 24px;
    }
}
.action-wrap {
    background: $white;
    @include rounded(8px);
    padding: 8px;
    box-shadow: $box-shadow-xs;
    text-align: center;
    display: block;
    margin-bottom: 24px;
    i {
        color: $primary;
        font-size: $font-size-14;
    }
    p {
        color: $gray-900;
        margin-top: 4px;
    }
}
.link-icon {
    color: $gray-500;
    &:hover {
        color: $primary;
    }
}
.contact-user {
    background: $white;
    @include rounded(8px);
    box-shadow: $box-shadow-xs;
    padding: 20px;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
}
.contact-scroll {
    max-height: 400px;
    overflow-y: auto;
}
.contact-actions {
    a {
        width: 36px;
        height: 36px;
        @include rounded(50%);
        background: $light;
        border: 1px solid $border-color;
        color: $gray-500;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        &:hover {
            background: $primary;
            border-color: $primary;
            color: $white;
        }
    }
}
.open-file {
    width: 28px;
    opacity: 0;
}
.chat-body {
    .dropdown-item {
        &.active {
            background-color: $primary;
        }
    }
}
.file-action {
    left: 50%;
    transform: translateX(-50%);
}
}
@media screen and (max-width: 991.98px) {
    .left-sidebar.hide-left-sidebar {
        margin-left: -991px;
    }
    .chat.show-chatbar {
        display: block;
        margin-left: 0;
        visibility: visible;
        transform: none;
        position: unset;
        height: auto;
    }
    .main-wrapper .content {
        flex-flow: column wrap;
    }

}