/* LeaveStatus Select stilleri */
.leave-status-dropdown-container {
  position: relative;
  min-width: 120px;
}

/* Durum tipine göre select stilleri */
.leave-status-select.status-pending .ant-select-selector {
  background-color: #f0ad4e !important;
  border-color: #eea236 !important;
  color: white !important;
}

.leave-status-select.status-approved .ant-select-selector {
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
  color: white !important;
}

.leave-status-select.status-rejected .ant-select-selector {
  background-color: #d9534f !important;
  border-color: #d43f3a !important;
  color: white !important;
}

/* Metin ve ikon renkleri */
.leave-status-select .ant-select-selection-item {
  color: white !important;
  font-weight: 500;
}

/* Ok ikonu rengi */
.leave-status-select .ant-select-arrow {
  color: white !important;
}

/* Disabled durum */
.leave-status-select.ant-select-disabled .ant-select-selector {
  opacity: 0.8;
  cursor: not-allowed;
}

/* Dropdown menü stilleri */
.leave-status-dropdown .ant-select-item-option {
  padding: 8px 12px;
  border-radius: 4px;
  margin: 4px 0;
}

/* Dropdown içindeki seçenekler için stiller */
.leave-status-dropdown .status-option.status-pending {
  color: #f0ad4e;
  font-weight: 500;
}

.leave-status-dropdown .status-option.status-approved {
  color: #5cb85c;
  font-weight: 500;
}

.leave-status-dropdown .status-option.status-rejected {
  color: #d9534f;
  font-weight: 500;
}

.leave-status-dropdown .ant-select-item-option-selected {
  background-color: #f5f5f5;
  font-weight: 600;
} 