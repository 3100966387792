.layout-box-mode {
    .main-wrapper {
        max-width: 1320px;
        margin: auto;
        @include respond-below(xxl) {
            max-width: 1170px;
        }
        @include respond-below(xl) {
            max-width: 950px;
        }
        @include respond-below(lg) {
            max-width: 100%;
        }
    }
}

@mixin use-css-variable($variable-name) {
    #{$variable-name}: var(#{$variable-name});
}
:root {
    --primary-rgb-picr: var(--primary-rgb);
    --sidebar--rgb-picr: var(--sidebar-rgb);
    --topbar--rgb-picr: var(--topbar-rgb);
    --topbarcolor--rgb-picr: var(--topbarcolor-rgb);
}
[data-layout="default"][data-width="box"],
[data-layout="mini"][data-width="box"] {
    .layout-box-mode {
        .mini-sidebar {
            .header {
                max-width: 1320px;
                left: 0;
                @include respond-below(xxl) {
                    max-width: 1170px;
                }
                @include respond-below(xl) {
                    max-width: 950px;
                }
                @include respond-below(lg) {
                    max-width: 100%;
                }
            }
            &.expand-menu {
                .header {
                    max-width: 1320px;
                    left: 0;
                    @include respond-below(xxl) {
                        max-width: 1170px;
                    }
                    @include respond-below(xl) {
                        max-width: 950px;
                    }
                    @include respond-below(lg) {
                        max-width: 100%;
                    }
                }
            }
        }        
        .header {
            margin: auto;
            max-width: 1320px;
            left: 0;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
                left: 0;
            }
            .header-left {
                display: block;
                padding-left: 24px;
                @include respond-below(lg) {
                    padding-left: 0;
                }
            }
        }
        .sidebar {
            left: unset;
            top: 50px;
            .sidebar-logo {
                display: none;
            }
            .slimScrollDiv {
                top: 0;
            }
        }
    }
}
[data-layout="twocolumn"][data-width="box"],
[data-layout="modern"][data-width="box"],
[data-layout="stacked"][data-width="box"] {
    .layout-box-mode {
        .sidebar {
            left: unset;
            .sidebar-logo {
                display: none;
            }
        }
        .header {
            margin: 0 auto;
            max-width: calc(1320px - 252px);
            @include respond-above(lg) {
                left: 252px;
            }
            @include respond-below(xxl) {
                max-width: calc(1170px - 252px);
            }
            @include respond-below(xl) {
                max-width: calc(950px - 252px);
            }
            @include respond-below(lg) {
                max-width: 100%;
                left: 0;
            }
        }
        &.mini-sidebar {
            .header {
                @include respond-above(lg) {
                    left: 72px;
                }
                max-width: calc(1320px - 72px);
                @include respond-below(xxl) {
                    max-width: calc(1170px - 72px);
                }
                @include respond-below(xl) {
                    max-width: calc(950px - 72px);
                }
                @include respond-below(lg) {
                    max-width: 100%;
                    left: 0;
                }
            }
            &.expand-menu {
                .header {
                    max-width: calc(1320px - 252px);
                    @include respond-above(lg) {
                        left: 252px;
                    }
                    @include respond-below(xxl) {
                        max-width: calc(1170px - 252px);
                    }
                    @include respond-below(xl) {
                        max-width: calc(950px - 252px);
                    }
                    @include respond-below(lg) {
                        max-width: 100%;
                        left: 0;
                    }
                }
            }
        }
    }
}
[data-layout="horizontal"][data-width="box"],
[data-layout="horizontal-overlay"][data-width="box"] {
    .main-wrapper {
        .header {
            margin: auto;
            max-width: 1320px;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
            }
        }
        &::before {            
            width: 1320px;
            margin: 0 auto;
            left: 0;
            right: 0;
            @include respond-below(xxl) {
                width: 1170px;
            }
            @include respond-below(xl) {
                width: 950px;
            }
            @include respond-below(lg) {
                width: 100%;
            }
        }
        &::after { 
            left: 0;
            right: 0;
            margin: 0 auto;
            @include respond-below(xxl) {
                width: 1170px;
            }
            @include respond-below(xl) {
                width: 950px;
            }
            @include respond-below(lg) {
                width: 100%;
            }
        }
    }
    .header {
        padding: 0 16px;
        .main-header {
            max-width: 1320px;
            margin: 0 auto;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
            }
            .user-menu {
                padding-right: 0;
            }
        }
        .header-left {
            display: block;
        }
    }     
    .sidebar {
        &.sidebar-horizontal {
            max-width: 1320px;
            margin: 0 auto;
            padding: 0 16px !important;
            left: auto;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
            }
            .sidebar-menu {
                .nav-menu {
                    > li {
                        &.submenu {
                            & > a {
                                padding: 8px 30px 8px 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}
[data-layout="horizontal-single"][data-width="box"] {
    .header {
        max-width: 1320px;
        margin: 0 auto;
        @include respond-below(xxl) {
            max-width: 1170px;
        }
        @include respond-below(xl) {
            max-width: 950px;
        }
        @include respond-below(lg) {
            max-width: 100%;
        }
        .main-header {
            .user-menu {
                padding-left: 10px;
                @include respond-below(xl) {
                    padding-left: 8px !important;
                }
            }
        }
    }  
    .sidebar-horizontal.sidebar {
        .sidebar-menu {
            ul li.submenu > a {
                padding: 8px 18px 8px 6px;
                @include respond-above(xl) {
                    padding: 8px 30px 8px 8px;
                }
            }
        } 
    }
}
[data-layout="detached"][data-width="box"] {
    .header {
        max-width: 1320px;
        margin: 0 auto;
        @include respond-below(xxl) {
            max-width: 95%;
        }
    } 
}
[data-layout="without-header"][data-width="box"] {
    .sidebar {
        left: auto;
    }
}
[data-layout="transparent"][data-width="box"] {
    .sidebar {
        left: auto;
    }
    .header {
        max-width: 1320px;
        margin: 0 auto;
        @include respond-below(xxl) {
            max-width: 1170px;
        }
        @include respond-below(xl) {
            max-width: 950px;
        }
        @include respond-below(lg) {
            max-width: 100%;
        }
    }  
}
[data-layout="horizontal-sidemenu"][data-width="box"] {
    .sidebar {
        left: auto;
    }
    .header {
        max-width: 1320px;
        margin: 0 auto;
        @include respond-below(xxl) {
            max-width: 95%;
        }
        @include respond-below(lg) {
            max-width: 100%;
        }
    }  
    .main-wrapper {
        
        &::before {            
            width: 1320px;
            margin: 0 auto;
            left: auto;
            @include respond-below(xxl) {
                width: 95%;
            }
            @include respond-below(lg) {
                width: 100%;
            }
        }
    }
}
[data-layout=detached] {
    .main-wrapper {
            margin: 0 auto;
        @include respond-above(xxl) {
            max-width: 1320px;
        }
        @include respond-above(lg) {
            max-width: 95%;
        }
    }
    .header {
        left: 0;
        .header-left {
            display: block;
        }
        .header-user .user-menu {
            padding-right: 0;
        }
        .main-header {
            margin: 0 auto;
            @include respond-above(xxl) {
                max-width: 1320px;
            }
            @include respond-above(lg) {
                max-width: 95%;
            }
        }
    }
    .expand-menu{
        .header-left {
            width: auto;
        }
        &.mini-sidebar{
            .header {
                left: 0;
                .header-left {
                    width: auto;
                }
            }
        }
    }
    .sidebar {
        top: 74px;
        left: unset;
        bottom: 24px;
        .slimScrollDiv {
            height: 100% !important;
            top: 0;
            .sidebar-inner {
                height: 100% !important;
            }
            .sidebar-menu {
                margin-top: 0;
                padding-bottom: 0;
            }
        }
        .sidebar-menu {
            margin-top: 16px;
        }
        .sidebar-logo {
            display: none;
        }
        @include respond-below(lg) {
            top: 50px;
            bottom: 0;
        }
    }
    .page-wrapper {
        @include respond-above(lg) {
            padding: 74px 0 0;
        }
        .head-icons{
            display: none;
        }
    }

}
[data-layout=twocolumn] {
    .sidebar.navbar-menu {
        display: none;
        @include respond-below(lg) {
            display: block;
        }
    }   
    .sidebar-twocol.sidebar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    #two-col-sidebar {
        display: block;
        @include respond-below(lg) {
            display: none;
        }
    }  
}
[data-layout=horizontal] {   
    #horizontal-menu {
        display: block;
    }
    .header {
        left: 0;
        .main-header {
            max-width: 1320px;
            margin: 0 auto;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
            }
            .user-menu {
                padding-right: 0;
            }
        }
        .header-left {
            display: block;
        }
    }  
}
[data-layout="modern"] {
    .header {
        background-color: $white;
        border-color: rgba($white, 0.8);
        .bar-icon {
            span {
                background-color: $white;
            }
        }
        .input-group {
            @include respond-below(xl) {
                width: 200px;
            }
        }
        .header-left {
            display: block;
            padding-left: 24px;
            .logo {
                display: block;
            }
            .dark-logo {
                display: none;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $gray-500;
                    &:hover, &:active, &.active {
                        background-color: $dark-transparent;
                        border-color: $dark-transparent;
                        color: $gray-900 !important;
                    }
                }
            }
        }
    }
    .sidebar {
        display: block;
        .sidebar-logo {
            display: none;
        }
        .slimScrollDiv {
            top: 0;
            height: calc(100% - 220px) !important;            
        }
        .sidebar-inner {
            height: 100% !important;
        }
        .sidebar-menu {
            margin-top: 0;
        }
        .modern-profile {
            display: block;
        }
    }
    .page-wrapper {
        &::before {
            content: "";
            height: 168px;
            background: $white;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        &::after {
            content: none;
            height: 168px;
            background: url('../../../../public/assets/img/bg/modern-bg.png');
            background-repeat: no-repeat;
            width: 60%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
        }
    }
    .mini-sidebar {
        .modern-profile {
            .user-profile {
                padding: 0 !important;
                margin-bottom: 0 !important;
                background: transparent !important;
                border-color: transparent !important;
                .avatar {
                    width: 40px;
                    height: 40px;
                }
            }
            h6, p, .sidebar-nav {
                display: none;
            }
        }
        .slimScrollDiv {
            height: calc(100% - 70px) !important;
        }
        &.expand-menu {            
            .modern-profile {
                .user-profile {
                    padding: 16px !important;
                    margin-bottom: 24px !important;
                    background: $light !important;
                    .avatar {
                        width: 40px;
                        height: 40px;
                    }
                }
                h6, p, .sidebar-nav {
                    display: block;
                }
            }
            .slimScrollDiv {
                height: calc(100% - 220px) !important;
            }
        }
    }
}
[data-layout="horizontal-overlay"] {   
    #horizontal-menu {
        display: block;
    }
    .header {
        left: 0;
        background-color: $white;
        border-color: lighten($white, 0.9);
        .main-header {
            max-width: 1320px;
            margin: 0 auto;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
            }
            .user-menu {
                padding-right: 0;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $gray-500;
                    &:hover {
                        background: $dark-transparent;
                        color: $gray-900;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }      
    .mode-toggle {
        color: $gray-700;
    }
    .sidebar-horizontal {
        &.sidebar {
            background-color: $white;
            border-bottom: 1px solid lighten($white, 0.9);
            &::before {
                content: none;
                height: 100%;
                background: url('../../../../public/assets/img/bg/overlay-bg.png');
                background-repeat: no-repeat;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }
            .sidebar-menu {
                .nav-menu {
                    > li {     
                        &.submenu {
                            & > a {
                                color: $gray-900;
                                &.active, &:hover,  &.subdrop {
                                    background-color: $dark-transparent;
                                    color: $gray-900;
                                    .menu-arrow {
                                        &::before {
                                            border-color: $gray-900;
                                        }
                                    }
                                }
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-900;;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        max-width: 1320px;
        margin: 0 auto;
        @include respond-below(xxl) {
            max-width: 1170px;
        }
        @include respond-below(xl) {
            max-width: 950px;
        }
        @include respond-below(lg) {
            max-width: 100%;
        }
        &::before {
            content: "";
            height: 168px;
            background: $white;
            width: 100%;
            position: absolute;
            top: 96px;
            left: 0;
            z-index: -1;
            @include respond-below(lg) {
                top: 50px;
                height: 100px;
            }
        }
        &::after {
            content: "";
            height: 168px;
            background-repeat: no-repeat;
            width: 100%;
            position: absolute;
            top: 96px;
            left: 0;
            z-index: -1;
            @include respond-below(lg) {
                top: 50px;
                height: 100px;
            }
        }
    }
    .page-breadcrumb {
        h2 {
            color: $white;
        }
    }
}
[data-layout="horizontal-box"] {
    #horizontal-menu {
        display: block;
    }
    .main-wrapper {
        max-width: 1320px;
        margin: auto;
        @include respond-below(xxl) {
            max-width: 1170px;
        }
        @include respond-below(xl) {
            max-width: 950px;
        }
        @include respond-below(lg) {
            max-width: 100%;
        }
        .header {
            max-width: 1320px;
            margin: auto;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
            }
        }
    }
    .header {
        left: 0;
        padding: 0 16px;
        .main-header {
            max-width: 1320px;
            margin: 0 auto;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
            }
            .user-menu {
                padding-right: 0;
            }
        }
        .header-left {
            display: block;
            .dark-logo {
                display: block;
            }
        }
    }     
    .sidebar {
        &.sidebar-horizontal {
            max-width: 1320px;
            margin: 0 auto;
            padding: 0 16px !important;
            left: auto;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
            }
            .sidebar-menu {
                .nav-menu {
                    > li {
                        &.submenu {
                            & > a {
                                padding: 8px 30px 8px 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

[data-layout="horizontal-fullwidth"] {     
    #horizontal-menu {
        display: block;
    }
    .header {
        padding: 0 24px;
    }
    .sidebar-horizontal {
        &.sidebar {
            .sidebar-menu {
                max-width: 100%;
                padding: 0 24px !important;
                .nav-menu {
                    > li {
                        &.submenu {
                            & > a {
                                padding: 8px 24px 8px 8px;
                                @include respond-above(xxl) {
                                    padding: 8px 30px 8px 8px;
                                }
                                @include respond-above(xl) {
                                    padding: 8px 28px 8px 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
[data-layout="without-header"] { 
    .header {
        @include respond-above(lg) {
            display: none;
        }
    }
    .page-wrapper {
        padding: 0;
        .head-icons{
            display: none;
        }
    }
    .modern-profile {
        .user-profile {
            display: none;
        }
    }
    .modern-profile, .sidebar-header {
        display: block;
    }
    .sidebar {
        display: block;
        .sidebar-logo {
            position: relative;
        }
    }
    .slimScrollDiv {
        top: 0;
        height: calc(100% - 307px) !important;
        .sidebar-inner {
            height: 100% !important;
        }
    }
    .mini-sidebar {
        .sidebar-nav {
            display: none;
        }
        .sidebar-header {
            .input-group, .menu-item  {
                display: none !important;
            }
        }
        .sidebar-profile {
            padding: 0 !important;
            background: transparent !important;
            border-color: transparent !important;;
            .sidebar-profile-info {
                display: none;
            }
        }
        .slimScrollDiv {
            height: calc(100% - 110px) !important;
            .sidebar-inner {
                height: 100% !important;
            }
        }
        &.expand-menu {
            .sidebar-nav {
                display: block;
            }
            .sidebar-header {
                .input-group, .menu-item  {
                    display: flex !important;
                }
            }
            .sidebar-profile {
                padding: 8px !important;
                background: $light !important;
                border-color: $light !important;;
                .sidebar-profile-info {
                    display: block;
                }
            }
        }
    }
}

[data-layout="horizontal-single"] { 
    #horizontal-menu {
        display: none;
    }
    #horizontal-single {
        position: relative;
        top: auto;
        width: auto !important;
        box-shadow: none;
        display: block;
    }
    #header-search {
        display: none !important;
    }
    .header {
        padding: 0 24px;
        @include respond-below(xl) {
            padding: 0 16px;
        }
    }
    .menu-horizontal {
        .header {
            .main-header .user-menu {
                @include respond-below(xl) {
                    padding-left: 16px;
                }
            }
        }
        .page-wrapper {
            padding-top: 50px;    
            max-width: 100%;
            margin: 0 24px;
        }
        .header-collapse{
            .header-user{
                display: block;
            }
        }
    }
    .footer {
        margin: 0 -24px;
    }
    .sidebar-horizontal.sidebar {
        .sidebar-menu {
            padding:  0 !important;
            height: 46px;
            display: flex;
            align-items: center;
            margin: 0 auto;
            width: 100%;
            ul li.submenu > a {
                padding: 8px 21px 8px 8px;
                @include respond-above(xl) {
                    padding: 8px 30px 8px 8px;
                }
            }
            .nav-menu {
                & > li {
                    margin-right: 8px;     
                    @include respond-below(xl) {
                        margin-right: 1px;
                    }
                    a {
                        .menu-arrow {
                            @include respond-below(xl) {
                                right: 2px;
                            }                            
                        }
                    }
                }
            }
        }
    }
    .sidebar-menu {
        .nav-menu {
            & > li {
                margin-right: 8px;  
                &:last-child {
                    margin-right: 0;
                }
                &.submenu {
                    > ul {
                        max-height: 350px;
                        overflow-x: hidden;
                        overflow-y: auto;
                    }
                }
                @include respond-below(xxl) {
                    margin-right: 2px;
                }
            }
        }
    }
}
[data-layout="stacked"] { 
    #sidebar {
        @include respond-above(lg) {
            display: none;
        }
    }  
    #stacked-sidebar { 
        @include respond-above(lg) {
            display: block;
        }
    }
    .mini-sidebar {
        .sidebar {
            @include respond-above(lg) {
                overflow: unset;
            }
            .sidebar-right {
                display: none !important;
            }
        }
        &.expand-menu {
            .sidebar {
                .sidebar-right {
                    display: flex !important;
                }
            }
        }
    }
}
[data-layout="transparent"],.data-layout-transparent { 
    body {
        background-color: $primary-transparent;
    }
    .header {
        left: 0;
        border-bottom: 3px solid $primary;
        .header-left {
            display: block;
            padding-left: 24px;
        }
    }
    .sidebar {
        top: 50px;
        background-color: $primary-transparent;
        .sidebar-logo {
            display: none;
            .logo {
                display: none;
            }
        }
        .slimScrollDiv {
            top: 0;
        }
    }
    .mini-sidebar {
        &.expand-menu {
            @include respond-above(lg) {
                .header {
                    left: 0;
                }
            }
        }
    }
    .page-wrapper{
        .head-icons{
            display: none;
        }
    }
}
body.data-layout-transparent {
    background-color: $primary-transparent;
    .header {
        left: 0;
        border-bottom: 3px solid $primary;
        .header-left {
            display: block;
            padding-left: 24px;
        }
    }
    .sidebar {
        top: 50px;
        background-color: $primary-transparent;
        .sidebar-logo {
            display: none;
            .logo {
                display: none;
            }
        }
        .slimScrollDiv {
            top: 0;
        }
    }
    .mini-sidebar {
        &.expand-menu {
            @include respond-above(lg) {
                .header {
                    left: 0;
                }
            }
        }
    }
    .page-wrapper{
        .head-icons{
            display: none;
        }
    }
}
[data-layout="horizontal-sidemenu"] { 
    .header {
        left: 0;
        background-color: $gray-900;
        border-color: $gray-800;
        .main-header {
            max-width: 1320px;
            margin: 0 auto;
            @include respond-below(xxl) {
                max-width: 1170px;
            }
            @include respond-below(xl) {
                max-width: 950px;
            }
            @include respond-below(lg) {
                max-width: 100%;
            }
            .user-menu {
                padding-right: 0;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover {
                        background: $gray-600;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }   
    .main-wrapper {
        margin: 0 auto;
        @include respond-above(xxl) {
            max-width: 1320px;
        }
        @include respond-above(lg) {
            max-width: 95%;
        }
        &::before {
            content: "";
            height: 168px;
            background: $gray-900;
            width: 100%;
            position: absolute;
            top: 50px;
            left: 0;
            z-index: -1;
            @include respond-below(lg) {
                top: 50px;
                height: 100px;
            }
        }
        &::after {
            content: "";
            height: 168px;
            background-repeat: no-repeat;
            width: 100%;
            position: absolute;
            top: 50px;
            left: 0;
            z-index: -1;
            @include respond-below(lg) {
                top: 50px;
                height: 100px;
            }
        }
    }  
    .sidebar {
        top: 74px;
        left: unset;
        bottom: 24px;
        .slimScrollDiv {
            height: 100% !important;
            top: 0;
            .sidebar-inner {
                height: 100% !important;
            }
            .sidebar-menu {
                margin-top: 0;
                padding-bottom: 0;
            }
        }
        .sidebar-menu {
            margin-top: 16px;
        }
        .sidebar-logo {
            display: none;
        }
        @include respond-below(lg) {
            top: 50px;
            bottom: 0;
        }
    }
    .page-wrapper {
        @include respond-above(lg) {
            padding: 74px 0 0;
        }
        .head-icons{
            display: none;
        }
    }
    .expand-menu {
        &.mini-sidebar {
            .header {
                left: 0;
            }
        }
    }
}
[data-sidebar="dark"]{
    .sidebar {
        background: $gray-900;
        border-color: $gray-900;
        .dark-logo {
            display: block;
        }
        .logo {
            display: none;
        }
        .sidebar-menu {
            > ul {
                > li {
                    > ul {
                        > li {
                            > a {
                                &:hover {
                                    background: $gray-600;
                                }
                            }
                        }
                    }
                    .submenu {
                        > a {
                            i {
                                color: $gray-200;
                            }
                            &.subdrop {
                                background: $gray-600;
                            }
                            &:hover, &.active {
                                background: $gray-600;
                            }
                        }
                    }
                    &.active {
                        a {
                            span {
                                color: $primary;
                            }
                        }
                    }
                    ul {
                        li {
                            &.active {
                                a {
                                     i {
                                        color: $primary;
                                    }
                                }
                            }
                            a {
                                color: $gray-300;
                                &:hover, &.active{
                                    color:$primary;
                                }
                                i {
                                    color: $gray-200;
                                }
                                span {
                                    color: $gray-300;
                                }
                            }
                            &.active {
                                a {
                                    background: $gray-700;
                                    i {
                                        color: $gray-200;
                                    }
                                }
                            }
                            .submenu {
                                > ul {
                                    li {
                                        a {
                                            color: $gray-300;
                                            &.active, &:hover {
                                                color: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                li {                    
                    .submenu.submenu-two {
                        a {
                            &.subdrop, &.active, &:hover {
                                background: transparent;
                             }
                        }
                    }
                }
            }
            .menu-arrow {
                &::before {
                    border-color: $gray-200;
                }
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .nav-link { 
                color: $white;
                &.active, &:hover {
                    background: $gray-600;
                }
            }
            .sidebar-right {
                background: $gray-900;
                .sidebar-logo {
                    .dark-logo {
                        display: block;
                    }
                }
                h6, p {
                    color: $white;
                }
                .bg-light {
                    background-color: $gray-600 !important;
                    border-color: $gray-600 !important;
                }
                ul {
                    li {
                        a {
                            color: $white;
                            &.subdrop {
                                background: $gray-600;
                            }
                        }
                        &.subdrop {
                            background: $gray-600;
                            &:hover {
                                color: $white !important;
                            }
                        }
                        &.active{
                            a {
                                background: $gray-600;
                            }
                        }
                        &.submenu {                        
                            & > a {
                                &:hover {
                                    background: $gray-600;
                                    color: $white !important;
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                    & > ul {                
                         & > li {
                             > a {
                                 &:hover {
                                     background: $gray-600;
                                     color: $white !important;
                                 }
                             }
                         }
                     }
     
                 }
            }
        }
        .twocol-mini {
            background-color: $gray-900;
            border-color: $gray-800;
        }
    }
    .expand-menu {
        &.mini-sidebar {
            .sidebar-twocol {
                &.sidebar {
                    .logo {
                        @include respond-above(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .sidebar-stacked {
        .stacked-mini {
            background-color: $gray-900;
            border-color: $gray-800;
        }
        .btn-menubar {
            color: $white;
            border-color: $gray-900;
            &:hover, &.active {
                background: $gray-600;
            }
        }
        .sidebar-right {
            background: $gray-900;
            h6 {
                color: $white;
            }
            .sidebar-profile {
                h6 {
                    color: $gray-900;
                }
            }
            a {
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
        }
        .nav-link {
            color: $white;
            background: $gray-800;
            border-color: $gray-800;
            p, i {
                color: $white;
            }
            &:hover, &.active {
                color: $white;
                background: $gray-800;
                border-color: $gray-800;
                p {
                    color: $white;
                }
            }
        }
    }
}
[data-sidebar="darkgreen"]{
    .sidebar {
        background: $dark-green;
        border-color: $dark-green;
        .dark-logo {
            display: block;
        }
        .logo {
            display: none;
        }
        .sidebar-menu {
            > ul {
                > li {
                    > ul {
                        > li {
                            > a {
                                &:hover {
                                    background: $gray-600;
                                }
                            }
                        }
                    }
                    .submenu {
                        > a {
                            i {
                                color: $gray-200;
                            }
                            &.subdrop {
                                background: $gray-600;
                            }
                            &:hover, &.active {
                                background: $gray-600;
                            }
                        }
                    }
                    &.active {
                        a {
                            span {
                                color: $primary;
                            }
                        }
                    }
                    ul {
                        li {
                            &.active {
                                a {
                                     i {
                                        color: $primary;
                                    }
                                }
                            }
                            a {
                                color: $gray-300;
                                &:hover, &.active{
                                    color:$primary;
                                }
                                i {
                                    color: $gray-200;
                                }
                                span {
                                    color: $gray-300;
                                }
                            }
                            &.active {
                                a {
                                    background: $gray-700;
                                    i {
                                        color: $gray-200;
                                    }
                                }
                            }
                            .submenu {
                                > ul {
                                    li {
                                        a {
                                            color: $gray-300;
                                            &.active, &:hover {
                                                color: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                li {                    
                    .submenu.submenu-two {
                        a {
                            &.subdrop, &.active, &:hover {
                                background: transparent;
                             }
                        }
                    }
                }
            }
            .menu-arrow {
                &::before {
                    border-color: $gray-200;
                }
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .nav-link { 
                color: $white;
                &.active, &:hover {
                    background: $gray-600;
                }
            }
            .sidebar-right {
                background: $dark-green;
                .sidebar-logo {
                    .dark-logo {
                        display: block;
                    }
                }
                h6, p {
                    color: $white;
                }
                .bg-light {
                    background-color: $gray-600 !important;
                    border-color: $gray-600 !important;
                }
                ul {
                    li {
                        a {
                            color: $white;
                            &.subdrop {
                                background: $gray-600;
                            }
                        }
                        &.subdrop {
                            background: $gray-600;
                            &:hover {
                                color: $white !important;
                            }
                        }
                        &.active{
                            a {
                                background: $gray-600;
                            }
                        }
                        &.submenu {                        
                            & > a {
                                &:hover {
                                    background: $gray-600;
                                    color: $white !important;
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                    & > ul {                
                         & > li {
                             > a {
                                 &:hover {
                                     background: $gray-600;
                                     color: $white !important;
                                 }
                             }
                         }
                     }
     
                 }
            }
        }
        .twocol-mini {
            background-color: $dark-green;
            border-color: $gray-800;
        }
    }
    .expand-menu {
        &.mini-sidebar {
            .sidebar-twocol {
                &.sidebar {
                    .logo {
                        @include respond-above(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .sidebar-stacked {
        .stacked-mini {
            background-color: $dark-green;
            border-color: $gray-800;
        }
        .btn-menubar {
            color: $white;
            border-color: $dark-green;
            &:hover, &.active {
                background: $gray-600;
            }
        }
        .sidebar-right {
            background: $dark-green;
            h6 {
                color: $white;
            }
            .sidebar-profile {
                h6 {
                    color: $dark-green;
                }
            }
            a {
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
        }
        .nav-link {
            color: $white;
            background: $gray-800;
            border-color: $gray-800;
            p, i {
                color: $white;
            }
            &:hover, &.active {
                color: $white;
                background: $gray-800;
                border-color: $gray-800;
                p {
                    color: $white;
                }
            }
        }
    }
}
[data-sidebar="nightblue"]{
    .sidebar {
        background: $night-blue;
        border-color: $night-blue;
        .dark-logo {
            display: block;
        }
        .logo {
            display: none;
        }
        .sidebar-menu {
            > ul {
                > li {
                    > ul {
                        > li {
                            > a {
                                &:hover {
                                    background: $gray-600;
                                }
                            }
                        }
                    }
                    .submenu {
                        > a {
                            i {
                                color: $gray-200;
                            }
                            &.subdrop {
                                background: $gray-600;
                            }
                            &:hover, &.active {
                                background: $gray-600;
                            }
                        }
                    }
                    &.active {
                        a {
                            span {
                                color: $primary;
                            }
                        }
                    }
                    ul {
                        li {
                            &.active {
                                a {
                                     i {
                                        color: $primary;
                                    }
                                }
                            }
                            a {
                                color: $gray-300;
                                &:hover, &.active{
                                    color:$primary;
                                }
                                i {
                                    color: $gray-200;
                                }
                                span {
                                    color: $gray-300;
                                }
                            }
                            &.active {
                                a {
                                    background: $gray-700;
                                    i {
                                        color: $gray-200;
                                    }
                                }
                            }
                            .submenu {
                                > ul {
                                    li {
                                        a {
                                            color: $gray-300;
                                            &.active, &:hover {
                                                color: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                li {                    
                    .submenu.submenu-two {
                        a {
                            &.subdrop, &.active, &:hover {
                                background: transparent;
                             }
                        }
                    }
                }
            }
            .menu-arrow {
                &::before {
                    border-color: $gray-200;
                }
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .nav-link { 
                color: $white;
                &.active, &:hover {
                    background: $gray-600;
                }
            }
            .sidebar-right {
                background: $night-blue;
                .sidebar-logo {
                    .dark-logo {
                        display: block;
                    }
                }
                h6, p {
                    color: $white;
                }
                .bg-light {
                    background-color: $gray-600 !important;
                    border-color: $gray-600 !important;
                }
                ul {
                    li {
                        a {
                            color: $white;
                            &.subdrop {
                                background: $gray-600;
                            }
                        }
                        &.subdrop {
                            background: $gray-600;
                            &:hover {
                                color: $white !important;
                            }
                        }
                        &.active{
                            a {
                                background: $gray-600;
                            }
                        }
                        &.submenu {                        
                            & > a {
                                &:hover {
                                    background: $gray-600;
                                    color: $white !important;
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                    & > ul {                
                         & > li {
                             > a {
                                 &:hover {
                                     background: $gray-600;
                                     color: $white !important;
                                 }
                             }
                         }
                     }
     
                 }
            }
        }
        .twocol-mini {
            background-color: $night-blue;
            border-color: $gray-800;
        }
    }
    .expand-menu {
        &.mini-sidebar {
            .sidebar-twocol {
                &.sidebar {
                    .logo {
                        @include respond-above(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .sidebar-stacked {
        .stacked-mini {
            background-color: $night-blue;
            border-color: $gray-800;
        }
        .btn-menubar {
            color: $white;
            border-color: $night-blue;
            &:hover, &.active {
                background: $gray-100;
                color: $gray-700;
            }
        }
        .sidebar-right {
            background: $night-blue;
            h6 {
                color: $white;
            }
            .sidebar-profile {
                h6 {
                    color: $night-blue;
                }
            }
            a {
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
        }
        .nav-link {
            color: $white;
            background: lighten($night-blue, 6%);
            border-color: lighten($night-blue, 6%);
            p, i {
                color: $white;
            }
            &:hover, &.active {
                color: $white;
                background: lighten($night-blue, 4%);
                border-color: lighten($night-blue, 4%);
                p {
                    color: $white;
                }
            }
        }
    }
}
[data-sidebar="royalblue"]{
    .sidebar {
        background: $royal-blue;
        border-color: $royal-blue;
        .dark-logo {
            display: block;
        }
        .logo {
            display: none;
        }
        .sidebar-menu {
            > ul {
                > li {
                    > ul {
                        > li {
                            > a {
                                &:hover {
                                    background: $gray-100;
                                    span, i {
                                        color: $gray-700;
                                    }
                                }
                            }
                        }
                    }
                    .submenu {
                        > a {
                            i {
                                color: $gray-200;
                            }
                            &.subdrop {
                                background: $gray-100;
                                span, i {
                                    color: $gray-700;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-700;
                                    }
                                }
                            }
                            &:hover, &.active {
                                background: $gray-100;
                                span, i {
                                    color: $gray-700;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-700;
                                    }
                                }
                            }
                        }
                    }
                    &.active {
                        a {
                            span {
                                color: $primary;
                            }
                        }
                    }
                    ul {
                        li {
                            &.active {
                                a {
                                     i {
                                        color: $primary;
                                    }
                                }
                            }
                            a {
                                color: $gray-300;
                                &:hover, &.active{
                                    color:$primary;
                                }
                                i {
                                    color: $gray-200;
                                }
                                span {
                                    color: $gray-300;
                                }
                            }
                            &.active {
                                a {
                                    background: $gray-700;
                                    i {
                                        color: $gray-200;
                                    }
                                }
                            }
                            .submenu {
                                > ul {
                                    li {
                                        a {
                                            color: $gray-300;
                                            &.active, &:hover {
                                                color: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                li {                    
                    .submenu.submenu-two {
                        a {
                            &.subdrop, &.active, &:hover {
                                background: transparent;
                             }
                        }
                    }
                }
            }
            .menu-arrow {
                &::before {
                    border-color: $gray-200;
                }
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .nav-link { 
                color: $white;
                &.active, &:hover {
                    background: $gray-100;
                    color: $gray-700;
                }
            }
            .sidebar-right {
                background: $royal-blue;
                .sidebar-logo {
                    .dark-logo {
                        display: block;
                    }
                }
                .sidebar-scroll {
                    & > h6 {
                        color: $white;
                    }
                }
                ul {
                    li {
                        a {
                            color: $white;
                            &.subdrop {
                                background: $gray-100;
                                color: $gray-700;
                            }
                        }
                        &.subdrop {
                            background: $gray-100;
                            &:hover {
                                color: $gray-700 !important;
                            }
                        }
                        &.active{
                            a {
                                background: $gray-100;
                                color: $gray-700;
                            }
                        }
                        &.submenu {                        
                            & > a {
                                &:hover {
                                    background: $gray-100;
                                    color: $gray-700 !important;
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                    & > ul {                
                         & > li {
                             > a {
                                 &:hover {
                                     background: $gray-100;
                                     color: $gray-700 !important;
                                 }
                             }
                         }
                     }
     
                 }
            }
        }
        .twocol-mini {
            background-color: $royal-blue;
            border-color: $gray-400;
        }
    }
    .expand-menu {
        &.mini-sidebar {
            .sidebar-twocol {
                &.sidebar {
                    .logo {
                        @include respond-above(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .sidebar-stacked {
        .stacked-mini {
            background-color: $royal-blue;
            border-color: $gray-400;
        }
        .btn-menubar {
            color: $white;
            border-color: $royal-blue;
            &:hover, &.active {
                background: $gray-100;
                color: $gray-700;
            }
        }
        .sidebar-right {
            background: $royal-blue;
            h6 {
                color: $white;
            }
            .sidebar-profile {
                h6 {
                    color: $royal-blue;
                }
            }
            a {
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
        }
        .nav-link {
            color: $white;
            background: lighten($royal-blue, 6%);
            border-color: lighten($royal-blue, 6%);
            p, i {
                color: $white;
            }
            &:hover, &.active {
                color: $white;
                background: lighten($royal-blue, 4%);
                border-color: lighten($royal-blue, 4%);
                p {
                    color: $white;
                }
            }
        }
    }
}
[data-sidebar="darkgray"]{
    .sidebar {
        background: $dark-gray;
        border-color: $dark-gray;
        .dark-logo {
            display: block;
        }
        .logo {
            display: none;
        }
        .sidebar-menu {
            > ul {
                > li {
                    > ul {
                        > li {
                            > a {
                                &:hover {
                                    background: $gray-600;
                                }
                            }
                        }
                    }
                    .submenu {
                        > a {
                            i {
                                color: $gray-200;
                            }
                            &.subdrop {
                                background: $gray-600;
                            }
                            &:hover, &.active {
                                background: $gray-600;
                            }
                        }
                    }
                    &.active {
                        a {
                            span {
                                color: $primary;
                            }
                        }
                    }
                    ul {
                        li {
                            &.active {
                                a {
                                     i {
                                        color: $primary;
                                    }
                                }
                            }
                            a {
                                color: $gray-300;
                                &:hover, &.active{
                                    color:$primary;
                                }
                                i {
                                    color: $gray-200;
                                }
                                span {
                                    color: $gray-300;
                                }
                            }
                            &.active {
                                a {
                                    background: $gray-700;
                                    i {
                                        color: $gray-200;
                                    }
                                }
                            }
                            .submenu {
                                > ul {
                                    li {
                                        a {
                                            color: $gray-300;
                                            &.active, &:hover {
                                                color: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                li {                    
                    .submenu.submenu-two {
                        a {
                            &.subdrop, &.active, &:hover {
                                background: transparent;
                             }
                        }
                    }
                }
            }
            .menu-arrow {
                &::before {
                    border-color: $gray-200;
                }
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .nav-link { 
                color: $white;
                &.active, &:hover {
                    background: $gray-600;
                }
            }
            .sidebar-right {
                background: $dark-gray;
                .sidebar-logo {
                    .dark-logo {
                        display: block;
                    }
                }
                h6, p {
                    color: $white;
                }
                .bg-light {
                    background-color: $gray-600 !important;
                    border-color: $gray-600 !important;
                }
                ul {
                    li {
                        a {
                            color: $white;
                            &.subdrop {
                                background: $gray-600;
                            }
                        }
                        &.subdrop {
                            background: $gray-600;
                            &:hover {
                                color: $white !important;
                            }
                        }
                        &.active{
                            a {
                                background: $gray-600;
                            }
                        }
                        &.submenu {                        
                            & > a {
                                &:hover {
                                    background: $gray-600;
                                    color: $white !important;
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                    & > ul {                
                         & > li {
                             > a {
                                 &:hover {
                                     background: $gray-600;
                                     color: $white !important;
                                 }
                             }
                         }
                     }
     
                 }
            }
        }
        .twocol-mini {
            background-color: $dark-gray;
            border-color: $gray-800;
        }
    }
    .expand-menu {
        &.mini-sidebar {
            .sidebar-twocol {
                &.sidebar {
                    .logo {
                        @include respond-above(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .sidebar-stacked {
        .stacked-mini {
            background-color: $dark-gray;
            border-color: $gray-800;
        }
        .btn-menubar {
            color: $white;
            border-color: $dark-gray;
            &:hover, &.active {
                background: $gray-600;
            }
        }
        .sidebar-right {
            background: $dark-gray;
            h6 {
                color: $white;
            }
            .sidebar-profile {
                h6 {
                    color: $dark-gray;
                }
            }
            a {
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
        }
        .nav-link {
            color: $white;
            background: $gray-800;
            border-color: $gray-800;
            p, i {
                color: $white;
            }
            &:hover, &.active {
                color: $white;
                background: $gray-800;
                border-color: $gray-800;
                p {
                    color: $white;
                }
            }
        }
    }
}
[data-sidebar="indigo"]{
    #sidebar {
        background: $indigo;
        border-color: $indigo;
        .dark-logo {
            display: block;
        }
        .logo {
            display: none;
        }
        .sidebar-menu {
            > ul {
                > li {
                    
                    > ul {
                        > li {
                            > a {
                                &:hover {
                                    background: $gray-100;
                                    span, i {
                                        color: $gray-700;
                                    }
                                }
                            }
                        }
                    }
                    .submenu {
                        > a {
                            i {
                                color: $gray-200;
                            }
                            &.subdrop {
                                background: $gray-100;
                                span, i {
                                    color: $gray-700;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-700;
                                    }
                                }
                            }
                            &:hover, &.active {
                                background: $gray-100;
                                span, i {
                                    color: $gray-700;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-700;
                                    }
                                }
                            }
                        }
                    }
                    &.active {
                        a {
                            span {
                                color: $primary;
                            }
                        }
                    }
                    ul {
                        li {
                            &.active {
                                a {
                                     i {
                                        color: $primary;
                                    }
                                }
                            }
                            a {
                                color: $gray-300;
                                &:hover, &.active{
                                    color:$primary;
                                }
                                i {
                                    color: $gray-200;
                                }
                                span {
                                    color: $gray-300;
                                }
                            }
                            &.active {
                                a {
                                    background: $gray-700;
                                    i {
                                        color: $gray-200;
                                    }
                                }
                            }
                            .submenu {
                                > ul {
                                    li {
                                        a {
                                            color: $gray-300;
                                            &.active, &:hover {
                                                color: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                li {                    
                    .submenu.submenu-two {
                        a {
                            &.subdrop, &.active, &:hover {
                                background: transparent;
                             }
                        }
                    }
                }
            }
            .menu-arrow {
                &::before {
                    border-color: $gray-200;
                }
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .nav-link { 
                color: $white;
                &.active, &:hover {
                    background: $gray-100;
                    color: $gray-700;
                }
            }
            .sidebar-right {
                background: $indigo;
                .sidebar-logo {
                    .dark-logo {
                        display: block;
                    }
                }
                .sidebar-scroll {
                    & > h6 {
                        color: $white;
                    }
                }
                ul {
                    li {
                        a {
                            color: $white;
                            &.subdrop {
                                background: $gray-100;
                                color: $gray-700;
                            }
                        }
                        &.subdrop {
                            background: $gray-100;
                            &:hover {
                                color: $gray-700 !important;
                            }
                        }
                        &.active{
                            a {
                                background: $gray-100;
                                color: $gray-700;
                            }
                        }
                        &.submenu {                        
                            & > a {
                                &:hover {
                                    background: $gray-100;
                                    color: $gray-700 !important;
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                    & > ul {                
                         & > li {
                             > a {
                                 &:hover {
                                     background: $gray-100;
                                     color: $gray-700 !important;
                                 }
                             }
                         }
                     }
     
                }
            }
        }
        .twocol-mini {
            background-color: $indigo;
            border-color: $gray-800;
        }
    }
    .expand-menu {
        &.mini-sidebar {
            .sidebar-twocol {
                &.sidebar {
                    .logo {
                        @include respond-above(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .sidebar-stacked {
        .stacked-mini {
            background-color: $indigo;
            border-color: $gray-800;
        }
        .btn-menubar {
            color: $white;
            border-color: $indigo;
            &:hover, &.active {
                background: $gray-100;
                color: $gray-700;
            }
        }
        .sidebar-right {
            background: $indigo;
            h6 {
                color: $white;
            }
            .sidebar-profile {
                h6 {
                    color: $indigo;
                }
            }
            a {
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
        }
        .nav-link {
            color: $white;
            background: lighten($indigo, 6%);
            border-color: lighten($indigo, 6%);
            p, i {
                color: $white;
            }
            &:hover, &.active {
                color: $white;
                background: lighten($indigo, 4%);
                border-color: lighten($indigo, 4%);
                p {
                    color: $white;
                }
            }
        }
    }
}
[data-sidebar="all"]{
    $primarybg: rgb(var(--sidebar--rgb-picr));
    .sidebar {
        background: $primarybg;
        border-color: $primarybg;
        .dark-logo {
            display: block;
        }
        .logo {
            display: none;
        }
        .sidebar-menu {
            > ul {
                > li {
                    > ul {
                        > li {
                            > a {
                                &:hover {
                                    background: $gray-100;
                                    span, i {
                                        color: $gray-700;
                                    }
                                }
                            }
                        }
                    }
                    .submenu {
                        > a {
                            i {
                                color: $gray-200;
                            }
                            &.subdrop {
                                background: $gray-100;
                                span, i {
                                    color: $gray-700;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-700;
                                    }
                                }
                            }
                            &:hover, &.active {
                                background: $gray-100;
                                span, i {
                                    color: $gray-700;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-700;
                                    }
                                }
                            }
                        }
                    }
                    &.active {
                        a {
                            span {
                                color: $primary;
                            }
                        }
                    }
                    ul {
                        li {
                            &.active {
                                a {
                                     i {
                                        color: $primary;
                                    }
                                }
                            }
                            a {
                                color: $gray-300;
                                &:hover, &.active{
                                    color:$primary;
                                }
                                i {
                                    color: $gray-200;
                                }
                                span {
                                    color: $gray-300;
                                }
                            }
                            &.active {
                                a {
                                    background: $gray-700;
                                    i {
                                        color: $gray-200;
                                    }
                                }
                            }
                            .submenu {
                                > ul {
                                    li {
                                        a {
                                            color: $gray-300;
                                            &.active, &:hover {
                                                color: $primary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                li {                    
                    .submenu.submenu-two {
                        a {
                            &.subdrop, &.active, &:hover {
                                background: transparent;
                             }
                        }
                    }
                }
            }
            .menu-arrow {
                &::before {
                    border-color: $gray-200;
                }
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .nav-link { 
                color: $white;
                &.active, &:hover {
                    background: $gray-100;
                    color: $gray-700;
                }
            }
            .sidebar-right {
                background: $primarybg;
                .sidebar-logo {
                    .dark-logo {
                        display: block;
                    }
                }
                .sidebar-scroll {
                    & > h6 {
                        color: $white;
                    }
                }
                ul {
                    li {
                        a {
                            color: $white;
                            &.subdrop {
                                background: $gray-100;
                                color: $gray-700;
                            }
                        }
                        &.subdrop {
                            background: $gray-100;
                            &:hover {
                                color: $gray-700 !important;
                            }
                        }
                        &.active{
                            a {
                                background: $gray-100;
                                color: $gray-700;
                            }
                        }
                        &.submenu {                        
                            & > a {
                                &:hover {
                                    background: $gray-100;
                                    color: $gray-700 !important;
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                    & > ul {                
                         & > li {
                             > a {
                                 &:hover {
                                     background: $gray-100;
                                     color: $gray-700 !important;
                                 }
                             }
                         }
                     }
     
                 }
            }
        }
        .twocol-mini {
            background-color: $primarybg;
            border-color: $gray-400;
        }
    }
    .expand-menu {
        &.mini-sidebar {
            .sidebar-twocol {
                &.sidebar {
                    .logo {
                        @include respond-above(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .sidebar-stacked {
        .stacked-mini {
            background-color: $primarybg;
            border-color: $gray-400;
        }
        .btn-menubar {
            color: $white;
            border-color: $primarybg;
            &:hover, &.active {
                background: $gray-100;
                color: $gray-700;
            }
        }
        .sidebar-right {
            background: $primarybg;
            h6 {
                color: $white;
            }
            .sidebar-profile {
                h6 {
                    color: $primarybg;
                }
            }
            a {
                color: $white;
                &:hover {
                    color: $primarybg;
                }
            }
        }
        .nav-link {
            color: $white;
            background: rgba(var(--sidebar--rgb-picr), 0.7);
            border-color: rgba(var(--sidebar--rgb-picr), 0.7);
            p, i {
                color: $white;
            }
            &:hover, &.active {
                color: $white;
                background: rgba(var(--sidebar--rgb-picr), 0.4);
                border-color: rgba(var(--sidebar--rgb-picr), 0.4);
                p {
                    color: $white;
                }
            }
        }
    }
}
[data-layout="default"][data-topbar="darkaqua"],
[data-layout="mini"][data-topbar="darkaqua"],
[data-layout="horizontal"][data-topbar="darkaqua"],
[data-layout="horizontal-single"][data-topbar="darkaqua"],
[data-layout="detached"][data-topbar="darkaqua"],
[data-layout="twocolumn"][data-topbar="darkaqua"],
[data-layout="stacked"][data-topbar="darkaqua"],
[data-layout="transparent"][data-topbar="darkaqua"] {
    .header {
        background: $darkaqua;
        border-bottom-color: $darkaqua;
        .bg-white {
            background: transparent  !important;
        }
        .header-left {
            background: $darkaqua;
            border-color: $darkaqua;
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover, &.active {
                        color: $gray-700;
                    }
                }
            }
        }
        .sidebar-horizontal.sidebar {
            .sidebar-menu {
                background: $darkaqua;
                .nav-menu {
                    & > li.submenu {
                        & > a {
                            color: $white;
                            .menu-arrow {
                                &::before {
                                    border-color: $white;
                                }
                            }
                            &:hover, &.active, &.subdrop {
                                color: $gray-700;
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-700;
                                    }
                                }
                            }
                        }
                    }
                } 
            }
        }
    }
    .menu-horizontal {
        .header {
            .header-left {
                .logo {
                    display: none;
                }
                .dark-logo {
                    display: block;
                }
            }
        }
    }
}
[data-layout="default"][data-topbar="rockblue"],
[data-layout="mini"][data-topbar="rockblue"],
[data-layout="horizontal"][data-topbar="rockblue"],
[data-layout="horizontal-single"][data-topbar="rockblue"],
[data-layout="detached"][data-topbar="rockblue"],
[data-layout="twocolumn"][data-topbar="rockblue"],
[data-layout="stacked"][data-topbar="rockblue"],
[data-layout="transparent"][data-topbar="rockblue"] {
    .header {
        background: $rockblue;
        border-bottom-color: $rockblue;
        .bg-white {
            background: transparent  !important;
        }
        .header-left {
            background: $rockblue;
            border-color: $rockblue;
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $gray-900;
                    &:hover, &.active {
                        background: $white;
                    }
                }
            }
        }
        .sidebar-horizontal.sidebar {
            .sidebar-menu {
                background: $rockblue;
            }
        }
    }
}
[data-layout="default"][data-topbar="whiterock"],
[data-layout="mini"][data-topbar="whiterock"],
[data-layout="horizontal"][data-topbar="whiterock"],
[data-layout="horizontal-single"][data-topbar="whiterock"],
[data-layout="detached"][data-topbar="whiterock"],
[data-layout="twocolumn"][data-topbar="whiterock"],
[data-layout="stacked"][data-topbar="whiterock"],
[data-layout="transparent"][data-topbar="whiterock"] {
    .header {
        background: $whiterock;
        border-bottom-color: $whiterock;
        .bg-white {
            background: transparent  !important;
        }
        .header-left {
            background: $whiterock;
            border-color: $whiterock;
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $gray-900;
                }
            }
        }
        .sidebar-horizontal.sidebar {
            .sidebar-menu {
                background: $whiterock;
            }
        }
    }
}
[data-layout="default"][data-topbar="bluehaze"],
[data-layout="mini"][data-topbar="bluehaze"],
[data-layout="horizontal"][data-topbar="bluehaze"],
[data-layout="horizontal-single"][data-topbar="bluehaze"],
[data-layout="detached"][data-topbar="bluehaze"],
[data-layout="twocolumn"][data-topbar="bluehaze"],
[data-layout="stacked"][data-topbar="bluehaze"],
[data-layout="transparent"][data-topbar="bluehaze"] {
    .header {
        background: $bluehaze;
        border-bottom-color: $bluehaze;
        .bg-white {
            background: transparent  !important;
        }
        .header-left {
            background: $bluehaze;
            border-color: $bluehaze;
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $gray-900;
                }
            }
        }
        .sidebar-horizontal.sidebar {
            .sidebar-menu {
                background: $bluehaze;
            }
        }
    }
}
[data-layout="default"][data-topbar="orangegradient"],
[data-layout="mini"][data-topbar="orangegradient"],
[data-layout="horizontal"][data-topbar="orangegradient"],
[data-layout="horizontal-single"][data-topbar="orangegradient"],
[data-layout="detached"][data-topbar="orangegradient"],
[data-layout="twocolumn"][data-topbar="orangegradient"],
[data-layout="stacked"][data-topbar="orangegradient"],
[data-layout="transparent"][data-topbar="orangegradient"] {
    .header {
        background: $orange-gradient;
        border-bottom-color: $orange-gradient;
        .bg-white {
            background: transparent  !important;
        }
        .header-left {
            background: $orange-gradient;
            border-color: $orange-gradient;
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                }
            }
        }
        .sidebar-horizontal.sidebar {
            .sidebar-menu {
                background: $orange-gradient;
            }
        }
    }
}
[data-layout="default"][data-topbar="purplegradient"],
[data-layout="mini"][data-topbar="purplegradient"],
[data-layout="horizontal"][data-topbar="purplegradient"],
[data-layout="horizontal-single"][data-topbar="purplegradient"],
[data-layout="detached"][data-topbar="purplegradient"],
[data-layout="twocolumn"][data-topbar="purplegradient"],
[data-layout="stacked"][data-topbar="purplegradient"],
[data-layout="transparent"][data-topbar="purplegradient"] {
    .header {
        background: $purple-gradient;
        border-bottom-color: $purple-gradient;
        .bg-white {
            background: transparent  !important;
        }
        .header-left {
            background: $purple-gradient;
            border-color: $purple-gradient;
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                }
            }
        }
        .sidebar-horizontal.sidebar {
            .sidebar-menu {
                background: $purple-gradient;
            }
        }
    }
}
[data-layout="default"][data-topbar="bluegradient"],
[data-layout="mini"][data-topbar="bluegradient"],
[data-layout="horizontal"][data-topbar="bluegradient"],
[data-layout="horizontal-single"][data-topbar="bluegradient"],
[data-layout="detached"][data-topbar="bluegradient"],
[data-layout="twocolumn"][data-topbar="bluegradient"],
[data-layout="stacked"][data-topbar="bluegradient"],
[data-layout="transparent"][data-topbar="bluegradient"] {
    .header {
        background: $blue-gradient;
        border-bottom-color: $blue-gradient;
        .bg-white {
            background: transparent  !important;
        }
        .header-left {
            background: $blue-gradient;
            border-color: $blue-gradient;
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                }
            }
        }
        .sidebar-horizontal.sidebar {
            .sidebar-menu {
                background: $blue-gradient;
            }
        }
    }
}
[data-layout="default"][data-topbar="maroongradient"],
[data-layout="mini"][data-topbar="maroongradient"],
[data-layout="horizontal"][data-topbar="maroongradient"],
[data-layout="horizontal-single"][data-topbar="maroongradient"],
[data-layout="detached"][data-topbar="maroongradient"],
[data-layout="twocolumn"][data-topbar="maroongradient"],
[data-layout="stacked"][data-topbar="maroongradient"],
[data-layout="transparent"][data-topbar="maroongradient"] {
    .header {
        background: $maroon-gradient;
        border-bottom-color: $maroon-gradient;
        .bg-white {
            background: transparent  !important;
        }
        .header-left {
            background: $maroon-gradient;
            border-color: $maroon-gradient;
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                }
            }
        }
        .sidebar-horizontal.sidebar {
            .sidebar-menu {
                background: $maroon-gradient;
            }
        }
    }
}
[data-layout="default"][data-topbar="all"],
[data-layout="mini"][data-topbar="all"],
[data-layout="horizontal"][data-topbar="all"],
[data-layout="horizontal-single"][data-topbar="all"],
[data-layout="detached"][data-topbar="all"],
[data-layout="twocolumn"][data-topbar="all"],
[data-layout="stacked"][data-topbar="all"],
[data-layout="transparent"][data-topbar="all"] {
    $primary: rgb(var(--topbar--rgb-picr));
    .header {
        background: $primary;
        border-bottom-color: $primary;
        .bg-white {
            background: transparent  !important;
        }
        .header-left {
            background: $primary;
            border-color: $primary;
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover, &.active {
                        color: $gray-700;
                    }
                }
            }
        }
        .sidebar-horizontal.sidebar {
            .sidebar-menu {
                background: $primary;
                .nav-menu {
                    & > li.submenu {
                        & > a {
                            color: $white;
                            .menu-arrow {
                                &::before {
                                    border-color: $white;
                                }
                            }
                            &:hover, &.active, &.subdrop {
                                color: $gray-700;
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-700;
                                    }
                                }
                            }
                        }
                    }
                } 
            }
        }
    }
    .menu-horizontal {
        .header {
            .header-left {
                .logo {
                    display: none;
                }
                .dark-logo {
                    display: block;
                }
            }
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="dark"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="dark"]  {   
    .header {
        left: 0;
        background-color: $gray-900;
        border-color: $gray-800;
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover {
                        background: $gray-600;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }      
    .mode-toggle {
        color: $white;
    }
    .sidebar-horizontal {
        &.sidebar {
            background-color: $gray-900;
            border-bottom: 1px solid $gray-800;
            .sidebar-menu {
                .nav-menu {
                    > li {     
                        &.submenu {
                            & > a {
                                color: $white;
                                &.active, &:hover {
                                    background: $gray-600;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-right: 2px solid $white;
                                        border-bottom: 2px solid $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        &::before {
            background: $gray-900;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="primary"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="primary"] {
    .header {
        left: 0;
        background-color: $primary;
        border-color: $primary-800;
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover {
                        background: $primary-600;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }      
    .mode-toggle {
        color: $white;
    }
    .sidebar-horizontal {
        &.sidebar {
            background-color: $primary;
            border-bottom: 1px solid $primary-800;
            .sidebar-menu {
                .nav-menu {
                    > li {     
                        &.submenu {
                            & > a {
                                color: $white;
                                &.active, &:hover {
                                    background: $primary-600;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-right: 2px solid $white;
                                        border-bottom: 2px solid $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        &::before {
            content: "";
            background: $primary;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="blackpearl"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="blackpearl"] {
    .header {
        left: 0;
        background-color: $blackpearl;
        border-color: $gray-800;
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover {
                        background: $gray-600;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }      
    .mode-toggle {
        color: $white;
    }
    .sidebar-horizontal {
        &.sidebar {
            background-color: $blackpearl;
            border-bottom: 1px solid $gray-800;
            .sidebar-menu {
                .nav-menu {
                    > li {     
                        &.submenu {
                            & > a {
                                color: $white;
                                &.active, &:hover,  &.subdrop {
                                    background: $gray-600;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-right: 2px solid $white;
                                        border-bottom: 2px solid $white;
                                    }
                                }
                            }
                        }
                    }
                    .menu-arrow {
                        &::before {
                            border-color: $gray-700;
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        &::before {
            content: "";
            background: $blackpearl;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="maroon"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="maroon"] {
    .header {
        left: 0;
        background-color: $maroon;
        border-color: lighten($maroon, 0.9);
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover {
                        background: $dark-transparent;
                        color: $gray-700;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }      
    .mode-toggle {
        color: $white;
    }
    .sidebar-horizontal {
        &.sidebar {
            background-color: $maroon;
            border-bottom: 1px solid lighten($maroon, 0.9);
            .sidebar-menu {
                .nav-menu {
                    > li {     
                        &.submenu {
                            & > a {
                                color: $white;
                                &.active, &:hover,  &.subdrop {
                                    background-color: $dark-transparent;
                                    color: $gray-900;
                                    .menu-arrow {
                                        &::before {
                                            border-color: $gray-900;
                                        }
                                    }
                                }
                                .menu-arrow {
                                    &::before {
                                        border-right: 2px solid $white;
                                        border-bottom: 2px solid $white;
                                    }
                                }
                            }
                        }
                    }
                    .menu-arrow {
                        &::before {
                            border-color: $gray-700;
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        &::before {
            content: "";
            background: $maroon;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="white"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="white"] {
    .header {
        left: 0;
        background-color: $white;
        border-color: lighten($white, 0.9);
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $gray-500;
                    &:hover {
                        background: $dark-transparent;
                        color: $gray-900;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: block;
            }
            .dark-logo {
                display: none;
            }
        }
    }      
    .mode-toggle {
        color: $gray-700;
    }
    .sidebar-horizontal {
        &.sidebar {
            background-color: $white;
            border-bottom: 1px solid lighten($white, 0.9);
            .sidebar-menu {
                .nav-menu {
                    > li {     
                        &.submenu {
                            & > a {
                                color: $gray-900;
                                &.active, &:hover,  &.subdrop {
                                    background-color: $dark-transparent;
                                    color: $gray-900;
                                    .menu-arrow {
                                        &::before {
                                            border-color: $gray-900;
                                        }
                                    }
                                }
                                .menu-arrow {
                                    &::before {
                                        border-color: $gray-900;;
                                    }
                                }
                            }
                        }
                    }
                    .menu-arrow {
                        &::before {
                            border-color: $gray-900;
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        &::before {
            content: "";
            background: $white;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="bluegem"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="bluegem"] {
    .header {
        left: 0;
        background-color: $bluegem;
        border-color: lighten($bluegem, 0.9);
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover {
                        background: $dark-transparent;
                        color: $gray-700;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }      
    .mode-toggle {
        color: $white;
    }
    .sidebar-horizontal {
        &.sidebar {
            background-color: $bluegem;
            border-bottom: 1px solid lighten($bluegem, 0.9);
            .sidebar-menu {
                .nav-menu {
                    > li {     
                        &.submenu {
                            & > a {
                                color: $white;
                                &.active, &:hover,  &.subdrop {
                                    background-color: $dark-transparent;
                                    color: $gray-900;
                                    .menu-arrow {
                                        &::before {
                                            border-color: $gray-900;
                                        }
                                    }
                                }
                                .menu-arrow {
                                    &::before {
                                        border-right: 2px solid $white;
                                        border-bottom: 2px solid $white;
                                    }
                                }
                            }
                        }
                    }
                    .menu-arrow {
                        &::before {
                            border-color: $gray-700;
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        &::before {
            content: "";
            background: $bluegem;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="all"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="all"]  {
    $primary: rgb(var(--topbarcolor--rgb-picr));   
    .header {
        left: 0;
        background-color: $primary;
        border-color: $primary;
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover {
                        background: $dark-transparent;
                        color: $gray-700;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }      
    .mode-toggle {
        color: $white;
    }
    .sidebar-horizontal {
        &.sidebar {
            background-color: $primary;
            border-bottom: 1px solid $primary;
            .sidebar-menu {
                .nav-menu {
                    > li {     
                        &.submenu {
                            & > a {
                                color: $white;
                                &.active, &:hover,  &.subdrop {
                                    background-color: $dark-transparent;
                                    color: $gray-900;
                                    .menu-arrow {
                                        &::before {
                                            border-color: $gray-900;
                                        }
                                    }
                                }
                                .menu-arrow {
                                    &::before {
                                        border-right: 2px solid $white;
                                        border-bottom: 2px solid $white;
                                    }
                                }
                            }
                        }
                    }
                    .menu-arrow {
                        &::before {
                            border-color: $gray-700;
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        &::before {
            content: "";
            background: $primary;
        }
    }
}
[data-sidebarbg="sidebarbg1"]{
    #sidebar {
        background-image: url(../../../../public/assets/img/theme/bg-01.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-color: transparent;
        &::before  {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="firefly"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="firefly"] {
    .header {
        left: 0;
        background-color: $firefly;
        border-color: $gray-800;
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover {
                        background: $gray-600;
                    }
                }
            }
        }
        .header-left {
            display: block;
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }      
    .mode-toggle {
        color: $white;
    }
    .sidebar-horizontal {
        &.sidebar {
            background-color: $firefly;
            border-bottom: 1px solid $gray-800;
            .sidebar-menu {
                .nav-menu {
                    > li {     
                        &.submenu {
                            & > a {
                                color: $white;
                                &.active, &:hover,  &.subdrop {
                                    background: $gray-600;
                                }
                                .menu-arrow {
                                    &::before {
                                        border-right: 2px solid $white;
                                        border-bottom: 2px solid $white;
                                    }
                                }
                            }
                        }
                    }
                    .menu-arrow {
                        &::before {
                            border-color: $gray-700;
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        &::before {
            content: "";
            background: $firefly;
        }
    }
}
[data-sidebarbg="sidebarbg1"]{
    #sidebar {
        background-image: url(../../../../public/assets/img/theme/bg-01.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-color: transparent;
        &::before  {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;    
            z-index: -1;
        }
    }
}
[data-sidebarbg="sidebarbg2"]{
    #sidebar {
        background-image: url(../../../../public/assets/img/theme/bg-02.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-color: transparent;
        &::before  {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
[data-sidebarbg="sidebarbg3"]{
    #sidebar {
        background-image: url(../../../../public/assets/img/theme/bg-03.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-color: transparent;
        &::before  {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
[data-sidebarbg="sidebarbg4"]{
    #sidebar {
        background-image: url(../../../../public/assets/img/theme/bg-04.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-color: transparent;
        &::before  {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
[data-sidebarbg="sidebarbg5"]{
    #sidebar {
        background-image: url(../../../../public/assets/img/theme/bg-05.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-color: transparent;
        &::before  {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
[data-sidebarbg="sidebarbg6"] {
    #sidebar {
        background-image: url(../../../../public/assets/img/theme/bg-06.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-color: transparent;
        &::before  {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
        }
    }
}
[data-sidebar="light"] [data-sidebarbg="sidebarbg1"],
[data-sidebar="light"] [data-sidebarbg="sidebarbg2"],
[data-sidebar="light"] [data-sidebarbg="sidebarbg3"],
[data-sidebar="light"] [data-sidebarbg="sidebarbg4"],
[data-sidebar="light"] [data-sidebarbg="sidebarbg5"],
[data-sidebar="light"] [data-sidebarbg="sidebarbg6"] {
    #sidebar {
       &::before  {
        background: rgba($white, 0.85);
       }
    }
}
[data-sidebar="darkgreen"] [data-sidebarbg="sidebarbg1"],
[data-sidebar="darkgreen"] [data-sidebarbg="sidebarbg2"],
[data-sidebar="darkgreen"] [data-sidebarbg="sidebarbg3"],
[data-sidebar="darkgreen"] [data-sidebarbg="sidebarbg4"],
[data-sidebar="darkgreen"] [data-sidebarbg="sidebarbg5"],
[data-sidebar="darkgreen"] [data-sidebarbg="sidebarbg6"] {
    #sidebar {
       &::before  {
        background: rgba($dark-green, 0.85);
       }
    }
}
[data-sidebar="nightblue"] [data-sidebarbg="sidebarbg1"],
[data-sidebar="nightblue"] [data-sidebarbg="sidebarbg2"],
[data-sidebar="nightblue"] [data-sidebarbg="sidebarbg3"],
[data-sidebar="nightblue"] [data-sidebarbg="sidebarbg4"],
[data-sidebar="nightblue"] [data-sidebarbg="sidebarbg5"],
[data-sidebar="nightblue"] [data-sidebarbg="sidebarbg6"] {
    #sidebar {
       &::before  {
        background: rgba($night-blue, 0.85);
       }
    }
}
[data-sidebar="darkgray"] [data-sidebarbg="sidebarbg1"],
[data-sidebar="darkgray"] [data-sidebarbg="sidebarbg2"],
[data-sidebar="darkgray"] [data-sidebarbg="sidebarbg3"],
[data-sidebar="darkgray"] [data-sidebarbg="sidebarbg4"],
[data-sidebar="darkgray"] [data-sidebarbg="sidebarbg5"],
[data-sidebar="darkgray"] [data-sidebarbg="sidebarbg6"] {
    #sidebar {
       &::before  {
        background: rgba($dark-gray, 0.85);
       }
    }
}
[data-sidebar="royalblue"] [data-sidebarbg="sidebarbg1"],
[data-sidebar="royalblue"] [data-sidebarbg="sidebarbg2"],
[data-sidebar="royalblue"] [data-sidebarbg="sidebarbg3"],
[data-sidebar="royalblue"] [data-sidebarbg="sidebarbg4"],
[data-sidebar="royalblue"] [data-sidebarbg="sidebarbg5"],
[data-sidebar="royalblue"] [data-sidebarbg="sidebarbg6"] {
    #sidebar {
       &::before  {
        background: rgba($royal-blue, 0.85);
       }
    }
}
[data-sidebar="indigo"] [data-sidebarbg="sidebarbg1"],
[data-sidebar="indigo"] [data-sidebarbg="sidebarbg2"],
[data-sidebar="indigo"] [data-sidebarbg="sidebarbg3"],
[data-sidebar="indigo"] [data-sidebarbg="sidebarbg4"],
[data-sidebar="indigo"] [data-sidebarbg="sidebarbg5"],
[data-sidebar="indigo"] [data-sidebarbg="sidebarbg6"] {
    #sidebar {
       &::before  {
        background: rgba($indigo, 0.85);
       }
    }
}
[data-sidebar="all"] [data-sidebarbg="sidebarbg1"],
[data-sidebar="all"] [data-sidebarbg="sidebarbg2"],
[data-sidebar="all"] [data-sidebarbg="sidebarbg3"],
[data-sidebar="all"] [data-sidebarbg="sidebarbg4"],
[data-sidebar="all"] [data-sidebarbg="sidebarbg5"],
[data-sidebar="all"] [data-sidebarbg="sidebarbg6"] {
    #sidebar {
       &::before  {
        background: rgba(var(--sidebar--rgb-picr), 0.85);
       }
    }
}
[data-layout="horizontal-overlay"] [data-topbarbg="pattern1"],
[data-layout="horizontal-sidemenu"] [data-topbarbg="pattern1"] {
    .main-wrapper {
        &::after {
            background: url('../../../../public/assets/img/theme/pattern-01.png');
        }
    }
}
[data-layout="horizontal-overlay"] [data-topbarbg="pattern2"],
[data-layout="horizontal-sidemenu"] [data-topbarbg="pattern2"] {
    .main-wrapper {
        &::after {
            background: url('../../../../public/assets/img/theme/pattern-01.png');
        }
    }
}
[data-layout="horizontal-overlay"] [data-topbarbg="pattern3"],
[data-layout="horizontal-sidemenu"] [data-topbarbg="pattern3"] {
    .main-wrapper {
        &::after {
            background: url('../../../../public/assets/img/theme/pattern-03.png');
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="maroon"] [data-topbarbg="pattern3"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="maroon"] [data-topbarbg="pattern3"] {
    .main-wrapper {
        &::before {
            background: rgba($maroon, 0.85);
            z-index: 1;
        }
        .page-wrapper {
            z-index: 2;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="white"] [data-topbarbg="pattern3"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="white"] [data-topbarbg="pattern3"] {
    .main-wrapper {
        &::before {
            background: rgba($white, 0.85);
            z-index: 1;
        }
        .page-wrapper {
            z-index: 2;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="primary"] [data-topbarbg="pattern3"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="primary"] [data-topbarbg="pattern3"] {
    .main-wrapper {
        &::before {
            background: rgba($primary, 0.85);
            z-index: 1;
        }
        .page-wrapper {
            z-index: 2;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="blackpearl"] [data-topbarbg="pattern3"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="blackpearl"] [data-topbarbg="pattern3"] {
    .main-wrapper {
        &::before {
            background: rgba($blackpearl, 0.85);
            z-index: 1;
        }
        .page-wrapper {
            z-index: 2;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="bluegem"] [data-topbarbg="pattern3"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="bluegem"] [data-topbarbg="pattern3"] {
    .main-wrapper {
        &::before {
            background: rgba($bluegem, 0.85);
            z-index: 1;
        }
        .page-wrapper {
            z-index: 2;
        }
    }
}
[data-layout="horizontal-overlay"][data-topbarcolor="firefly"] [data-topbarbg="pattern3"],
[data-layout="horizontal-sidemenu"][data-topbarcolor="firefly"] [data-topbarbg="pattern3"] {
    .main-wrapper {
        &::before {
            background: rgba($firefly, 0.85);
            z-index: 1;
        }
        .page-wrapper {
            z-index: 2;
        }
    }
}
[data-layout="modern"][data-topbarcolor="white"] {
    .header {
        background-color: $white;
        border-color: rgba($white, 0.8);
        .bar-icon {
            span {
                background-color: $white;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $gray-500;
                    &:hover, &:active, &.active {
                        background-color: $dark-transparent;
                        border-color: $dark-transparent;
                        color: $gray-900 !important;
                    }
                }
            }
        }
        .header-left {
            .logo {
                display: block;
            }
            .dark-logo {
                display: none;
            }
        }
    }
    .page-wrapper {
        &::before {
            background: $white;
        }
        .head-icons{
            display: none;
        }
    }    
}
[data-layout="modern"][data-topbarcolor="blue"] {
    .header {
        background-color: $white;
        border-color: rgba($white, 0.8);
        .bar-icon {
            span {
                background-color: $white;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $gray-500;
                    &:hover, &:active, &.active {
                        background-color: $dark-transparent;
                        border-color: $dark-transparent;
                        color: $gray-900 !important;
                    }
                }
            }
        }
        .header-left {
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }
    .page-wrapper {
        &::before {
            background: $white;
        }
    }    
}
[data-layout="modern"][data-topbarcolor="primary"] {
    .header {
        background-color: $primary;
        border-color: $primary-800;
        .bar-icon {
            span {
                background-color: $white;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover, &:active, &.active {
                        background-color: $white;
                        color: $gray-700 !important;
                    }
                }
            }
        }
        .header-left {
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }
    .page-wrapper {
        &::before {
            background: $primary;
        }
    }    
}
[data-layout="modern"][data-topbarcolor="blackpearl"] {
    .header {
        background-color: $blackpearl;
        border-color: rgba($blackpearl, 0.8);
        .bar-icon {
            span {
                background-color: $white;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover, &:active, &.active {
                        background-color: $white;
                        color: $gray-700 !important;
                    }
                }
            }
        }
        .header-left {
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }
    .page-wrapper {
        &::before {
            background: $blackpearl;
        }
    }    
}
[data-layout="modern"][data-topbarcolor="maroon"] {
    .header {
        background-color: $maroon;
        border-color: rgba($maroon, 0.8);
        .bar-icon {
            span {
                background-color: $white;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover, &:active, &.active {
                        background-color: $white;
                        color: $gray-700 !important;
                    }
                }
            }
        }
        .header-left {
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }
    .page-wrapper {
        &::before {
            background: $maroon;
        }
    }    
}
[data-layout="modern"][data-topbarcolor="bluegem"] {
    .header {
        background-color: $bluegem;
        border-color: rgba($bluegem, 0.8);
        .bar-icon {
            span {
                background-color: $white;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover, &:active, &.active {
                        background-color: $white;
                        color: $gray-700 !important;
                    }
                }
            }
        }
        .header-left {
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }
    .page-wrapper {
        &::before {
            background: $bluegem;
        }
    }    
}
[data-layout="modern"][data-topbarcolor="firefly"] {
    .header {
        background-color: $firefly;
        border-color: rgba($firefly, 0.8);
        .bar-icon {
            span {
                background-color: $white;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $white;
                    &:hover, &:active, &.active {
                        background-color: $white;
                        color: $gray-700 !important;
                    }
                }
            }
        }
        .header-left {
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
    }
    .page-wrapper {
        &::before {
            background: $firefly;
        }
    }    
}
[data-card="borderless"] {
    .card {
        border: 0 !important;;
        box-shadow: none;
    }
}
[data-card="shadow"] {
    .card {
        border: 0 !important;
        box-shadow: $box-shadow-sm;
    }
}
[data-color="primary"]{
    $primary: #ff0000;
    .sidebar {
        .sidebar-menu > ul {
            &  > li {
                ul {
                    li {
                        a {
                            &.active {
                                color: $primary;
                            }
                        }
                    }
                    & > li {
                        &.active a{
                            .menu-arrow {
                                &::before {
                                    border-right: 2px solid $primary;
                                    border-bottom: 2px solid $primary;
                                }
                            }
                            &.subdrop{
                                color: $primary;
                                span {
                                    color: $primary;
                                    
                                }
                            }
                        }
                    }
                }
            }
            li {
                .submenu > {
                    ul{
                        li{
                            a{
                                &:before {
                                    background: $primary;
                                }
                                &.active {
                                    color: $primary;
                                }
                                &:hover {                                    
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                .submenu.submenu-two {
                    a {
                        &.subdrop, &.active {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .sidebar-right {
                ul {
                    li {
                        &.submenu {    
                            ul {
                                & > li {
                                    & > a{
                                         &:hover, &.active {
                                             color: $primary;
                                         }
                                     }
                                 }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-primary {
        background-color: $primary;
        border: 1px solid $primary;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($primary, 7%);
            border: 1px solid darken($primary, 7%);
        }
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn.show:hover, .btn:first-child:active, :not(.btn-check)+.btn:active {
        background-color: $primary;
        border-color: $primary;
    }
    .link-primary {
        color: $primary !important;
        &:hover,
        &:focus,
        &:active {
          color: darken($primary, 7%);
        }
    }
    .text-primary {
        color: $primary !important;
    }
    .badge {
        &.badge-primary {
            background: $primary;
        }
    }
    .dataTables_paginate .pagination li.active a.page-link {
        background: $primary;
        border-color: $primary;
    }
    .bg-primary {
        background-color: $primary !important;
        border: 1px solid $primary !important;
    }
    .border-bottom.border-primary {
        border-color: $primary !important;
    }
    .border-primary {
        border-color: $primary !important;
    }
    .nav-tabs.nav-tabs-bottom li a.active, .nav-tabs.nav-tabs-bottom li a:hover {
        border-bottom-color: $primary;
        color: $primary;
    }
    .badge {
        &.badge-soft-primary {
            background: rgba($primary, 0.1);
            color: $primary;

        }
    }
    a {
        &:hover {
            color: $primary;
        }
        &.video-expand { 
            &:hover {
                color: $white;
            }
        }
    }
    .btn-outline-primary {
        border: 1px solid $primary !important;
        color: $primary !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $primary !important;
            border:1px solid $primary !important;
            color: $white !important;
        }
    }
    .select2-container--default {
        &.select2-container--open.select2-container--below .select2-selection--single {
            border-color: $primary;
        }
        .select2-results__option--highlighted[aria-selected] {
            background: $primary;
        }
    }
    .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
        &.active {
            background-color: $primary;
            border-color: $primary;
        }
    }
    .nav-tabs .nav-link {
        &:hover {
            color: $primary;
        }
    }
    .form-check-input {
        &:checked {
          background-color: $primary;
          border-color: $primary;
        }
    }
    .sidebar-contact .toggle-theme {
        background-color: $primary;
        @include respond-below(lg) {
            display: none;
        }
    }
    .header .header-user .user-menu {
        .profile-dropdown {
            .dropdown-item:hover {
                color: $primary;
            }
        }
    }  
    #sales-income,  #revenue-income,  #leads_stage, #s-col, #s-col-stacked {
        .apexcharts-bar-series {
            .apexcharts-series:nth-child(1) path {
                fill: $primary;
            }
        }
    }
    #deals_stage, #emp-department, #s-bar, #mixed-chart  {
        .apexcharts-bar-series {
            .apexcharts-series path {
                fill: $primary;
            }
        }
    }
    #mixed-chart, #s-col-stacked, #s-col {
        .apexcharts-legend-series:first-child .apexcharts-legend-marker {
            fill: $primary; 
        }
    }
    #s-line  {
        .apexcharts-line-series {
            .apexcharts-series path {
                stroke: $primary;
            }
            .apexcharts-marker {
                fill: $primary;
            }
        }
    }
    #chart-bar-stacked, #chart-bar, #chart-bar-rotated {
        .c3-target-data2 .c3-bar {
            fill: $primary !important; 
        }
    }
    #chart-spline-rotated .c3-target-data2 {
        .c3-line {
            stroke: $primary !important;
        } 
        .c3-circle {
            fill: $primary; 
        }
    }  
    .nav.tab-style-1 .nav-link.active {
        background-color: $primary !important;
    }
    .table tbody tr td a:hover {
        color: $primary;
    }
}
[data-color="brightblue"]{
    $primary: #4680FF;
    .sidebar {
        .sidebar-menu > ul {
            &  > li {
                ul {
                    li {
                        a {
                            &.active {
                                color: $primary;
                            }
                        }
                        &.active {                
                            a {
                                span, i {
                                    color: $primary;
                                }
                            }
                        }
                    }
                    & > li {
                        &.active a{
                            .menu-arrow {
                                &::before {
                                    border-right: 2px solid $primary;
                                    border-bottom: 2px solid $primary;
                                }
                            }
                            &.subdrop{
                                color: $primary;
                                span {
                                    color: $primary;
                                    
                                }
                            }
                        }
                    }
                }
            }
            li {
                .submenu > {
                    ul{
                        li{
                            a{
                                &:before {
                                    background: $primary;
                                }
                                &.active {
                                    color: $primary;
                                }
                                &:hover {                                    
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                .submenu.submenu-two {
                    a {
                        &.subdrop, &.active {
                            color: $primary;
                        }
                    }
                }
            }
        }
        .nav-link {
            &.active {
                color: $primary;
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .sidebar-right {
                ul {
                    li {
                        &.submenu {    
                            ul {
                                & > li {
                                    & > a{
                                         &:hover, &.active {
                                             color: $primary;
                                         }
                                     }
                                 }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-primary {
        background-color: $primary;
        border: 1px solid $primary;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($primary, 7%);
            border: 1px solid darken($primary, 7%);
        }
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn.show:hover, .btn:first-child:active, :not(.btn-check)+.btn:active {
        background-color: $primary;
        border-color: $primary;
    }
    .link-primary {
        color: $primary !important;
        &:hover,
        &:focus,
        &:active {
          color: darken($primary, 7%);
        }
    }
    .text-primary {
        color: $primary !important;
    }
    .badge {
        &.badge-primary {
            background: $primary;
        }
    }
    .dataTables_paginate .pagination li.active a.page-link {
        background: $primary;
        border-color: $primary;
    }
    .bg-primary {
        background-color: $primary !important;
        border: 1px solid $primary !important;
    }
    .border-bottom.border-primary {
        border-color: $primary !important;
    }
    .border-primary {
        border-color: $primary !important;
    }
    .nav-tabs.nav-tabs-bottom li a.active, .nav-tabs.nav-tabs-bottom li a:hover {
        border-bottom-color: $primary;
        color: $primary;
    }
    .badge {
        &.badge-soft-primary {
            background: rgba($primary, 0.1);
            color: $primary;

        }
    }
    a {
        &:hover {
            color: $primary;
        }
    }
    .btn-outline-primary {
        border: 1px solid $primary !important;
        color: $primary !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $primary !important;
            border:1px solid $primary !important;
        }
    }
    .select2-container--default {
        &.select2-container--open.select2-container--below .select2-selection--single {
            border-color: $primary;
        }
        .select2-results__option--highlighted[aria-selected] {
            background: $primary;
        }
    }
    .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
        &.active {
            background-color: $primary;
            border-color: $primary;
        }
    }
    .nav-tabs .nav-link {
        &:hover {
            color: $primary;
        }
    }
    .form-check-input {
        &:checked {
          background-color: $primary;
          border-color: $primary;
        }
    }
    .sidebar-contact .toggle-theme {
        background-color: $primary;
    }
    .header .header-user .user-menu {
        .profile-dropdown {
            .dropdown-item:hover {
                color: $primary;
            }
        }
    }
    #sales-income,  #revenue-income,  #leads_stage, #s-col, #s-col-stacked {
        .apexcharts-bar-series {
            .apexcharts-series:nth-child(1) path {
                fill: $primary;
            }
        }
    }
    #deals_stage, #emp-department, #s-bar, #mixed-chart  {
        .apexcharts-bar-series {
            .apexcharts-series path {
                fill: $primary;
            }
        }
    }
    #mixed-chart, #s-col-stacked, #s-col {
        .apexcharts-legend-series:first-child .apexcharts-legend-marker {
            fill: $primary; 
        }
    }
    #s-line  {
        .apexcharts-line-series {
            .apexcharts-series path {
                stroke: $primary;
            }
            .apexcharts-marker {
                fill: $primary;
            }
        }
    }
    #chart-bar-stacked, #chart-bar, #chart-bar-rotated {
        .c3-target-data2 .c3-bar {
            fill: $primary !important; 
        }
    }
    #chart-spline-rotated .c3-target-data2 {
        .c3-line {
            stroke: $primary !important;
        } 
        .c3-circle {
            fill: $primary; 
        }
    }  
    .nav.tab-style-1 .nav-link.active {
        background-color: $primary !important;
    }
    .table tbody tr td a:hover {
        color: $primary;
    }
}
[data-color="lunargreen"]{
    $primary: #365E32;
    .sidebar {
        .sidebar-menu > ul {
            &  > li {
                ul {
                    li {
                        a {
                            &.active {
                                color: $primary;
                            }
                        }
                        &.active {                
                            a {
                                span, i {
                                    color: $primary;
                                }
                            }
                        }
                    }
                    & > li {
                        &.active a{
                            .menu-arrow {
                                &::before {
                                    border-right: 2px solid $primary;
                                    border-bottom: 2px solid $primary;
                                }
                            }
                            &.subdrop{
                                color: $primary;
                                span {
                                    color: $primary;
                                    
                                }
                            }
                        }
                    }
                }
            }
            li {
                .submenu > {
                    ul{
                        li{
                            a{
                                &:before {
                                    background: $primary;
                                }
                                &.active {
                                    color: $primary;
                                }
                                &:hover {                                    
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                .submenu.submenu-two {
                    a {
                        &.subdrop, &.active {
                            color: $primary;
                        }
                    }
                }
            }
        }
        .nav-link {
            &.active {
                color: $primary;
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .sidebar-right {
                ul {
                    li {
                        &.submenu {    
                            ul {
                                & > li {
                                    & > a{
                                         &:hover, &.active {
                                             color: $primary;
                                         }
                                     }
                                 }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-primary {
        background-color: $primary;
        border: 1px solid $primary;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($primary, 7%);
            border: 1px solid darken($primary, 7%);
        }
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn.show:hover, .btn:first-child:active, :not(.btn-check)+.btn:active {
        background-color: $primary;
        border-color: $primary;
    }
    .link-primary {
        color: $primary !important;
        &:hover,
        &:focus,
        &:active {
          color: darken($primary, 7%);
        }
    }
    .text-primary {
        color: $primary !important;
    }
    .badge {
        &.badge-primary {
            background: $primary;
        }
    }
    .dataTables_paginate .pagination li.active a.page-link {
        background: $primary;
        border-color: $primary;
    }
    .bg-primary {
        background-color: $primary !important;
        border: 1px solid $primary !important;
    }
    .border-bottom.border-primary {
        border-color: $primary !important;
    }
    .border-primary {
        border-color: $primary !important;
    }
    .nav-tabs.nav-tabs-bottom li a.active, .nav-tabs.nav-tabs-bottom li a:hover {
        border-bottom-color: $primary;
        color: $primary;
    }
    .badge {
        &.badge-soft-primary {
            background: rgba($primary, 0.1);
            color: $primary;

        }
    }
    a {
        &:hover {
            color: $primary;
        }
    }
    .btn-outline-primary {
        border: 1px solid $primary !important;
        color: $primary !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $primary !important;
            border:1px solid $primary !important;
        }
    }
    .select2-container--default {
        &.select2-container--open.select2-container--below .select2-selection--single {
            border-color: $primary;
        }
        .select2-results__option--highlighted[aria-selected] {
            background: $primary;
        }
    }
    .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
        &.active {
            background-color: $primary;
            border-color: $primary;
        }
    }
    .nav-tabs .nav-link {
        &:hover {
            color: $primary;
        }
    }
    .form-check-input {
        &:checked {
          background-color: $primary;
          border-color: $primary;
        }
    }
    .sidebar-contact .toggle-theme {
        background-color: $primary;
    }
    .header .header-user .user-menu {
        .profile-dropdown {
            .dropdown-item:hover {
                color: $primary;
            }
        }
    }
    #sales-income,  #revenue-income,  #leads_stage, #s-col, #s-col-stacked {
        .apexcharts-bar-series {
            .apexcharts-series:nth-child(1) path {
                fill: $primary;
            }
        }
    }
    #deals_stage, #emp-department, #s-bar, #mixed-chart  {
        .apexcharts-bar-series {
            .apexcharts-series path {
                fill: $primary;
            }
        }
    }
    #mixed-chart, #s-col-stacked, #s-col {
        .apexcharts-legend-series:first-child .apexcharts-legend-marker {
            fill: $primary; 
        }
    }
    #s-line  {
        .apexcharts-line-series {
            .apexcharts-series path {
                stroke: $primary;
            }
            .apexcharts-marker {
                fill: $primary;
            }
        }
    }
    #chart-bar-stacked, #chart-bar, #chart-bar-rotated {
        .c3-target-data2 .c3-bar {
            fill: $primary !important; 
        }
    }
    #chart-spline-rotated .c3-target-data2 {
        .c3-line {
            stroke: $primary !important;
        } 
        .c3-circle {
            fill: $primary; 
        }
    }  
    .nav.tab-style-1 .nav-link.active {
        background-color: $primary !important;
    }
    .table tbody tr td a:hover {
        color: $primary;
    }
}
[data-color="lavendar"]{
    $primary: #673AB7;
    .sidebar {
        .sidebar-menu > ul {
            &  > li {
                ul {
                    li {
                        a {
                            &.active {
                                color: $primary;
                            }
                        }
                        &.active {                
                            a {
                                span, i {
                                    color: $primary;
                                }
                            }
                        }
                    }
                    & > li {
                        &.active a{
                            .menu-arrow {
                                &::before {
                                    border-right: 2px solid $primary;
                                    border-bottom: 2px solid $primary;
                                }
                            }
                            &.subdrop{
                                color: $primary;
                                span {
                                    color: $primary;
                                    
                                }
                            }
                        }
                    }
                }
            }
            li {
                .submenu > {
                    ul{
                        li{
                            a{
                                &:before {
                                    background: $primary;
                                }
                                &.active {
                                    color: $primary;
                                }
                                &:hover {                                    
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                .submenu.submenu-two {
                    a {
                        &.subdrop, &.active {
                            color: $primary;
                        }
                    }
                }
            }
        }
        .nav-link {
            &.active {
                color: $primary;
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .sidebar-right {
                ul {
                    li {
                        &.submenu {    
                            ul {
                                & > li {
                                    & > a{
                                         &:hover, &.active {
                                             color: $primary;
                                         }
                                     }
                                 }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-primary {
        background-color: $primary;
        border: 1px solid $primary;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($primary, 7%);
            border: 1px solid darken($primary, 7%);
        }
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn.show:hover, .btn:first-child:active, :not(.btn-check)+.btn:active {
        background-color: $primary;
        border-color: $primary;
    }
    .link-primary {
        color: $primary !important;
        &:hover,
        &:focus,
        &:active {
          color: darken($primary, 7%);
        }
    }
    .text-primary {
        color: $primary !important;
    }
    .badge {
        &.badge-primary {
            background: $primary;
        }
    }
    .dataTables_paginate .pagination li.active a.page-link {
        background: $primary;
        border-color: $primary;
    }
    .bg-primary {
        background-color: $primary !important;
        border: 1px solid $primary !important;
    }
    .border-bottom.border-primary {
        border-color: $primary !important;
    }
    .border-primary {
        border-color: $primary !important;
    }
    .nav-tabs.nav-tabs-bottom li a.active, .nav-tabs.nav-tabs-bottom li a:hover {
        border-bottom-color: $primary;
        color: $primary;
    }
    .badge {
        &.badge-soft-primary {
            background: rgba($primary, 0.1);
            color: $primary;

        }
    }
    a {
        &:hover {
            color: $primary;
        }
    }
    .btn-outline-primary {
        border: 1px solid $primary !important;
        color: $primary !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $primary !important;
            border:1px solid $primary !important;
        }
    }
    .select2-container--default {
        &.select2-container--open.select2-container--below .select2-selection--single {
            border-color: $primary;
        }
        .select2-results__option--highlighted[aria-selected] {
            background: $primary;
        }
    }
    .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
        &.active {
            background-color: $primary;
            border-color: $primary;
        }
    }
    .nav-tabs .nav-link {
        &:hover {
            color: $primary;
        }
    }
    .form-check-input {
        &:checked {
          background-color: $primary;
          border-color: $primary;
        }
    }
    .sidebar-contact .toggle-theme {
        background-color: $primary;
    }
    .header .header-user .user-menu {
        .profile-dropdown {
            .dropdown-item:hover {
                color: $primary;
            }
        }
    }
    #sales-income,  #revenue-income,  #leads_stage, #s-col, #s-col-stacked {
        .apexcharts-bar-series {
            .apexcharts-series:nth-child(1) path {
                fill: $primary;
            }
        }
    }
    #deals_stage, #emp-department, #s-bar, #mixed-chart  {
        .apexcharts-bar-series {
            .apexcharts-series path {
                fill: $primary;
            }
        }
    }
    #mixed-chart, #s-col-stacked, #s-col {
        .apexcharts-legend-series:first-child .apexcharts-legend-marker {
            fill: $primary; 
        }
    }
    #s-line  {
        .apexcharts-line-series {
            .apexcharts-series path {
                stroke: $primary;
            }
            .apexcharts-marker {
                fill: $primary;
            }
        }
    }
    #chart-bar-stacked, #chart-bar, #chart-bar-rotated {
        .c3-target-data2 .c3-bar {
            fill: $primary !important; 
        }
    }
    #chart-spline-rotated .c3-target-data2 {
        .c3-line {
            stroke: $primary !important;
        } 
        .c3-circle {
            fill: $primary; 
        }
    }  
    .nav.tab-style-1 .nav-link.active {
        background-color: $primary !important;
    }
    .table tbody tr td a:hover {
        color: $primary;
    }
}
[data-color="magenta"]{
    $primary: #E83E8C;
    .sidebar {
        .sidebar-menu > ul {
            &  > li {
                ul {
                    li {
                        a {
                            &.active {
                                color: $primary;
                            }
                        }
                        &.active {                
                            a {
                                span, i {
                                    color: $primary;
                                }
                            }
                        }
                    }
                    & > li {
                        &.active a{
                            .menu-arrow {
                                &::before {
                                    border-right: 2px solid $primary;
                                    border-bottom: 2px solid $primary;
                                }
                            }
                            &.subdrop{
                                color: $primary;
                                span {
                                    color: $primary;
                                    
                                }
                            }
                        }
                    }
                }
            }
            li {
                .submenu > {
                    ul{
                        li{
                            a{
                                &:before {
                                    background: $primary;
                                }
                                &.active {
                                    color: $primary;
                                }
                                &:hover {                                    
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                .submenu.submenu-two {
                    a {
                        &.subdrop, &.active {
                            color: $primary;
                        }
                    }
                }
            }
        }
        .nav-link {
            &.active {
                color: $primary;
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .sidebar-right {
                ul {
                    li {
                        &.submenu {    
                            ul {
                                & > li {
                                    & > a{
                                         &:hover, &.active {
                                             color: $primary;
                                         }
                                     }
                                 }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-primary {
        background-color: $primary;
        border: 1px solid $primary;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($primary, 7%);
            border: 1px solid darken($primary, 7%);
        }
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn.show:hover, .btn:first-child:active, :not(.btn-check)+.btn:active {
        background-color: $primary;
        border-color: $primary;
    }
    .link-primary {
        color: $primary !important;
        &:hover,
        &:focus,
        &:active {
          color: darken($primary, 7%);
        }
    }
    .text-primary {
        color: $primary !important;
    }
    .badge {
        &.badge-primary {
            background: $primary;
        }
    }
    .dataTables_paginate .pagination li.active a.page-link {
        background: $primary;
        border-color: $primary;
    }
    .bg-primary {
        background-color: $primary !important;
        border: 1px solid $primary !important;
    }
    .border-bottom.border-primary {
        border-color: $primary !important;
    }
    .border-primary {
        border-color: $primary !important;
    }
    .nav-tabs.nav-tabs-bottom li a.active, .nav-tabs.nav-tabs-bottom li a:hover {
        border-bottom-color: $primary;
        color: $primary;
    }
    .badge {
        &.badge-soft-primary {
            background: rgba($primary, 0.1);
            color: $primary;

        }
    }
    a {
        &:hover {
            color: $primary;
        }
    }
    .btn-outline-primary {
        border: 1px solid $primary !important;
        color: $primary !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $primary !important;
            border:1px solid $primary !important;
        }
    }
    .select2-container--default {
        &.select2-container--open.select2-container--below .select2-selection--single {
            border-color: $primary;
        }
        .select2-results__option--highlighted[aria-selected] {
            background: $primary;
        }
    }
    .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
        &.active {
            background-color: $primary;
            border-color: $primary;
        }
    }
    .nav-tabs .nav-link {
        &:hover {
            color: $primary;
        }
    }
    .form-check-input {
        &:checked {
          background-color: $primary;
          border-color: $primary;
        }
    }
    .sidebar-contact .toggle-theme {
        background-color: $primary;
    }
    .header .header-user .user-menu {
        .profile-dropdown {
            .dropdown-item:hover {
                color: $primary;
            }
        }
    }
    #sales-income,  #revenue-income,  #leads_stage, #s-col, #s-col-stacked {
        .apexcharts-bar-series {
            .apexcharts-series:nth-child(1) path {
                fill: $primary;
            }
        }
    }
    #deals_stage, #emp-department, #s-bar, #mixed-chart  {
        .apexcharts-bar-series {
            .apexcharts-series path {
                fill: $primary;
            }
        }
    }
    #mixed-chart, #s-col-stacked, #s-col {
        .apexcharts-legend-series:first-child .apexcharts-legend-marker {
            fill: $primary; 
        }
    }
    #s-line  {
        .apexcharts-line-series {
            .apexcharts-series path {
                stroke: $primary;
            }
            .apexcharts-marker {
                fill: $primary;
            }
        }
    }
    #chart-bar-stacked, #chart-bar, #chart-bar-rotated {
        .c3-target-data2 .c3-bar {
            fill: $primary !important; 
        }
    }
    #chart-spline-rotated .c3-target-data2 {
        .c3-line {
            stroke: $primary !important;
        } 
        .c3-circle {
            fill: $primary; 
        }
    }  
    .nav.tab-style-1 .nav-link.active {
        background-color: $primary !important;
    }
    .table tbody tr td a:hover {
        color: $primary;
    }
}
[data-color="chromeyellow"]{
    $primary: #FF9B04;
    .sidebar {
        .sidebar-menu > ul {
            &  > li {
                ul {
                    li {
                        a {
                            &.active {
                                color: $primary;
                            }
                        }
                        &.active {                
                            a {
                                span, i {
                                    color: $primary;
                                }
                            }
                        }
                    }
                    & > li {
                        &.active a{
                            .menu-arrow {
                                &::before {
                                    border-right: 2px solid $primary;
                                    border-bottom: 2px solid $primary;
                                }
                            }
                            &.subdrop{
                                color: $primary;
                                span {
                                    color: $primary;
                                    
                                }
                            }
                        }
                    }
                }
            }
            li {
                .submenu > {
                    ul{
                        li{
                            a{
                                &:before {
                                    background: $primary;
                                }
                                &.active {
                                    color: $primary;
                                }
                                &:hover {                                    
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                .submenu.submenu-two {
                    a {
                        &.subdrop, &.active {
                            color: $primary;
                        }
                    }
                }
            }
        }
        .nav-link {
            &.active {
                color: $primary;
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .sidebar-right {
                ul {
                    li {
                        &.submenu {    
                            ul {
                                & > li {
                                    & > a{
                                         &:hover, &.active {
                                             color: $primary;
                                         }
                                     }
                                 }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-primary {
        background-color: $primary;
        border: 1px solid $primary;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($primary, 7%);
            border: 1px solid darken($primary, 7%);
        }
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn.show:hover, .btn:first-child:active, :not(.btn-check)+.btn:active {
        background-color: $primary;
        border-color: $primary;
    }
    .link-primary {
        color: $primary !important;
        &:hover,
        &:focus,
        &:active {
          color: darken($primary, 7%);
        }
    }
    .text-primary {
        color: $primary !important;
    }
    .badge {
        &.badge-primary {
            background: $primary;
        }
    }
    .dataTables_paginate .pagination li.active a.page-link {
        background: $primary;
        border-color: $primary;
    }
    .bg-primary {
        background-color: $primary !important;
        border: 1px solid $primary !important;
    }
    .border-bottom.border-primary {
        border-color: $primary !important;
    }
    .border-primary {
        border-color: $primary !important;
    }
    .nav-tabs.nav-tabs-bottom li a.active, .nav-tabs.nav-tabs-bottom li a:hover {
        border-bottom-color: $primary;
        color: $primary;
    }
    .badge {
        &.badge-soft-primary {
            background: rgba($primary, 0.1);
            color: $primary;

        }
    }
    a {
        &:hover {
            color: $primary;
        }
    }
    .btn-outline-primary {
        border: 1px solid $primary !important;
        color: $primary !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $primary !important;
            border:1px solid $primary !important;
        }
    }
    .select2-container--default {
        &.select2-container--open.select2-container--below .select2-selection--single {
            border-color: $primary;
        }
        .select2-results__option--highlighted[aria-selected] {
            background: $primary;
        }
    }
    .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
        &.active {
            background-color: $primary;
            border-color: $primary;
        }
    }
    .nav-tabs .nav-link {
        &:hover {
            color: $primary;
        }
    }
    .form-check-input {
        &:checked {
          background-color: $primary;
          border-color: $primary;
        }
    }
    .sidebar-contact .toggle-theme {
        background-color: $primary;
    }
    .header .header-user .user-menu {
        .profile-dropdown {
            .dropdown-item:hover {
                color: $primary;
            }
        }
    }
    #sales-income,  #revenue-income,  #leads_stage, #s-col, #s-col-stacked {
        .apexcharts-bar-series {
            .apexcharts-series:nth-child(1) path {
                fill: $primary;
            }
        }
    }
    #deals_stage, #emp-department, #s-bar, #mixed-chart  {
        .apexcharts-bar-series {
            .apexcharts-series path {
                fill: $primary;
            }
        }
    }
    #mixed-chart, #s-col-stacked, #s-col {
        .apexcharts-legend-series:first-child .apexcharts-legend-marker {
            fill: $primary; 
        }
    }
    #s-line  {
        .apexcharts-line-series {
            .apexcharts-series path {
                stroke: $primary;
            }
            .apexcharts-marker {
                fill: $primary;
            }
        }
    }
    #chart-bar-stacked, #chart-bar, #chart-bar-rotated {
        .c3-target-data2 .c3-bar {
            fill: $primary !important; 
        }
    }
    #chart-spline-rotated .c3-target-data2 {
        .c3-line {
            stroke: $primary !important;
        } 
        .c3-circle {
            fill: $primary; 
        }
    }  
    .nav.tab-style-1 .nav-link.active {
        background-color: $primary !important;
    }
    .table tbody tr td a:hover {
        color: $primary;
    }
}
[data-color="lavared"]{
    $primary: #DC2626;
    .sidebar {
        .sidebar-menu > ul {
            &  > li {
                ul {
                    li {
                        a {
                            &.active {
                                color: $primary;
                            }
                        }
                        &.active {                
                            a {
                                span, i {
                                    color: $primary;
                                }
                            }
                        }
                    }
                    & > li {
                        &.active a{
                            .menu-arrow {
                                &::before {
                                    border-right: 2px solid $primary;
                                    border-bottom: 2px solid $primary;
                                }
                            }
                            &.subdrop{
                                color: $primary;
                                span {
                                    color: $primary;
                                    
                                }
                            }
                        }
                    }
                }
            }
            li {
                .submenu > {
                    ul{
                        li{
                            a{
                                &:before {
                                    background: $primary;
                                }
                                &.active {
                                    color: $primary;
                                }
                                &:hover {                                    
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                .submenu.submenu-two {
                    a {
                        &.subdrop, &.active {
                            color: $primary;
                        }
                    }
                }
            }
        }
        .nav-link {
            &.active {
                color: $primary;
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .sidebar-right {
                ul {
                    li {
                        &.submenu {    
                            ul {
                                & > li {
                                    & > a{
                                         &:hover, &.active {
                                             color: $primary;
                                         }
                                     }
                                 }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-primary {
        background-color: $primary;
        border: 1px solid $primary;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken($primary, 7%);
            border: 1px solid darken($primary, 7%);
        }
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn.show:hover, .btn:first-child:active, :not(.btn-check)+.btn:active {
        background-color: $primary;
        border-color: $primary;
    }
    .link-primary {
        color: $primary !important;
        &:hover,
        &:focus,
        &:active {
          color: darken($primary, 7%);
        }
    }
    .text-primary {
        color: $primary !important;
    }
    .badge {
        &.badge-primary {
            background: $primary;
        }
    }
    .dataTables_paginate .pagination li.active a.page-link {
        background: $primary;
        border-color: $primary;
    }
    .bg-primary {
        background-color: $primary !important;
        border: 1px solid $primary !important;
    }
    .border-bottom.border-primary {
        border-color: $primary !important;
    }
    .border-primary {
        border-color: $primary !important;
    }
    .nav-tabs.nav-tabs-bottom li a.active, .nav-tabs.nav-tabs-bottom li a:hover {
        border-bottom-color: $primary;
        color: $primary;
    }
    .badge {
        &.badge-soft-primary {
            background: rgba($primary, 0.1);
            color: $primary;

        }
    }
    a {
        &:hover {
            color: $primary;
        }
    }
    .btn-outline-primary {
        border: 1px solid $primary !important;
        color: $primary !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $primary !important;
            border:1px solid $primary !important;
        }
    }
    .select2-container--default {
        &.select2-container--open.select2-container--below .select2-selection--single {
            border-color: $primary;
        }
        .select2-results__option--highlighted[aria-selected] {
            background: $primary;
        }
    }
    .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
        &.active {
            background-color: $primary;
            border-color: $primary;
        }
    }
    .nav-tabs .nav-link {
        &:hover {
            color: $primary;
        }
    }
    .form-check-input {
        &:checked {
          background-color: $primary;
          border-color: $primary;
        }
    }
    .sidebar-contact .toggle-theme {
        background-color: $primary;
    }
    .header .header-user .user-menu {
        .profile-dropdown {
            .dropdown-item:hover {
                color: $primary;
            }
        }
    }
    #sales-income,  #revenue-income,  #leads_stage, #s-col, #s-col-stacked {
        .apexcharts-bar-series {
            .apexcharts-series:nth-child(1) path {
                fill: $primary;
            }
        }
    }
    #deals_stage, #emp-department, #s-bar, #mixed-chart  {
        .apexcharts-bar-series {
            .apexcharts-series path {
                fill: $primary;
            }
        }
    }
    #mixed-chart, #s-col-stacked, #s-col {
        .apexcharts-legend-series:first-child .apexcharts-legend-marker {
            fill: $primary; 
        }
    }
    #s-line  {
        .apexcharts-line-series {
            .apexcharts-series path {
                stroke: $primary;
            }
            .apexcharts-marker {
                fill: $primary;
            }
        }
    }
    #chart-bar-stacked, #chart-bar, #chart-bar-rotated {
        .c3-target-data2 .c3-bar {
            fill: $primary !important; 
        }
    }
    #chart-spline-rotated .c3-target-data2 {
        .c3-line {
            stroke: $primary !important;
        } 
        .c3-circle {
            fill: $primary; 
        }
    }  
    .nav.tab-style-1 .nav-link.active {
        background-color: $primary !important;
    }
    .table tbody tr td a:hover {
        color: $primary;
    }
}
[data-color="all"]{
    $primary: var(--primary-rgb-picr);
    .sidebar {
        .sidebar-menu > ul {
            &  > li {
                ul {
                    li {
                        a {
                            &.active {
                                color: rgba($primary, 1);
                            }
                        }
                        &.active {                
                            a {
                                span, i {
                                    color: rgba($primary, 1);
                                }
                            }
                        }
                    }
                    & > li {
                        &.active a{
                            .menu-arrow {
                                &::before {
                                    border-right: 2px solid rgba($primary, 1);
                                    border-bottom: 2px solid rgba($primary, 1);
                                }
                            }
                            &.subdrop{
                                color: rgba($primary, 1);
                                span {
                                    color: rgba($primary, 1);
                                    
                                }
                            }
                        }
                    }
                }
            }
            li {
                .submenu > {
                    ul{
                        li{
                            a{
                                &:before {
                                    background: rgba($primary, 1);
                                }
                                &.active {
                                    color: rgba($primary, 1);
                                }
                                &:hover {                                    
                                    color: rgba($primary, 1);
                                }
                            }
                        }
                    }
                }
                .submenu.submenu-two {
                    a {
                        &.subdrop, &.active {
                            color: rgba($primary, 1);
                        }
                    }
                }
            }
        }
        .nav-link {
            &.active {
                color: rgba($primary, 1);
            }
        }
    }
    .sidebar-twocol {
        &.sidebar {
            .sidebar-right {
                ul {
                    li {
                        &.submenu {    
                            ul {
                                & > li {
                                    & > a{
                                         &:hover, &.active {
                                             color: rgba($primary, 1);
                                         }
                                     }
                                 }
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-primary {
        background-color: rgba($primary, 1);
        border: 1px solid rgba($primary, 1);
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: rgba($primary, 2);
           border: 1px solid rgba($primary, 2);
        }
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn.show:hover, .btn:first-child:active, :not(.btn-check)+.btn:active {
        background-color: rgba($primary, 1);
        border-color: rgba($primary, 1);
    }
    .link-primary {
        color: rgba($primary, 1) !important;
        &:hover,
        &:focus,
        &:active {
          color: rgba($primary, 1);
        }
    }
    .text-primary {
        color: rgba($primary, 1) !important;
    }
    .badge {
        &.badge-primary {
            background: rgba($primary, 1);
        }
    }
    .dataTables_paginate .pagination li.active a.page-link {
        background: rgba($primary, 1);
        border-color: rgba($primary, 1);
    }
    .bg-primary {
        background-color: rgba($primary, 1) !important;
        border: 1px solid rgba($primary, 1) !important;
    }
    .border-bottom.border-primary {
        border-color: rgba($primary, 1) !important;
    }
    .border-primary {
        border-color: rgba($primary, 1) !important;
    }
    .nav-tabs.nav-tabs-bottom li a.active, .nav-tabs.nav-tabs-bottom li a:hover {
        border-bottom-color: rgba($primary, 1);
        color: rgba($primary, 1);
    }
    .badge {
        &.badge-soft-primary {
            background: rgba($primary, 0.1);
            color: rgba($primary, 1);

        }
    }
    a {
        &:hover {
            color: rgba($primary, 1);
        }
    }
    .btn-outline-primary {
        border: 1px solid rgba($primary, 1) !important;
        color: rgba($primary, 1) !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: rgba($primary, 1) !important;
            border:1px solid rgba($primary, 1) !important;
        }
    }
    .select2-container--default {
        &.select2-container--open.select2-container--below .select2-selection--single {
            border-color: rgba($primary, 1);
        }
        .select2-results__option--highlighted[aria-selected] {
            background: rgba($primary, 1);
        }
    }
    .nav-tabs.nav-tabs-solid.nav-tabs-rounded-fill li a {
        &.active {
            background-color: rgba($primary, 1);
            border-color: rgba($primary, 1);
        }
    }
    .nav-tabs .nav-link {
        &:hover {
            color: rgba($primary, 1);
        }
    }
    .form-check-input {
        &:checked {
          background-color: rgba($primary, 1);
          border-color: rgba($primary, 1);
        }
    }
    .sidebar-contact .toggle-theme {
        background-color: rgba($primary, 1);
    }
    .header .header-user .user-menu {
        .profile-dropdown {
            .dropdown-item:hover {
                color: rgba($primary, 1);
            }
        }
    }
    #sales-income,  #revenue-income,  #leads_stage, #s-col, #s-col-stacked {
        .apexcharts-bar-series {
            .apexcharts-series:nth-child(1) path {
                fill: rgba($primary, 1);
            }
        }
    }
    #deals_stage, #emp-department, #s-bar, #mixed-chart  {
        .apexcharts-bar-series {
            .apexcharts-series path {
                fill: rgba($primary, 1);
            }
        }
    }
    #mixed-chart, #s-col-stacked, #s-col {
        .apexcharts-legend-series:first-child .apexcharts-legend-marker {
            fill: rgba($primary, 1);
        }
    }
    #s-line  {
        .apexcharts-line-series {
            .apexcharts-series path {
                stroke: rgba($primary, 1);
            }
            .apexcharts-marker {
                fill: rgba($primary, 1);
            }
        }
    }
    #chart-bar-stacked, #chart-bar, #chart-bar-rotated {
        .c3-target-data2 .c3-bar {
            fill: rgba($primary, 1) !important; 
        }
    }
    #chart-spline-rotated .c3-target-data2 {
        .c3-line {
            stroke: rgba($primary, 1) !important;
        } 
        .c3-circle {
            fill: rgba($primary, 1);
        }
    }  
    .nav.tab-style-1 .nav-link.active {
        background-color: rgba($primary, 1);
    }
    .table tbody tr td a:hover {
        color: rgba($primary, 1);
    }
}
body {
    &.layout-mode-rtl {
        direction: rtl;
        .slide-nav .sidebar {
            margin-right: 0;
        }
        .sidebar {
            right: 0;
            left: unset;
            border-right: 0;
            border-left: 1px solid $gray-200;
            .sidebar-menu {
                > ul > li {
                    .submenu {
                        ul {
                            &::before {
                                right: 14px;
                                left: unset;
                            }                            
                        }
                        > ul {
                            li {
                                a {
                                    padding: 8px 32px 8px 8px;
                                    &::before {
                                        right: 13px;
                                        left: unset;
                                    }   
                                }
                            }
                        }
                    }
                    ul li a span  {
                        margin-right: 10px;
                        margin-left: 0;
                    }
                }
                .menu-arrow {
                    left: 10px;
                    right: unset;
                }
                > ul > li .submenu-hdr::after {
                    margin-right: 8px;
                    margin-left: 0;
                }
            }
            @include respond-below(lg) {
                margin-right: -575px;
            }
        }
        .header-left {
            float: right;
            border-right: 0;
            border-left: 1px solid $gray-200;
        }
        .page-wrapper {
            margin-right: 252px;
            margin-left: 0;
        }
        &.mini-sidebar {
            .page-wrapper {
                margin-right: 80px;
            }
        }
        .header {
            @include respond-above(lg) {
                right: 252px;
                left: 0;
            }
            .input-group {
                input {
                    border-right: 1px solid $gray-200 !important;
                    border-top-right-radius: 5px !important;
                    border-bottom-right-radius: 5px !important;
                    border-left: 0 !important;
                    padding: 0.5rem 1.875rem 0.5rem 0.625rem;
                }
                .input-group-text {
                    border-right: 0;
                    border-radius: 5px !important;
                    border-left: 1px solid $gray-200;
                    &:last-child {
                        padding-left: 10px;
                        padding-right: 0;
                    }
                }
            }
            .mobile_btn {
                @include respond-below(lg) {
                    right: 0;
                    left: unset;
                }
            }
            .mobile-user-menu {
                left: 0;
                right: auto;
                text-align: left;
            }
        }
        .input-icon-addon {
            right: 0;
            left: unset;
        }
        &.mini-sidebar {
            .header {
                right: 72px;
                left: 0;
            }
            &.expand-menu {
                .header {
                    right: 252px;
                    left: 0;
                }
            }
        }
        .input-icon .form-control {
            padding-left: 0;
            padding-right: 30px;
        }
        .nav-list::before {
            left: 0;
            right: unset;
        }
    }
}
[data-loader="enable"]{
    #global-loader {
        opacity: 1;
    }
}
[data-loader="disable"]{
    #global-loader {
       opacity: 0;
       display: none !important;
    }
}
[data-theme="dark"] {
    $text-color: $darkmode-gray-500;    
    $border-color: #1F2228;
    $default-border: #1F2228;
    .bg-white {
        background-color: $darkmode-white;
    }
    body {
        background: $darkmode-light;
    }
    .header {
        background: $darkmode-white;
        border-color: $darkmode-gray-200;        
        .header-left {
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
        .header-user {
            .user-menu {
                .btn-menubar {
                    color: $darkmode-gray-500;
                    &:hover, &:active, &.active {
                        background-color: $darkmode-dark-transparent;
                        border-color: $darkmode-dark-transparent;
                        color: $darkmode-gray-900;
                    }
                }
                .profile-dropdown {
                    .avatar img {
                        border-color: $darkmode-gray-100;
                    }
                }
            }
        }
        .input-group {
            input {
                border-color: $darkmode-gray-200 !important;
            }
        }
        
    }
    .head-icons a{
        background: $darkmode-white;
        border-color: $darkmode-gray-200;
        &:hover{
            background: $primary;
        }
    }
    .sidebar {
        background: $darkmode-white;
        border-color: $darkmode-gray-200;
        .sidebar-logo {
            .logo {
                display: none;
            }
            .dark-logo {
                display: block;
            }
        }
        .sidebar-menu{
            > ul{
                > li{
                    & > a {
                        &:hover {
                            span, i {
                                color: $darkmode-gray-900;
                            }
                        }
                    }
                    > ul {
                        > li {
                            > a {
                                &:hover {
                                    background: $darkmode-dark-transparent;
                                    i, span {
                                        color: $darkmode-gray-900;
                                    }
                                }
                            }
                            &.submenu {
                                > a {
                                    &:hover, &.active, &.subdrop {
                                        background: $darkmode-dark-transparent;
                                        span, i {
                                            color: $darkmode-gray-900;
                                        }
                                        .menu-arrow {
                                            &::before {
                                                border-color: $darkmode-gray-900;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.active{
                        a{
                            color: $white;
                        }
                    }
                    > a{
                        span{
                            color: $darkmode-gray-600;
                        }
                    }
                    ul{
                        li{
                            a {
                                color: $darkmode-gray-900;
                                i{
                                    color: $darkmode-gray-500;
                                }
                                span {
                                    color: $darkmode-gray-900;
                                }
                                &.active{
                                    color: $primary;
                                }
                            }  
                        }
                        & > li {
                            &.active a{
                                background: $darkmode-dark-transparent;
                                color: $white;
                                .menu-arrow {
                                    &::before {
                                        border-right: 2px solid $primary;
                                        border-bottom: 2px solid $primary;
                                    }
                                }
                                &.subdrop{
                                    background: $darkmode-primary-transparent;
                                    color: $primary;
                                    i{
                                        color:$darkmode-white;
                                        background:$darkmode-white;
                                    }
                                    span {
                                        color: $primary;
                                        
                                    }
                                }
                            }
                        }
                    }
                    .submenu {
                        ul {
                            &::before {
                                background: $darkmode-gray-100;
                            }
                        }
                    }
                }
                li {              
                    &.menu-title {
                        color: $darkmode-gray-400;
                    }
                    .submenu > {
                        ul{
                            li{
                                a{
                                    color: $darkmode-gray-500;
                                    &:before {
                                        background: $primary;
                                    }
                                    &.active {
                                        color: $primary;
                                    }
                                    &:hover {                                    
                                        color: $primary;
                                    }
                                }
                            }
                        }
                        
                        a {
                            &.subdrop {
                                background: $darkmode-dark-transparent;
                                i {
                                    color: $darkmode-gray-900;
                                }
                            }
                            &.active {
                                background: $darkmode-dark-transparent;
                                i {
                                    color: $darkmode-gray-900;
                                }
                            }
                        }
                    }
                    .submenu.submenu-two {
                        a {
                            &.subdrop, &.active {
                                background: transparent;
                                color: $primary;
                            }
                        }
                    }
                }
    
            }            
            .menu-arrow {
                &::before {
                    border-right: 2px solid $darkmode-gray-900;
                    border-bottom: 2px solid $darkmode-gray-900;
                }
            }
        }
        .nav-link {
            color: $darkmode-gray-700;
        }
        .btn-menubar { 
            border: 1px solid $darkmode-gray-100;   
            color: $darkmode-gray-500;
            &:hover, &:active, &.active {
                background-color: $darkmode-dark-transparent;
                border-color: $darkmode-dark-transparent;
                color: $darkmode-gray-900;
            }
        }
        .input-group {
            input {
                border-color: $darkmode-gray-200 !important;
            }
        }
    }
    .mini-sidebar {
        .sidebar {
            .sidebar-logo {
                .dark-logo  {
                    display: none;
                }
            }
        }
        &.expand-menu {
            .sidebar {
                .sidebar-logo {
                    .dark-logo  {
                        display: block;
                    }
                    .logo-small, .logo {
                        display: none;
                    }
                }
            }
        }
    }
    .form-control {
        background-color: $darkmode-white;
        border-color: $darkmode-gray-200;
    }
    .input-group-text {
        background-color: $darkmode-white;
        border-color: $darkmode-gray-200;
        color: $darkmode-gray-900;
    }
    .sidebar-stacked {
        .stacked-mini {
            border-right: 1px solid $darkmode-gray-100;
            background-color: $darkmode-light-transparent;
        }
        &.sidebar {
            background-color: transparent;
            .nav-link {
                border: 1px solid $darkmode-gray-100;
                color: $darkmode-gray-900;
                &.active, &:hover {
                    background: $darkmode-light;
                    color: $darkmode-gray-900;
                }
                p {
                    color: $darkmode-gray-900;
                }
            }
            .sidebar-right {
                background: $darkmode-white;
                border-right: 1px solid $darkmode-gray-200;
                .logo {
                    text-align: center;
                }
                .stack-submenu {
                    background: $darkmode-white;
                }
                ul {
                    li {
                        a {
                            color: $darkmode-gray-500;
                            &.subdrop {
                                background: $darkmode-dark-transparent;
                                i {
                                    color: $darkmode-gray-700;
                                }
                            }
                            &.active {
                                background: $darkmode-dark-transparent;
                                i {
                                    color: $darkmode-gray-700;
                                }
                            }
                        }                    
                        &.active{
                            a {
                                background: $darkmode-dark-transparent;
                            }
                        }
                        &.submenu {
                            > a {
                                &:hover {
                                    background: $darkmode-dark-transparent;
                                    color: $darkmode-gray-500 !important;
                                }
                            }
                            ul {
                                & > li {
                                   & > a{
                                        &:hover, &.active {
                                            color: $primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                   & > ul {                
                        & > li {
                            > a {
                                &:hover {
                                    background: $darkmode-dark-transparent;
                                }
                            }
                        }
                    }
    
                }
            }
        }
    }   
    .sidebar-horizontal {
        &.sidebar {
            .sidebar-menu {
                ul {
                    li {
                        &.submenu  {
                            & > a {
                                color: $darkmode-gray-900;
                                &:hover {
                                    background-color: $darkmode-dark-transparent;
                                }
                            }
                            ul {
                                li {
                                    a {
                                        color: $darkmode-gray-500;
                                        &:hover, &.active {
                                            background-color: $darkmode-dark-transparent;
                                        }
                                        .menu-arrow {
                                            &::before {
                                                border-color: $darkmode-gray-500;
                                            }
                                        }
                                    }
                                }
                            }
                        }                    
                    }
                    ul {
                        background-color: $darkmode-white;
                    }
                }
            }     
            .sidebar-menu {
                    ul {
                        li {
                            &.submenu  {
                            & > a {
                                &.active, &.subdrop {
                                    background-color: $darkmode-dark-transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .menu-horizontal {
        .header {
            .header-left {
                .logo {
                    display: none;
                }
                .dark-logo {
                    display: block;
                }
            }
        }
    }
    .sidebar-twocol{
        .twocol-mini {
            border-right: 1px solid $darkmode-gray-200;
            background-color: $darkmode-white;
        }
        &.sidebar {
            background-color: transparent;
            .nav-link {
                color: $darkmode-gray-700;
                &.active, &:hover {
                    background: $darkmode-light;
                    color: $darkmode-gray-700;
                }
                &::before {
                    background: $success;
                }
            }
            .sidebar-right {
                background: $darkmode-white;
                border-right: 1px solid $darkmode-gray-200;
                .sidebar-logo {
                    .dark-logo {
                        display: block;
                    }
                    .logo {
                        display: none;
                    }
                }
                ul {
                    li {
                        &.menu-title {
                            color: $darkmode-gray-400;
                        }
                        a {
                            color: $darkmode-gray-500;
                            &.subdrop {
                                background: $darkmode-dark-transparent;
                                i {
                                    color: $darkmode-gray-700;
                                }
                                &:hover {
                                    color: $darkmode-gray-500 !important;
                                }
                            }
                            &.active {
                                background: $darkmode-dark-transparent;
                                i {
                                    color: $darkmode-gray-700;
                                }
                            }
                        }                    
                        &.active{
                            a {
                                background: $darkmode-dark-transparent;
                            }
                        }
                        &.submenu {                        
                            & > a {
                                &:hover {
                                    background: $darkmode-dark-transparent;
                                    color: $darkmode-gray-500 !important;
                                }
                            }
                            ul {
                                & > li {
                                   & > a{
                                        &:hover, &.active {
                                            color: $primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .tab-pane {
                   & > ul {                
                        & > li {
                            > a {
                                &:hover {
                                    background: $darkmode-dark-transparent;
                                }
                            }
                        }
                    }
    
                }
                .menu-arrow {
                    &:before {
                        content: "\f105";
                    }
                }
            }
        }
    }   
    .sidebar-nav {
        background: $darkmode-light;
    }
    h1, h2, h3, h4, h5, h6 {
        color: $darkmode-gray-900;
    }
    .bg-light {
        background-color: $darkmode-light !important;
        border-color: $darkmode-light !important;
    }
    a {
        color: $darkmode-gray-900;
    }
    kbd {
        background-color: $darkmode-dark-transparent;
        color: $darkmode-gray-500;
    }
    .input-group-flat {
        &:focus-within {      
            .form-control,
            .input-group-text {
                border-color: $darkmode-gray-200 !important;
            }
        }
    }
    .card {
        background-color: $darkmode-white;
        border:1px solid $darkmode-gray-200;    
        .card-title {  
            color: $darkmode-gray-900;   
        }
        .card-header {
            border-color: $darkmode-gray-200;
        }
        .card-footer {
            border-color: $darkmode-gray-200;
        }    
    }
    .alert-light {
        background-color: $darkmode-light;
        color: $text-color;
        border-color: $darkmode-light;
        .alert-link {
          color: $text-color;
        }
        .btn-close {
          color: $text-color;
          &.custom-close {
            @include custom-close($darkmode-light);
          }
        }
    }
    .custom-alert1 {
        background-color: $darkmode-white;
    }
    .alert-primary, .alert-secondary, .alert-warning, .alert-danger  {
        &.custom-alert-icon {
            background-color: $darkmode-white;
            border-color: $darkmode-gray-200;
        }
    }
    &[data-layout="horizontal-overlay"][data-topbarcolor="white"],
    &[data-layout="horizontal-sidemenu"][data-topbarcolor="white"]  {   
        .header {
            left: 0;
            background-color: $darkmode-white;
            border-color: lighten($darkmode-white, 0.9);
            .header-user {
                .user-menu {
                    .btn-menubar {
                        color: $darkmode-gray-500;
                        &:hover {
                            background: $darkmode-dark-transparent;
                            color: $darkmode-gray-500;
                        }
                    }
                }
            }
            .header-left {
                display: block;
                .logo {
                    display: none;
                }
                .dark-logo {
                    display: block;
                }
            }
        }      
        .mode-toggle {
            color: $darkmode-gray-500;
        }
        .sidebar-horizontal {
            &.sidebar {
                background-color: $darkmode-white;
                border-bottom: 1px solid lighten($darkmode-white, 0.9);
                .sidebar-menu {
                    .nav-menu {
                        > li {     
                            &.submenu {
                                & > a {
                                    color: $darkmode-gray-900;
                                    &.active, &:hover,  &.subdrop {
                                        background-color: $darkmode-dark-transparent;
                                        color: $darkmode-gray-900;
                                        .menu-arrow {
                                            &::before {
                                                border-color: $darkmode-gray-900;
                                            }
                                        }
                                    }
                                    .menu-arrow {
                                        &::before {
                                            border-color: $darkmode-gray-900;;
                                        }
                                    }
                                }
                            }
                        }
                        .menu-arrow {
                            &::before {
                                border-color: $darkmode-gray-900;
                            }
                        }
                    }
                }
            }
        }
        .main-wrapper {
            &::before {
                content: "";
                background: $darkmode-white;
            }
        }
    }
    .page-wrapper {
        &::before {
            background: $darkmode-white;
        }
    }
    .accordion-button {
        background-color: $darkmode-white;
        color: $text-color;
        &:not(.collapsed) {
        }
        &:after {
            content: "\f078";
            background-color: $darkmode-white;
            color: $text-color;
        }
    }  
    .accordion-body {
        color: $text-muted;
    }
    .accordion-item {
        color: $text-color;
        background-color: $darkmode-white;
        border-color: $darkmode-light-900;
    }
    .accordion {
        &.accordion-primary, &.accordion-secondary {
            .accordion-button.collapsed {
                background-color: $darkmode-white;
                color: $text-color;
                &::after {
                    background-color: $darkmode-white;
                    color: $text-color;
                }
            }
        }
        &.accordion-solid-primary, &.accordion-solid-secondary,
        &.accordion-solid-warning, &.accordion-solid-info, &.accordion-solid-success, &.accordion-solid-danger {
            .accordion-button {
                color: $darkmode-white;;
                &.collapsed {
                    background-color: $darkmode-white;
                    color: $text-color;
                    &::after {
                        background-color: $darkmode-white;
                        color: $text-color;
                    }
                }
            }   
        }
        &.accordion-border-primary, &.accordion-border-secondary,
        &.accordion-border-warning, &.accordion-border-info, &.accordion-border-success, &.accordion-border-danger {
            .accordion-button {
                background-color: $darkmode-white;
                &::after {
                    background-color: $darkmode-white;
                }
            }
        }
    }
    .bg-outline-primary, .bg-outline-secondary, .bg-outline-success,
    .bg-outline-danger, .bg-outline-warning, .bg-outline-info, .bg-outline-light, .bg-outline-light {
        background-color: $darkmode-white;
    }    
    .bg-outline-light {
        border: 1px solid $darkmode-light !important;
        color: $darkmode-gray-900 !important;
    }
    .border-container {
        background-color: $darkmode-light;
    }
    .border {
        border-color: $border-color !important;
        &.border-primary {
            border: 1px solid $primary !important;
        }
        &.border-secondary {
            border: 1px solid $secondary !important;
        }
        &.border-success {
            border: 1px solid $success !important;
        }
        &.border-warning {
            border: 1px solid $warning !important;
        }
        &.border-danger {
            border: 1px solid $danger !important;
        }
        &.border-info {
            border: 1px solid $info !important;
        }
        &.border-light {
            border: 1px solid $darkmode-light !important;
        }
        &.border-white {
            border: 1px solid $darkmode-white !important;
        }
    }
    .border-top {
        border-top: 1px solid $border-color !important;
    }
    .border-end {   
        border-inline-end: 1px solid $border-color !important;
    }
    .border-bottom {
        border-bottom: 1px solid $border-color !important;
    }
    .border-start {
        border-inline-start: 1px solid $border-color !important;
    }
    .list-group-item {
        background-color: $darkmode-white;
        border-color: $darkmode-light-900 !important;
    }
    .toast {
        background-color: $darkmode-white;
    }
    .avatar.online:before, .avatar.offline:before {
        border-color: $darkmode-white !important;
    }
    .swal2-popup {
        background-color: $darkmode-white;
    }
    .progress, .progress-stacked {
        background-color: $darkmode-light-900;
    }
    .popover {
        background-color: $darkmode-white;
        border: 1px solid $default-border;
        box-shadow: none;
        .popover-header {
          background-color: $darkmode-white;
          border-block-end: 1px solid $default-border;
        }
        .popover-body {
          color: $text-color;
        }
      }
      .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
      .bs-popover-top > .popover-arrow::after,
      .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
      .bs-popover-top > .popover-arrow::before {
        border-top-color: $darkmode-light;
      }
      .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
      .bs-popover-end > .popover-arrow::after,
      .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
      .bs-popover-end > .popover-arrow::before {
        border-right-color: $darkmode-light;
      }
      .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
      .bs-popover-start > .popover-arrow::after,
      .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
      .bs-popover-start > .popover-arrow::before {
        border-left-color: $darkmode-light;
      }
      .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
      .bs-popover-bottom > .popover-arrow::after,
      .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
      .bs-popover-bottom > .popover-arrow::before,
      .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
      .bs-popover-bottom .popover-header::before {
        border-bottom-color: $darkmode-light;
    }
    .page-link {
        color: $text-color;
        background-color: $darkmode-white;
        border: 1px solid $border-color;
        &:focus {
            background-color: $darkmode-light;
        }
        &:hover {
            background-color: $darkmode-light;
            border-color: $border-color;
        }
    }
    .pagination-style-2 .pagination .page-item.active .page-link {
        background-color: $darkmode-light;
    }
    .pagination-style-1 .pagination .page-item:hover .page-link {
        background-color: $darkmode-light;
    }
    .pagination-style-3 .pagination .page-item:hover .page-link {
        background-color: $darkmode-light;
    }
    .offcanvas {
        background-color: $darkmode-white;
        color: $text-color;
    }
    .modal-content {
        background-color: $darkmode-white;
        border-color: $darkmode-gray-200;
        .modal-header {
            border-color: $darkmode-gray-200;
        }
        .modal-footer {
            border-color: $darkmode-gray-200;
        }
    }
    .figure-caption {
        color: $text-color;
    }
    .text-dark {
        color: $darkmode-dark !important;
    }
    .nav-tabs li a {
        color: $darkmode-gray-900;
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        background-color: $darkmode-white;
        color:  $darkmode-gray-900;
    }
    .nav-tabs .nav-link.active {
        border-color: $darkmode-gray-200;
    }
    .nav-tabs.nav-tabs-solid {
        background-color: $darkmode-white;
    }
    .nav.tab-style-1 {
        background-color: $darkmode-light;
    }
    .vertical-tabs-2 .nav-item .nav-link {
        background-color: $darkmode-light;
        border-color: $darkmode-gray-200;
        &.active {
            background-color: $primary;
        }
    }
    .tab-style-2 {
        .nav-item .nav-link {            
            i {
                background-color: $darkmode-light;
                color: $darkmode-gray-500;
            }
        }
    }
    .nav-tabs.nav-tabs-top {
        li{
            a{
                &.active{
                    border-top-color: $primary;      
                }
            }
        }
    }
    .tab-style-3 {
        .nav-item {
            .nav-link {
                &::after {
                    background-color: $darkmode-white;
                    border-color: $darkmode-gray-200;
                }
                &:before { 
                    background-color: $darkmode-light;
                }
                &.active{
                    &:before {
                        background-color: $primary;
                      }
                      &:after {
                        border-color: $primary;
                        color: $primary;
                      }
                }
            }
        }
    }
    .tab-style-6 {
        background-color: $darkmode-white;
    }
    .bg-white {
        background-color: $darkmode-white !important;
    }
    .card.card-bg-primary .card-body, .text-fixed-white {
        color: $darkmode-white;
    }
    .btn {
        &.btn-light {
            background-color: $darkmode-light-900;
            border: 1px solid $darkmode-light-900;
            color: $darkmode-gray-500;
            &:hover,
            &:focus,
            &.focus,
            &:active,
            &.active {
                background-color: darken($darkmode-light-900, 3%);
                border: 1px solid $darkmode-light-900;
                color: $darkmode-gray-500;
            }
        }
    }
    .scroll-demo {
        border-color: $darkmode-gray-200;
    }
    .timeline  {
        &::before {
            background-color: $darkmode-gray-200;
        }
        & > li {
            & > .timeline-panel {
                border-color: $darkmode-gray-200;
                &::after, &::before { 
                    border-color: $darkmode-gray-200;
                }
            }
        }
    }
    .callout {
        background-color: $darkmode-light;
    }
    .icons-list {
        li {
            border-color: $darkmode-gray-200;
            box-shadow: none;
        }
    }
    .btn-outline-light {
        border: 1px solid $darkmode-light !important;
        color: $darkmode-gray-700 !important;
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $darkmode-light !important;
            border: 1px solid $darkmode-light !important;
            color: $darkmode-gray-700 !important;
        }
    }
    .bg-dark {
        background-color: $darkmode-dark !important;
        border-color: $darkmode-dark !important;
        color: $darkmode-white;
    }
    .twitter-bs-wizard .twitter-bs-wizard-nav {
        .nav-link::after {
            background-color: $darkmode-gray-200;
        }
    }
    .form-check-input {
        background-color: $darkmode-white;
        border-color: $darkmode-gray-200;
    }
    .alert-outline-primary, .alert-outline-secondary, .alert-outline-warning, .alert-outline-danger,
    .alert-outline-info, .alert-outline-dark, .alert-outline-success, .alert-outline-light {
        background-color: $darkmode-white;
    }
    .text-white {
        color: $darkmode-white !important;
    }
    .text-light {
        color: $darkmode-light !important;
      }
    .table {
        thead {
            tr {
                border-color: $darkmode-gray-200;
                    th {
                        border-color: $darkmode-gray-200;
                        background-color: $darkmode-gray-200;
                        color: $darkmode-gray-900;
                    }
            }
        }
        tbody {
            tr {
                border-color: $darkmode-gray-200;
                th {
                    border-color: $darkmode-gray-200;
                        background-color: $darkmode-gray-200;
                        color: $darkmode-gray-900;
                }
                td {
                    background-color: $darkmode-white;
                    color: $darkmode-gray-500;
                    border-color: $darkmode-gray-200;
                }
            }
        }
        .thead-primary th {
            background-color: $primary;
        }
        .thead-secondary th {
            background-color: $secondary;
        }
        .thead-info th {
            background-color: $info;
        }
        .thead-warning th {
            background-color: $warning;
        }
        .thead-danger th {
            background-color: $danger;
        }
        .thead-success th {
            background-color: $success;
        }
        .thead-dark th {
            background-color: $darkmode-dark;
        }
        .thead-light th {
            background-color: $darkmode-light-600;
        }
    }
    table.table.dataTable {
        & > tbody {
            > tr {
                border-color: $darkmode-gray-200;
                td {
                    color: $darkmode-gray-500;                
                    border-color: $darkmode-gray-200;
                }
            } 
        }
        & > thead > tr {
            border-color: $darkmode-gray-200;
                th {
                    color: $darkmode-gray-900;
                }
        }
    }
    .dataTables_length {
        label {
            color: $darkmode-gray-700;    
            .form-select {
                border: 1px solid $darkmode-gray-200;
                color: $darkmode-gray-900;
            }
        }
    }
    .form-label {
        color: $darkmode-gray-900;
    }
    .form-control {
        color: $darkmode-gray-900;
    }
    .form-select {
        background-color: $darkmode-white;
        color: $darkmode-gray-900;
        border-color: $darkmode-gray-200;
        option {
            background-color: $darkmode-white;
        }
    }
    .select2-container--default {
        .select2-selection--single {
            border-color: $darkmode-gray-200;
            background: $darkmode-white;
            .select2-selection__rendered {
                color: $darkmode-gray-900 !important;
            }
            .select2-selection__arrow {
                b {
                    border-color: $darkmode-gray-900;
                }
            }
        }
        .select2-results__option--highlighted[aria-selected] {
            color: $darkmode-white;
        }
    }
    .select2-container--focus  {
        .select2-selection--single{
            background: $darkmode-white !important;
            border-color: $darkmode-gray-200;
        }
    }
    .select2-container--classic .select2-selection--single,
    .select2-container--default .select2-selection--single,
    .select2-container--default .select2-selection--single .select2-selection__rendered,
    .select2-container--default .select2-selection--single .select2-selection__arrow,
    .select2-container--default .select2-selection--multiple,
    .select2-container--classic .select2-selection--single .select2-selection__arrow,
    .select2-container--classic .select2-selection--single .select2-selection__rendered {
        border-color: $darkmode-gray-200;
        color: $darkmode-gray-500;
    }
    .select2-dropdown {
        border-color: $darkmode-gray-200;
        background: $darkmode-white;
    }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice,
    .select2-container--default .select2-selection--multiple .select2-selection__choice,
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        color: $darkmode-white;
    }
    .form-floating > .form-control-plaintext ~ label::after, 
    .form-floating > .form-control:focus ~ label::after, 
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after, 
    .form-floating > .form-select ~ label::after {
        background-color: $darkmode-white;
    }
    .select2-container--default .select2-selection--multiple {
        background-color: $darkmode-white;
    }
    .custom-file-container {
        .custom-file-container__custom-file__custom-file-control {
            background-color: $darkmode-gray-100;
            border-color: $darkmode-gray-100;
        }
    }
    .dropdown-menu {
        background-color: $darkmode-white;
        border-color: $darkmode-gray-200;
        color: $darkmode-gray-900;
        .dropdown-item {
            color: $darkmode-gray-900;
            &:not(.active) {
                &:hover,
                &:focus,
                &:active {
                  color: $darkmode-gray-900;
                  background-color: $darkmode-light-500;
                }
              }
              &.active {
                background-color: $darkmode-light-500;
              }
        }
    }
    .dropdown-divider {
        border-color: $darkmode-gray-200;
    }
    &[data-sidebar="light"] [data-sidebarbg="sidebarbg1"],
    &[data-sidebar="light"] [data-sidebarbg="sidebarbg2"],
    &[data-sidebar="light"] [data-sidebarbg="sidebarbg3"],
    &[data-sidebar="light"] [data-sidebarbg="sidebarbg4"],
    &[data-sidebar="light"] [data-sidebarbg="sidebarbg5"],
    &[data-sidebar="light"] [data-sidebarbg="sidebarbg6"] {
        #sidebar {
            &::before  {
                background: rgba($darkmode-white, 0.85);
            }
        }
    }
    .theme-width {
        input[type="radio"]{
            &:checked{
                ~ label{
                    border-color: $primary;
                    &::before {
                        border-color: $primary;
                    }
                }
            }
        }
        label {
            border-color: $darkmode-gray-200;
            color: $darkmode-gray-500;
            &::before {
                border-color: $darkmode-gray-200;
                background: $darkmode-white;
            }
            &::after {
                background: $primary;
            }
        }
    }
    .theme-sidebarcolor {
        input[type="radio"]{
            &:checked{
                ~ label{                
                    border-color: $primary !important;
                    &:after{
                        color: $primary;
                    }
                    &.bg-dark {
                        &:after{
                            color: $darkmode-white;
                        }
                    }
                }
            }
        }
        label {        
            border-color: $darkmode-gray-200;
        }
    }
    .theme-sidebarbg {
        input[type="radio"]{
            &:checked{
                ~ label{
                    &:after{
                        color: $primary;
                        background: $white;
                        border-color: $darkmode-gray-200;
                    }      
                }
            }
        }
        label { 
            img { 
                border-color: $darkmode-gray-200;
            }
        }
    }
    .theme-colorselect {
        input[type="radio"]{
            &:checked{
                ~ label{
                    outline-color: $primary;
                    &:after{
                        color: $primary;
                        background: $white;
                        border-color: $darkmode-gray-200;
                    }
                    &.primary-topbar,   &.dark-topbar {
                        &:after{
                            color: $darkmode-white;
                        }
                    }
                }
            }
        }
        label {
            outline-color: $darkmode-gray-200;
            &.white-topbar {
                background: $darkmode-white;
                border-color: $darkmode-gray-200;
            }
        }
        .pickr-container-background, .pickr-topbar, .pickr-topbarcolor { 
            outline-color: $darkmode-gray-200;
        }
    }
    .theme-colorsset{
        label{
            border-color: $darkmode-gray-200;
            &.primary-clr{
                &::before {
                    background: $primary;
                }
            }
            &.brightblue-clr{
                &::before {
                    background: $brightblue;
                }
            }
            &.lunargreen-clr{
                &::before {
                    background: $lunargreen;
                }
            }
            &.lavendar-clr{
                &::before {
                    background: $lavendar;
                }
            }
            &.magenta-clr{
                &::before {
                    background: $magenta;
                }
            }
            &.chromeyellow-clr{
                &::before {
                    background: $chromeyellow;
                }
            }
            &.lavared-clr{
                &::before {
                    background: $lavared;
                }
            }
        }
        input[type="radio"]{
            &:checked{
                ~ label{
                    &:after{
                        color: $darkmode-white;
                    }
                }
            }
        }
    }    
    .theme-mode {
        input[type="radio"]{
            &:checked{
                ~ label{
                    border-color: $primary;
                    .avatar {
                        color: $warning;
                        background-color: $warning-transparent;
                    }
                }
            }
        }
        label {
            border-color: $darkmode-gray-200;
            color: $darkmode-gray-700;
            .avatar {
                background-color: $darkmode-dark-transparent;
                color: $darkmode-dark;
            }
        }        
        .pickr-container-primary {
            border-color: $darkmode-gray-200;
        }
    }
    .theme-layout {
        &.active {
            img {
                border: 1px solid $primary;
            }
        }
        input[type="radio"]{
            &:checked{
                ~ label{
                    .layout-img {
                        img {
                            border-color: $primary;
                        }
                    }
                }
            }
        }
        label {
            .layout-img {
                img {
                    border-color: $darkmode-gray-200;
                }
            }            
            .layout-type { 
                color: $darkmode-gray-900;
            }
        }
    }
    &[data-layout=modern][data-topbarcolor=white] {
        .header {
            background-color: $darkmode-white;
            border-color: rgba($darkmode-white, 0.8);
            .bar-icon {
                span {
                    background-color: $darkmode-white;
                }
            }
            .header-user {
                .user-menu {
                    .btn-menubar {
                        color: $darkmode-gray-500;
                        &:hover, &:active, &.active {
                            background-color: $darkmode-white;
                            color: $darkmode-gray-500 !important;
                        }
                    }
                }
            }
            .header-left {
                .logo {
                    display: none;
                }
                .dark-logo {
                    display: block;
                }
            }
        }
        .page-wrapper {
            &::before {
                background: $darkmode-white;
            }
        }   
    }
    &[data-layout="modern"][data-topbarcolor="firefly"],
    &[data-layout="modern"][data-topbarcolor="bluegem"],
    &[data-layout="modern"][data-topbarcolor="blackpearl"],
    &[data-layout="modern"][data-topbarcolor="maroon"],
    &[data-layout="modern"][data-topbarcolor="primary"]     {
        .header {
            .bar-icon {
                span {
                    background-color: $darkmode-white;
                }
            }
            .header-user {
                .user-menu {
                    .btn-menubar {
                        color: $darkmode-gray-500;
                        &:hover, &:active, &.active {
                            background-color: $darkmode-white;
                            color: $darkmode-gray-500 !important;
                        }
                    }
                }
            }
        }
    }
    .policy-upload {
        border-color: $border-color;
    }
    .bootstrap-tagsinput {
        background-color: $darkmode-white;
        border-color: $border-color;
    }
    .note-toolbar, .note-dropdown-menu, .note-modal-content, .note-input {
        background-color: $darkmode-light;
        border-color: $border-color;
        color: $darkmode-gray-900;
    }
    .note-btn {
        background-color: $darkmode-white;
        border-color: $border-color;
        color: $darkmode-gray-900;
    }
    .note-modal-header, .file-drop {
        border-color: $border-color;
    }
    .close {
        color: $darkmode-gray-900;
    }
    .video-wrap {
        .video-content {
            background-color: $darkmode-white;
        }
    }
    .bg-black {
        .text-white {
            color: $darkmode-gray-900 !important;
        }
    }
    .bg-transparent-light {
        background: $darkmode-light !important;
    }
    .contact-grids-tab .nav-underline {
        border-color: $border-color;
        .nav-item .nav-link {
            &.active, &:hover {
                background-color: $darkmode-white;
            }
        }
    }
    .bg-primary-100 {
        a {
            color: $darkmode-white;
            &:hover {
                color: $primary;
            }
        }
    }
    .notification-dropdown {
        .dropdown {
            .dropdown-toggle {
                color: $darkmode-gray-900;
                &.show {
                    color: $darkmode-gray-900;
                }
            }
        }
    }
    .bootstrap-datetimepicker-widget {
        table {
            th {
                color: $darkmode-gray-900;
            }
        }
    }
    .crm-link {
        color: $darkmode-gray-900;
        background: $darkmode-light;
        border: $border-color;

    }
}

[data-theme="dark"] {
    .breadcrumb .breadcrumb-item.active {
        color: $darkmode-gray-800;
    }
    .table tbody tr td a {
        color: $darkmode-gray-800;
    }
    .btn.btn-icon.active.bg-white {
        border-color: $darkmode-gray-200;
        color: $darkmode-gray-800;
    }
    .custom-datatable-filter {
        background: $darkmode-white;
    }
    .btn.btn-white {
        background: $darkmode-white;
        border-color: $darkmode-gray-200;
        color: $darkmode-gray-800;
    }
    .todo-item .form-check-label {
        color: $darkmode-gray-800;
    }
    .bg-transparent-secondary,.bg-transparent-dark,.bg-light-500 {
        background: $darkmode-gray-700 !important;
    }
    .text-gray-9, .text-title {
        color: $darkmode-gray-800 !important;
    }
    .attendance-bg {
        background: $darkmode-white;
        border-color: $darkmode-gray-200 !important;
    }
    .chat-wrapper .sidebar-group {
        background: $darkmode-gray-200;
    }
    .chat-wrapper .chat-users-wrap .chat-user-list,.chat-wrapper .chat .chat-header,.chat-wrapper .chat {
        background: $darkmode-white;
        border-color: $darkmode-gray-200;
    }
    .chat-wrapper .search-wrap .input-group-text {
        background: $darkmode-white;
        border-color: $darkmode-gray-200
    }
    .chat-wrapper .search-wrap .form-control {
        border-color: $darkmode-gray-200
    }
    .chat-wrapper .chat .chat-footer .footer-form {
        background: $darkmode-white;
        border-color: $darkmode-gray-200;
    }
    .chat-wrapper .chat .chat-footer .footer-form .chat-footer-wrap {
        background: $darkmode-gray-200;
    }
    .chat-wrapper .chat .chat-body .messages .chats .chat-content .message-content {
        background: $darkmode-gray-200;
    }
    .chat-wrapper .chat .chat-header .chat-options > ul > li > a {
        background: $darkmode-gray-200;
    }
    #calendar {
        .fc-scroller-harness th {
            background: $darkmode-white;
            border-color: $darkmode-gray-200;
        }
        .fc-col-header-cell-cushion,.fc-daygrid-day-number {
            color: $darkmode-gray-800;
        }
        .fc-daygrid-body tbody td {
            background: $darkmode-white;
            border-color: $darkmode-gray-200;
        }
        .fc-daygrid .fc-scrollgrid {
            border-color: $darkmode-gray-200;
        }
    }
}