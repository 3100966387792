.bg-primary {
    background-color: $primary !important;
}
.bg-secondary {
    background-color: $secondary !important;
}
.bg-info {
    background-color: $info !important;
}
.bg-success {
    background-color: $success !important;
}
.bg-warning {
    background-color: $warning !important;
}
.bg-danger {
    background-color: $danger !important;
}
.bg-dark {
    background-color: $dark !important;
}
.bg-light {
    background-color: $light !important;
}
.bg-white {
    background-color: $white;
}
.bg-pink {
    background-color: $pink !important;
}
.bg-purple {
    background-color: $purple !important;
}
.bg-darkgreen {
    background-color: $dark-green !important;
}
.bg-nightblue {
    background-color: $night-blue !important;
}
.bg-royalblue {
    background-color: $royal-blue !important;
}
.bg-darkgray {
    background-color: $dark-gray !important;
}
.bg-indigo {
    background-color: $indigo !important;
}

.bg-gray {
    background: $light-500;
}
.bg-primary {
    background-color: $primary !important;
    &.bg-opacity-10 {
        background-color: rgba($primary,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($primary,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($primary,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($primary,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($primary,1) !important;
    }
}
.bg-secondary {
    background-color: $secondary !important;
    &.bg-opacity-10 {
        background-color: rgba($secondary,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($secondary,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($secondary,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($secondary,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($secondary,1) !important;
    }
}
.bg-info {
    background-color: $info !important;
    &.bg-opacity-10 {
        background-color: rgba($info,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($info,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($info,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($info,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($info,1) !important;
    }
}
.bg-success {
    background-color: $success !important;
    &.bg-opacity-10 {
        background-color: rgba($success,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($success,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($success,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($success,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($success,1) !important;
    }
}
.bg-warning {
    background-color: $warning !important;
    &.bg-opacity-10 {
        background-color: rgba($warning,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($warning,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($warning,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($warning,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($warning,1) !important;
    }
}
.bg-danger {
    background-color: $danger !important;
    &.bg-opacity-10 {
        background-color: rgba($danger,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($danger,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($danger,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($danger,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($danger,1) !important;
    }
}
.bg-dark {
    background-color: $dark !important;
    &.bg-opacity-10 {
        background-color: rgba($dark,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($dark,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($dark,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($dark,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($dark,1) !important;
    }
}
.bg-light {
    background-color: $light !important;
    &.bg-opacity-10 {
        background-color: rgba($light,0.1) !important;
    }
    &.bg-opacity-25 {
        background-color: rgba($light,0.25) !important;
    }
    &.bg-opacity-50 {
        background-color: rgba($light,0.50) !important;
    }
    &.bg-opacity-75 {
        background-color: rgba($light,0.75) !important;
    }
    &.bg-opacity-100 {
        background-color: rgba($light,1) !important;
    }
}
.bg-skyblue {
    background-color: $skyblue !important;
}
@each $color,
$value in $theme-colors {
    .bg-#{$color} {
        background-color: #{$value} !important;
        border: 1px solid #{$value} !important;
        color: $white;
    }
}
.bg-soft-primary {
    background-color: $primary-100;
    color: $primary;
}
.bg-soft-secondary {
    background-color: $secondary-300;
    color: $secondary;
}
.bg-soft-success {
    background-color: $success-300;
    color: $success;
}
.bg-soft-danger {
    background-color: $danger-300;
    color: $danger;
}
.bg-soft-warning {
    background-color: $warning-300;
    color: $warning;
}
.bg-soft-info {
    background-color: rgba($info, 0.3);
    color: $info;
}
.bg-soft-light {
    background-color: rgba($light, 0.9);
    color: $text-color;
}
.bg-soft-dark {
    background-color: rgba($dark, 0.3);
    color: $white;
}
.bg-light {
  color: $text-color;
}

.bg-primary-gradient {
  background: $primary-gradient !important;
  color: $white;
}
.bg-secondary-gradient {
  background: $secondary-gradient !important;
  color: $white;
}
.bg-warning-gradient {
  background: $warning-gradient !important;
  color: $white;
}
.bg-info-gradient {
  background: $info-gradient !important;
  color: $white;
}
.bg-success-gradient {
  background: $success-gradient !important;
  color: $white;
}
.bg-danger-gradient {
  background: $danger-gradient !important;
  color: $white;
}
.bg-purple-gradient {
  background: $purple-gradient !important;
  color: $white;
}
.bg-light-gradient {
  background: $light-gradient !important;
  color: $black;
}
.bg-dark-gradient {
  background: $dark-gradient !important;
  color: $white;
}
.bg-outline-primary {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
}
.bg-outline-secondary {
  background-color: $white;
  border: 1px solid $secondary;
  color: $secondary;
}
.bg-outline-warning {
  background-color: $white;
  border: 1px solid $warning;
  color: $warning;
}
.bg-outline-info {
  background-color: $white;
  border: 1px solid $info;
  color: $info;
}
.bg-outline-success {
  background-color: $white;
  border: 1px solid $success;
  color: $success;
}
.bg-outline-danger {
  background-color: $white;
  border: 1px solid $danger;
  color: $danger;
}
.bg-outline-dark {
  background-color: $white;
  border: 1px solid $dark;
  color: $dark;
}
.bg-outline-light {
  background-color: $white;
  border: 1px solid $light !important;
  color: $black !important;
}
.bg-primary-transparent {
    background-color: $primary-transparent !important;
    color: $primary !important;
    &:hover {
        background-color: $primary-transparent !important;
        color: $primary !important;
    }
}
.bg-secondary-transparent {
    background-color: $secondary-transparent !important;
    color: $secondary !important;
    &:hover {
        background-color: $secondary-transparent !important;
        color: $secondary !important;
    }
}
.bg-info-transparent {
    background-color: $info-100 !important;
    color: $info !important;
    &:hover {
        background-color: $info-100 !important;
        color: $info !important;
    }
}
.bg-success-transparent {
    background-color: $success-100 !important;
    color: $success !important;
    &:hover {
        background-color: $success-100 !important;
        color: $success !important;
    }
}
.bg-warning-transparent {
    background-color: $warning-100 !important;
    color: $warning !important;
    &:hover {
        background-color: $warning-100 !important;
        color: $warning !important;
    }
}
.bg-danger-transparent {
    background-color: $danger-100 !important;
    color: $danger !important;
    &:hover {
        background-color: $danger-100 !important;
        color: $danger !important;
    }
}
.bg-light-transparent {
    background-color: rgba($light, 0.5) !important;
    color: $light !important;
    &:hover {
        background-color: rgba($light, 0.5) !important;
        color: $text-color !important;
    }
}
.bg-skyblue-transparent {
    background-color: $skyblue-100 !important;
    color: $skyblue !important;
    &:hover {
        background-color: $skyblue-100 !important;
        color: $skyblue !important;
    }
}
.bg-pink-transparent {
    background-color: $pink-100 !important;
    color: $pink !important;
    &:hover {
        background-color: $pink-100 !important;
        color: $pink !important;
    }
}
.bg-lightdanger-gradient {
    background: linear-gradient(180deg, #FFFFFF 0%, #FFEEEE 100%);
}
.bg-lightpink-gradient {
    background: linear-gradient(180deg, #FFFFFF 0%, #FFEBF4 100%);
}
.bg-lightsuccess-gradient {
    background: linear-gradient(180deg, #FFFFFF 0%, #E8FFF2 100%);
}
.bg-lightpurple-gradient {
    background: linear-gradient(180deg, #FFFFFF 0%, #FCE8FF 100%);
}
.bg-primary-100 {
    background-color: $primary-100 !important; 
}
.bg-gray-100 {
    background-color: $gray-100; 
}
.bg-gray-200 {
    background-color: $gray-200;
}
.bg-gray-300 {
    background-color: $gray-300;
}
.bg-gray-400 {
    background-color: $gray-400;
}
.bg-gray-500 {
    background-color: $gray-500;
}
.bg-gray-600 {
    background-color: $gray-600;
}
.bg-gray-700 {
    background-color: $gray-700;
}
.bg-gray-800 {
    background-color: $gray-800;
}
.bg-gray-900 {
    background-color: $gray-900 !important;
}
.bg-light-100 {
    background-color: $light-100 !important;
}
.bg-light-300 {
    background-color: $light-300 !important;
}
.bg-light-400 {
    background-color: $light-400 !important;
}
.bg-light-500 {
    background-color: $light-500 !important;
}
.bg-success-800 {
    background-color: $success-800 !important;
}
.svg-primary {
    fill: $primary;
}
.svg-secondary {
    fill: $secondary;
}
.svg-success {
    fill: $success;
}
.svg-danger {
    fill: $danger;
}
.svg-warning {
    fill: $warning;
}
.svg-white {
    fill: $white;
}
.svg-black {
    fill: $black;
}
.svg-pink {
    fill: $pink;
}
.svg-purple {
    fill: $purple;
}
.svg-info {
    fill: $info;
}
.svg-light {
  fill: $light;
}
.svg-dark {
  fill: $dark;
}
.svg-default {
  fill: $text-color;
}
.color-container {
  width: 5rem;
  height: 5rem;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3rem;
}
.text-container {
  padding: 0.25rem 0.5rem;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}
.color-box {
    width: 80px;
    height: 80px;
    border-radius: $border-radius;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
}
.bg-light-200 {
    background: $light-200;
}
.bg-light-300 {
    background: $light-300;
}
.bg-transparent-primary {
    background: $primary-transparent;
}
.bg-transparent-dark {
    background: $dark-transparent;
}
.bg-transparent-warning {
    background: $warning-100 !important;
}
.bg-transparent-light {
    background: $light !important;
}
.bg-transparent-danger {
    background: $danger-100 !important;
}
.bg-transparent-success {
    background: $success-100 !important;
}
.bg-transparent-info {
    background: $info-100 !important;
}
.bg-transparent-purple {
    background: $purple-transparent !important;
    color: $purple !important;
}
.bg-transparent-secondary {
    background: $secondary-transparent !important;
}
.bg-transparent-skyblue {
    background: $skyblue-transparent !important;
}
.bg-light-gray {
    background: $light-500;
}
.bg-soft-pink {
   background: $pink-100;
   color: $pink;
}
.bg-soft-skyblue {
    background: $skyblue-100;
}
.bg-danger-transparent {
    background: $danger-transparent;
    border-color: $danger-transparent !important;
}
.bg-pink-transparent {
    background: $pink-transparent !important;
    color: $pink !important;
}
.bg-outline-pink {
    border: 1px solid $pink !important;
    color: $pink !important;
}
.bg-outline-purple {
    border: 1px solid $purple !important;
    color: $purple !important;
}
.bg-outline-skyblue {
    border: 1px solid $skyblue !important;
    color: $skyblue !important;
}