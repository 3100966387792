.flatpickr-current-month {
	.flatpickr-monthDropdown-months {
		.flatpickr-monthDropdown-month {
			background-color: #FFF ;
			font-size: 0.813rem;
		}
	}
}
.flatpickr-current-month {
	.flatpickr-monthDropdown-months {
		border-radius: 0.25rem ;
		border: 1px dashed rgba(242, 101, 34, 0.3) ;
		&:hover {
			background: transparent ;
		}
	}
	.numInputWrapper {
		span {
			&.arrowUp {
				&:after {
					border-bottom-color: #677788 ;
				}
			}
		}
	}
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after,
.flatpickr-calendar.arrowBottom:before {
	border-top-color: #677788 ;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
	stroke: $primary;
}
.flatpickr-months,
.flatpickr-weekdays {
  background-color: rgba(242, 101, 34, 0.1) ;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
	inset-block-start: 0 ;
	padding-block-start: 0.313rem ;
	padding-inline: 0.313rem ;
	padding-block-end: 0 ;
	color: $primary ;
	fill: $primary ;
}
.flatpickr-months .flatpickr-month {
    color: $primary ;
    fill: $primary ;
  }
  span.flatpickr-weekday {
    color: rgba(242, 101, 34, 0.8) ;
    font-weight: 700 ;
}
.flatpickr-day.today {
    border-color: $primary ;
    background-color: $primary ;
    color: #FFF ;
}
.flatpickr-day {
    border-radius: 4px ;
}
.icons-list {
    padding: 0;
    display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      li {
        display: block;
        width: 40px;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        font-size: $font-size-18;
        @include rounded(50px);
        border: 1px solid $gray-200;
        margin: 5px;
        @include box-shadow(null, 0, 2px, 3px, null, rgb(215, 197, 255));
    }
    .icons-list-item {
      
      display: block;
      width: 50px;
      height: 50px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      justify-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      font-size: $font-size-18;
      @include rounded(50px);
      border: 1px solid $gray-200;
      margin: 5px;
        &:hover {
            background-color: $gray-200;
            color: $primary;
            font-size: 24px;
        }
    }
}


/* Start:: sortable js */
ul,ol{
    &.sortable-list {
      li {
        background-color: $primary;
        margin: 3px 0;
        border: 1px solid $primary;
        border-radius: 0.3rem;
        font-weight: 500;
        &.filtered {
          background-color: $danger;
          color: $white;
        }
        &.selected {
          background-color: $primary;
          border: 1px solid $primary;
          color: $white;
        }
      }
      .list-group-item{
        background-color:rgba(242, 101, 34, 0.05) ;
      }
      .list-group-item+.list-group-item {
        border-top-width: 1px;
        background-color:rgba(242, 101, 34, 0.05) ;
      }
    }
    &#shared-right,&#cloning-right,&#disabling-sorting-right {
      li {
        background-color: rgba($secondary, 0.05);
        border: 1px solid rgba($secondary, 0.05);
      }
    }
    .handle {
      cursor: grab;
    } 
  }
  .grid-square {
    width: 100px;
    height: 100px;
    display: inline-block;
    background-color: $white;
    border: 1px solid $default-border;
    border-radius: 0.5rem;
    margin-block-end: 1.5rem;
    margin-inline-end: 1.5rem;
    padding: 20px;
  }
  .nested-sortable, .nested-1, .nested-2, .nested-3 {
    margin-top: 5px;
    .list-group-item+.list-group-item {
      border-top-width: 1px;
    }
  }
  .nested-1, .nested-2, .nested-3 {
    border: 1px solid rgba($secondary, 0.05);
    font-weight: 500;
  }
  /* End:: sortable js */
  
  
  /* Start::Leaflet Maps */
  #map,
  #map1,
  #map-popup,
  #map-custom-icon,
  #interactive-map {
    height: 18.75rem;
    z-index: 10;
  }
  
  /* End::Leaflet Maps */
  
  /* Start::Vector Maps */
  .jvm-zoom-btn {
    background-color: $light ;
    color: $text-color ; 
    border: 1px solid $default-border ;
    font-size: 20px;
  }
  #vector-map,
  #marker-map,
  #marker-image-map,
  #lines-map,
  #us-map,
  #canada-map,
  #spain-map,
  #russia-map {
    height: 21.875rem;
  }
  
  .jvm-tooltip {
    background-color: #1a1c1e ;
  }
  
  #vector-map,
  #marker-map,
  #marker-image-map,
  #lines-map,
  #users-map {
    #jvm-regions-group path {
      fill: $light ;
    }
  }
  
  .jvm-zoom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px ;
    height: 20px ;
    line-height: 20px ;
  }
  
  .jvm-zoom-btn.jvm-zoomin {
    inset-block-start: 5px ;
  }
  
  #jvm-markers-labels-group text {
    fill: $text-muted;
  }
  
  /* End::Vector Maps */
  
  /* Start::Google Maps */
  #google-map,
  #google-map-overlay,
  #map-layers,
  #map-markers,
  #streetview-map,
  #map-geofencing {
    height: 18.75rem;
  }
  
  .google-map-overlay {
    display: block;
    text-align: center;
    color: $white;
    font-size: 1.25rem;
    line-height: 0.875rem;
    opacity: 0.8;
    background: $primary;
    border: solid 0.188rem $primary;
    border-radius: 0.25rem;
    padding: 0 0.25rem;
  }
  
  .google-overlay_arrow {
    inset-inline-start: 50%;
    margin-inline-start: -1rem;
    width: 0;
    height: 0;
    position: absolute;
  }
  
  .google-overlay_arrow.above {
    inset-block-end: -0.938rem;
    border-inline-start: 0.938rem solid transparent;
    border-inline-end: 1rem solid transparent;
    border-block-start: 1rem solid #336699;
  }
  
  .google-overlay_arrow.below {
    inset-block-start: -0.938rem;
    border-inline-start: 1rem solid transparent;
    border-inline-end: 1rem solid transparent;
    border-block-end: 1rem solid #336699;
  }
  
  /* End::Google Maps */
  
  
  /* Start:Pickers */
  .flatpickr-time .flatpickr-am-pm {
    width: 36% ;
  } 
  [dir="rtl"] {
    .flatpickr-input {
      text-align: start;
    }
  }
  
  .flatpickr-calendar {
    background-color: $white ;
    box-shadow: $box-shadow ;
    border: 1px solid $default-border ;
    font-size: $font-size-base ;
  }
  
  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    color: $black ;
    fill: $black ;
  }
  .rangeMode .flatpickr-day {
    margin-top: 4px ;
  }
  .flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
    border-radius: 50px 0 0 50px ;
  }
  .flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
    border-radius: 0 50px 50px 0 ;
  }
  .flatpickr-monthDropdown-months,
  .numInput {
    color: $black ;
  }
  
  .flatpickr-day.today.inRange {
    color: $primary ;
  }
  
  .dayContainer {
    padding: 0.25rem ;
  }
  
  .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: $white ;
    font-size: 0.813rem;
  }
  
  .flatpickr-months .flatpickr-prev-month svg,
  .flatpickr-months .flatpickr-next-month svg {
    width: 1.25rem ;
    height: 1.25rem ;
    padding: 0.25rem;
    fill: $primary;
  }
  
  .flatpickr-day.inRange {
    box-shadow: none ;
  }
  
  .flatpickr-calendar.open {
    z-index: 105 ;
  }
  
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    border-radius: 0.25rem ;
    border: 1px dashed $primary ;
  }
  
  .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: transparent ;
  }
  
  .flatpickr-current-month .numInputWrapper span.arrowDown:after,
  .flatpickr-calendar.arrowBottom:before {
    border-top-color: $text-muted ;
  }
  
  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-bottom-color: $text-muted ;
  }
  
  .flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n + 1)) {
    box-shadow: none ;
  }
  
  .flatpickr-day {
    color: $gray-600 ;
    font-weight: 500 ;
  
    &.nextMonthDay,
    &.prevMonthDay {
      opacity: 0.5 ;
    }
  }
  
  .flatpickr-day.today {
    border-color: $primary ;
    background-color: $primary ;
    color: $white ;
  }
  
  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: $primary ;
    color: $white ;
    border-color: $primary ;
  }
  
  .flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
    background: $default-background ;
    border-color: $default-border ;
  }
  
  .flatpickr-day.today:hover {
    background-color: $primary ;
    border-color: $primary ;
    color: $white ;
  }
  
  .flatpickr-calendar.hasTime .flatpickr-time {
    border-top: 1px solid $default-border ;
  }
  
  .flatpickr-calendar.arrowTop:after,
  .flatpickr-calendar.arrowTop:before {
    border-bottom-color: $default-border ;
  }
  
  .flatpickr-calendar.arrowBottom:after,
  .flatpickr-calendar.arrowBottom:before {
    border-top-color: $default-border ;
  }
  
  .flatpickr-time input:hover,
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time input:focus,
  .flatpickr-time .flatpickr-am-pm:focus {
    background: $default-background ;
  }
  
  .flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .flatpickr-am-pm {
    color: $text-muted ;
  }
  
  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
    inset-inline-start: 0 ;
  }
  

  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    inset-block-start: 0 ;
    padding-block-start: 0.313rem ;
    padding-inline: 0.313rem ;
    padding-block-end: 0 ;
    color: $primary ;
    fill: $primary ;
  }
  
  @media (min-width: 420px) {
    .flatpickr-time .flatpickr-am-pm {
      padding: 0 1.875rem 0 0.5rem;
    }
  }
  
  .flatpickr-weekdays {
    border-block-end: 1px solid $default-border ;
  }
  
  .numInputWrapper span.arrowUp {
    top: -0.125rem ;
  }
  
  .flatpickr-current-month .numInputWrapper {
    width: 3.5rem ;
  }
  
  .flatpickr-calendar.hasTime {
    width: auto;
  }
  
  [dir="rtl"] {
    .flatpickr-months {
      .numInputWrapper span {
        left: 0 ;
        right: inherit ;
      }
    }
  }
  
  @media (max-width: 575.98) {
    .flatpickr-calendar {
      width: 250px ;
    }
  }
  
  .flatpickr-current-month .flatpickr-monthDropdown-months {
    margin: -1px 2.25rem 0 0 ;
  }
  
  .flatpickr-current-month .flatpickr-monthDropdown-months,
  .flatpickr-current-month input.cur-year {
    padding-block: 0 ;
    padding-inline-start: 0 ;
    padding-inline-end: 0.5ch ;
    font-size: 0.875rem ;
    font-weight: 500 ;
  }
  
  .flatpickr-months .flatpickr-prev-month:hover,
  .flatpickr-months .flatpickr-next-month:hover {
    stroke: $primary;
  }
  
  .flatpickr-day {
    border-radius: 4 ;
  }
  
  .numInputWrapper:hover {
    background: transparent ;
  }
  
  .numInputWrapper span {
    border: 0px ;
  }
  
  .flatpickr-current-month .numInputWrapper span.arrowUp:after {
    border-block-end-color: $primary ;
  }
  
  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: $white ;
  }
  
  .numInputWrapper span:hover {
    background: transparent ;
  }
  
  .numInputWrapper span.arrowUp:after {
    border-inline-start: 0.25rem solid transparent ;
    border-inline-end: 0.25rem solid transparent ;
    border-block-end: 0.25rem solid $primary ;
    inset-block-start: 75% ;
  }
  
  .flatpickr-current-month .numInputWrapper span.arrowDown:after {
    border-block-start-color: $primary ;
  }
  
  .numInputWrapper span.arrowDown:after {
    border-inline-start: 0.25rem solid transparent ;
    border-inline-end: 0.25rem solid transparent ;
    border-block-start: 0.25rem solid $primary ;
    inset-block-start: 15% ;
  }
  
  span.flatpickr-weekday {
    color: $primary ;
    font-weight: 700 ;
  }
  
  .flatpickr-months .flatpickr-month {
    color: $primary ;
    fill: $primary ;
  }
  
  .flatpickr-monthDropdown-months,
  .numInput {
    color: $primary ;
  }
  
  .pcr-app {
    background: $white ;
  }
  
  .pcr-app .pcr-interaction .pcr-result {
    color: $gray-600 ;
    background: $default-background ;
  }
  
  .theme-container,
  .theme-container1,
  .theme-container2 {
    button {
      display: none;
    }
  }
  
  .pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview {
    margin-inline-end: 0.75em ;
  }
  
  .pcr-app[data-theme="classic"] .pcr-selection .pcr-color-chooser,
  .pcr-app[data-theme="classic"] .pcr-selection .pcr-color-opacity {
    margin-inline-start: 0.75em ;
  }
  
  .flatpickr-weekwrapper .flatpickr-weeks {
    box-shadow: 1px 0 0 $default-border ;
  }
  
  /* End:Pickers */

  /* Start:: swiper js */
.swiper {
    border-radius: 5px;
  }
  
  [dir="rtl"] {
    .swiper {
      direction: ltr;
    }
  
    .swiper-backface-hidden .swiper-slide {
      direction: rtl;
    }
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    width: 1.563rem ;
    height: 1.563rem ;
    color: $white ;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
  }
  
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 0.75rem ;
    font-weight: 800 ;
  }
  
  .swiper-horizontal1 .swiper-slide {
    height: auto;
  }
  
  .swiper-pagination-bullet {
    width: 1.25rem ;
    height: 0.25rem ;
    border-radius: 5px ;
    background: $white ;
  }
  
  .swiper-pagination-bullet-active {
    background: $white ;
  }
  
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: $success ;
  }
  
  .swiper-pagination {
    color: $white;
  }
  
  .custom-pagination {
    .swiper-pagination-bullet {
      width: 1.5rem ;
      height: 1.5rem ;
      padding: 0.188rem;
      background-color: rgba(255, 255, 255, 0.3) ;
      color: $white ;
      opacity: 1 ;
  
      &.swiper-pagination-bullet-active {
        background-color: $white ;
        color: $black ;
      }
    }
  }
  
  .swiper-scrollbar {
    background-color: rgba(255, 255, 255, 0.3) ;
  }
  
  .swiper-scrollbar-drag {
    background-color: $white ;
  }
  
  .swiper.vertical {
    height: 21.875rem;
  }
  
  .swiper-preview {
    height: 80%;
    width: 100%;
  }
  
  .swiper-view {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .swiper-view .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .swiper-view .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .swiper-preview .swiper-wrapper {
    margin-block-end: 0.65rem;
  }
  
  .swiper-thumbs .swiper-slide img {
    border-radius: 5px;
  }
  
  /* End:: swiper js */