.notification-dropdown {
    max-width: 100%;
    min-width: 450px !important;
    .topnav-dropdown-header {
        border-bottom: 1px solid $light-900
    }
    .dropdown {
        .dropdown-toggle {
            border: 0;
            &.show {
                color: $gray-900;
            }
        }
    }
}
.topnav-dropdown-header {
    padding: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    .notification-title {
        font-size: $font-size-16;
        color: $black;
        margin-bottom: 0;
        font-weight: $font-weight-bold;
    }
}
.notification-dropdown {
    .noti-content {
        height: 270px;
        overflow-y: auto;
        position: relative;
    }
}
.activity {
    width: 100%;
    .activity-box {
        position: relative;
    }
    .activity-list {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
        li {
            background-color: $white;
            position: relative;
            border: 1px solid $gray-400;
            margin:0  0 10px ;
            padding:10px ;
            font-size: $font-size-15;
            border-radius: 6px;
            display: flex;
            align-items: start;
            .activity-user {
                margin-right: 10px;
                > a {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .activity-content {
                background-color: $white;
                position: relative;
                flex: 1;
            }
            .time {
                color: $gray-500;
                display: block;
                font-size: $font-size-12;
                line-height: 1.35;
            }
            a.name {
                color: $black;;
            }
            a {
                font-weight: $font-weight-semibold;
                font-size: $font-size-14;
                &:hover {
                    color: $primary;
                }
                img{
                    width:50px;
                    height:50px;
                    border-radius: 5px;
                }
            }
        }
    }
}
.notification_item {
    position: relative;
}
#global-loader {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
	width: 100%;
	height: 100%;
	background: $white;
	display: flex;
	align-items: center;
	justify-content: center;
}
.page-loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
  }
  
  .page-loader img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: rotation 2s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }
.notification-status-dot {
    width: 6px;
    height: 6px;
    background: $danger;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
    &:before {
        content: "";
        position: absolute;
        width: 14px;
        height: 14px;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation: pulsate1 2s;
        animation: pulsate1 2s;
        -webkit-animation-direction: forwards;
        animation-direction: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: steps;
        animation-timing-function: steps;
        opacity: 1;
        border-radius: 50%;
        border: 5px solid $danger-100;
        top: -4px;
        left: -4px;
        background: $danger-500;
        z-index: -1;
    }
}
  
@-webkit-keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
  
    }
  }  
  @keyframes pulsate1 {
    0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
      opacity: 1;
      box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
    }
    100% {
      -webkit-transform: scale(1, 1);
      transform: scale(1);
      opacity: 0;
      box-shadow: none;
  
    }
  }
.chat-status-dot {
    width: 6px;
    height: 6px;
    background: $skyblue;
    border-radius: 50%;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
    &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation: pulsate1 2s;
        animation: pulsate1 2s;
        -webkit-animation-direction: forwards;
        animation-direction: forwards;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: steps;
        animation-timing-function: steps;
        opacity: 1;
        border-radius: 50%;
        border: 5px solid $skyblue;
        top: -2px;
        left: -2px;
        background: $skyblue;
        z-index: -1;
    }
}
.noti-delete {
    display: none;
    transition: 0.5s all;
}
.noti-hover {
    &:hover {
        .noti-delete {            
            display: block;
        }
    }
}