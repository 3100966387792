/* Mola türü seçme modalı için stiller */
.break-type-options .btn {
    transition: all 0.3s ease;
    border-radius: 10px;
    min-height: 60px;
    border-width: 2px;
  }
  
  .break-type-options .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .break-type-options .btn:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .break-type-options .btn i {
    transition: all 0.3s ease;
    font-size: 28px !important;
  }
  
  .break-type-options .btn:hover i {
    transform: scale(1.2);
  }
  
  .break-type-options .btn span {
    font-weight: 500;
    margin-top: 5px;
  }
  
  /* Özel modal stilleri */
  .custom-modal .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }
  
  .custom-modal .ant-modal-header {
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .custom-modal .ant-modal-body {
    padding: 20px;
  }
  
  .custom-modal .ant-result {
    padding: 24px 0;
  }
  
  .custom-modal .ant-result-title {
    font-size: 20px;
  }
  
  .custom-modal .ant-result-subtitle {
    font-size: 14px;
  }
  
  @media (max-width: 576px) {
    .break-type-options .btn {
      min-height: 80px;
      padding: 10px !important;
    }
    
    .break-type-options .btn i {
      font-size: 20px !important;
    }
    
    .break-type-options .btn span {
      font-size: 12px;
    }
    
    .custom-modal .ant-modal-content {
      margin: 0 10px;
    }
  }

  /* Onay modalı için gelişmiş stiller */
  .confirm-modal .ant-modal-content {
    border-radius: 20px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
    background: linear-gradient(to bottom, #ffffff, #f8fafc);
    transform: translateY(0);
    animation: modalAppear 0.4s ease-out;
  }

  @keyframes modalAppear {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .confirm-modal .ant-modal-header {
    padding: 28px 32px 24px;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    border-bottom: 1px solid rgba(230, 230, 230, 0.7);
    position: relative;
  }

  .confirm-modal .ant-modal-header::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 10%;
    right: 10%;
    height: 2px;
    background: linear-gradient(90deg, transparent, rgba(24, 144, 255, 0.5), transparent);
  }

  .confirm-modal .modal-title {
    font-size: 22px;
    font-weight: 700;
    color: #2c3e50;
    letter-spacing: -0.3px;
  }

  .confirm-modal .ant-modal-body {
    padding: 40px 32px;
    background-image: radial-gradient(circle at top right, rgba(240, 249, 255, 0.8), transparent 70%);
  }

  .confirm-modal .confirm-message {
    font-size: 18px;
    color: #333;
    line-height: 1.7;
    text-align: center;
    margin-bottom: 36px;
    position: relative;
    padding: 0 10px;
  }

  .confirm-modal .confirm-message::before,
  .confirm-modal .confirm-message::after {
    content: '"';
    font-size: 32px;
    color: rgba(24, 144, 255, 0.2);
    position: absolute;
    font-family: Georgia, serif;
  }

  .confirm-modal .confirm-message::before {
    left: -5px;
    top: -10px;
  }

  .confirm-modal .confirm-message::after {
    right: -5px;
    bottom: -20px;
  }

  .modal-button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }

  .modal-cancel-button {
    min-width: 140px;
    height: 52px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid #d9d9d9;
    background-color: #f5f5f5;
    color: #555;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    overflow: hidden;
  }

  .modal-cancel-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent);
    transform: translateX(-100%);
    transition: transform 0.6s;
  }

  .modal-cancel-button:hover {
    background-color: #e8e8e8;
    color: #333;
    border-color: #c0c0c0;
    transform: translateY(-3px);
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1);
  }

  .modal-cancel-button:hover::before {
    transform: translateX(100%);
  }

  .modal-cancel-button:active {
    transform: translateY(-1px);
  }

  .modal-confirm-button {
    min-width: 160px;
    height: 52px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0 6px 15px rgba(24, 144, 255, 0.3);
    background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
    border: none;
    position: relative;
    overflow: hidden;
  }

  .modal-confirm-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2), transparent);
    transform: translateX(-100%);
    transition: transform 0.6s;
  }

  .modal-confirm-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(24, 144, 255, 0.4);
    background: linear-gradient(135deg, #40a9ff 0%, #1890ff 100%);
  }

  .modal-confirm-button:hover::after {
    transform: translateX(100%);
  }

  .modal-confirm-button:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(24, 144, 255, 0.3);
  }

  /* Sonuç modalı için gelişmiş stiller */
  .custom-modal .ant-result {
    padding: 40px 0 32px;
  }
  .custom-modal .ant-modal-close{
    top: 24px;
    inset-inline-end: 28px;
  }
  .custom-modal .ant-result-icon {
    margin-bottom: 28px;
  }

  .custom-modal .ant-result-icon .anticon {
    font-size: 80px;
    filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.1));
  }

  .custom-modal .ant-result-success .anticon {
    color: #52c41a;
  }

  .custom-modal .ant-result-error .anticon {
    color: #ff4d4f;
  }

  .custom-modal .ant-result-title {
    font-size: 28px;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 20px;
    letter-spacing: -0.5px;
  }

  .custom-modal .ant-result-subtitle {
    font-size: 17px;
    color: #5a6268;
    line-height: 1.7;
    max-width: 80%;
    margin: 0 auto;
  }

  .custom-modal .ant-result-extra {
    margin-top: 40px;
  }

  .custom-modal .ant-result-extra .ant-btn {
    height: 48px;
    min-width: 140px;
    font-size: 16px;
    border-radius: 12px;
    font-weight: 600;
    box-shadow: 0 6px 15px rgba(24, 144, 255, 0.3);
  }

  /* Mazeret modalı için gelişmiş stiller */
  .custom-modal .form-control {
    border-radius: 12px;
    padding: 14px 18px;
    border: 1px solid #d9d9d9;
    transition: all 0.3s ease;
    font-size: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
  }

  .custom-modal .form-control:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.15), 0 2px 8px rgba(0, 0, 0, 0.05);
    transform: translateY(-1px);
  }

  .custom-modal .form-label {
    font-weight: 600;
    margin-bottom: 12px;
    color: #2c3e50;
    font-size: 16px;
    display: block;
    position: relative;
    padding-left: 12px;
  }

  .custom-modal .form-label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 16px;
    background: linear-gradient(to bottom, #1890ff, #096dd9);
    border-radius: 2px;
  }

  /* Yükleme durumu için stiller */
  .custom-modal .ant-spin {
    margin-bottom: 20px;
  }

  .custom-modal .ant-spin-dot {
    transform: scale(1.2);
  }

  .custom-modal .ant-spin-dot-item {
    background-color: #1890ff;
    width: 12px;
    height: 12px;
  }

  .custom-modal .loading-text {
    font-size: 17px;
    color: #5a6268;
    margin-top: 20px;
    animation: pulse 1.5s infinite;
  }

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }

  /* Mola türü seçme modalı için gelişmiş stiller */
  .break-modal .break-type-options {
    padding: 10px 5px;
  }

  .break-type-options .break-btn {
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 16px;
    min-height: 120px;
    border-width: 2px;
    background: linear-gradient(to bottom, #ffffff, #f8fafc);
    position: relative;
    overflow: hidden;
  }

  .break-type-options .break-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(24, 144, 255, 0.05) 0%, rgba(24, 144, 255, 0.1) 100%);
    opacity: 0;
    transition: opacity 0.4s;
  }

  .break-type-options .break-btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-color: #1890ff;
  }

  .break-type-options .break-btn:hover::before {
    opacity: 1;
  }

  .break-type-options .break-btn:active {
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }

  .break-type-options .break-icon-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(135deg, #e6f7ff 0%, #bae7ff 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    transition: all 0.4s ease;
  }

  .break-type-options .break-btn:hover .break-icon-wrapper {
    transform: scale(1.1);
    background: linear-gradient(135deg, #bae7ff 0%, #91d5ff 100%);
  }

  .break-type-options .break-btn i {
    transition: all 0.4s ease;
    font-size: 30px !important;
    color: #1890ff;
  }

  .break-type-options .break-btn:hover i {
    transform: scale(1.1);
    color: #096dd9;
  }

  .break-type-options .break-btn span {
    font-weight: 600;
    margin-top: 8px;
    font-size: 15px;
    color: #2c3e50;
    transition: all 0.3s ease;
  }

  .break-type-options .break-btn:hover span {
    color: #1890ff;
  }