.settings-list {
  &.list-group {
    a {
      color: $gray-500;
      &:hover {
        background: $primary-100;
        color: $primary;
      }
      &.active {
        color: $primary;
        font-weight: $font-weight-medium;
      }
    }
  }

}

//Appearance Settings
.theme-colors{
  ul li{
      margin-right: 10px;
  }
  ul li span{
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      background-color: $white;
      border: 1px solid $dark-transparent;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      .primecolor{
          content: "";
          display: flex;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          opacity: 1;
          position: relative;
          .colorcheck{
              visibility: hidden;
              width: 12px;
              height: 12px;
              background: #fff;
              border-radius: 4px;

          }
          &:hover.colorcheck{
              visibility: visible;
          }

      }
      &.active .colorcheck{
          visibility: visible;
      }
  }
}
.search-input {
  input {
      padding-left: 30px;
  }
  .search-addon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
  }
}

.circle-progress {
  width: 21px;
  height: 21px !important;
  float: left; 
  line-height: 21px;
  background: none;
  box-shadow: none;
  position: relative;
  &::after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 3px solid  $light-900;
      position: absolute;
      top: 0;
      left: 0;
  }
  .progress-left {
      left: 0;
      .progress-bar {
          left: 100%;
          border-top-right-radius: 80px;
          border-bottom-right-radius: 80px;
          border-left: 0;
          -webkit-transform-origin: center left;
          transform-origin: center left;
      }
  }
  .progress-right {
      right: 0;
      .progress-bar {
          left: -100%;
          border-top-left-radius: 80px;
          border-bottom-left-radius: 80px;
          border-right: 0;
          -webkit-transform-origin: center right;
          transform-origin: center right;
          animation: loading-1 1.8s linear forwards;
        }
  }
  .progress-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: 3px;
      border-style: solid;
      position: absolute;
      top: 0;
  }
}
.circle-progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}
.wh-20 {
  width: 20px;
  height: 20px;
}