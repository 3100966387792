/* Şirket listesi sayfası için stiller */
.company-list-card {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.company-search {
  width: 300px;
}

.company-name-link {
  color: #2c7be5;
  font-weight: 500;
  text-decoration: none;
}

.company-name-link:hover {
  text-decoration: underline;
}

/* Şirket ekleme ve detay sayfası için stiller */
.company-form {
  max-width: 100%;
}

.form-section {
  margin-bottom: 24px;
  padding: 16px;
  background-color: #fbfbfb;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
}

.section-title {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.company-details-card {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.company-logo {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 20px;
}

.company-info {
  flex: 1;
}

.company-name {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 4px;
}

.company-meta {
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
}

.detail-label {
  font-weight: 500;
  color: #666;
  margin-bottom: 4px;
}

.detail-value {
  font-size: 16px;
  margin-bottom: 16px;
}

.status-active {
  color: #52c41a;
}

.status-inactive {
  color: #d9d9d9;
}

.status-suspended {
  color: #ff4d4f;
}

.company-stats-card {
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  height: 100%;
}

.stats-icon {
  font-size: 24px;
  padding: 12px;
  border-radius: 50%;
  margin-right: 16px;
}

.stats-title {
  color: #666;
  font-size: 14px;
  margin-bottom: 4px;
}

.stats-value {
  font-size: 24px;
  font-weight: 600;
}

/* Responsive ayarlamaları */
@media (max-width: 768px) {
  .company-search {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .details-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .company-logo {
    margin-bottom: 16px;
  }
} 