aside {
    padding: 24px;
}
.video-section .item{
  opacity:0.4;
  transition:.4s ease all;
  transform:scale(.8);
}
@media(max-width:1000px){
  .video-section .item{margin:0; transform:scale(.9)}
}
.video-section .active .item{
  opacity:1;
  transform:scale(1);
}
.video-section .owl-item {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
}
.video-section video{
  max-width:100%;
  height:100%;
  @include rounded(10px 10px 0 0);
}
.deleted-info {
    display: none;
    .bin-bg {
        width: 184px;
        height: 184px;
        @include rounded(100%);
        background: $light;
        margin: 0 auto;
    }
}
svg {
    &.feather-16 {
        width: 16px;
        height: 16px;
    }
    &.feather-14 {
        width: 14px;
        height: 14px;
    }
}
.files-list {
    a {
        @include rounded(5px);
        &:hover, &.active {
            background: $dark-transparent;
            color: $gray-900 !important;
        }
    }
}
.bg-01 {
    background: url('../../../../public/assets/img/bg/upgrade-bg.svg');
}
.access-wrap {
    position: relative;
    .access-rate {
        position: absolute;
        top: 8px;
        right: 8px;
    }
}
.rating-select {
    i {
        color: $gray-500;
        &.filled {
            color: $warning;
        }
    }
}
.folder-wrap {
    @include rounded(5px);
    padding: 16px;
    margin-bottom: 24px;
    &:hover {
        box-shadow: $drop-shadow;
    }
}
.video-wrap {
    @include rounded(5px);
    margin-bottom: 24px;
    .video-content {
        background: $white;
        padding: 20px;
        @include rounded(0 0 5px 5px);
    }
    &:hover {
        box-shadow: $drop-shadow;
    }
}
.files-wrap {
    @include rounded(5px);
    margin-bottom: 24px;
    .files-icon {
        @include rounded(5px 5px 0 0);
    }
    .files-content {
        @include rounded(0 0 5px 5px);
    }
    &:hover {
        box-shadow: $drop-shadow;
    }
}
.file-name-icon {
    img {
        width: auto;
    height: auto;
    }
}
.file-type {
    border: 1px solid $gray-200;
    @include rounded(5px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    & > div {
        flex: 1;
    }
}
.document-wrap {
    padding: 20px;
}
.recent-activity {
    li {
        padding-bottom: 16px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 16px;
            border-color: $gray-200;
            border-width: 1px;
            width: 1px;
            height: 100%;
            border-style: dashed;
        }
        &:last-child {
            padding-bottom: 0;
            &::before {
                content: none;
            }
        }
    }
}
.preview-content {
    padding: 20px;
}
.user-icon {
    width: 30px;
    height: 30px;
    background: $white;
    color: $gray-700;
    @include rounded(5px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    &:hover {
        background: $dark-transparent;
        color: $gray-900;
    }
}
.file-drop {
    position: relative;
    transition: all 0.5s;
    border: 1px dashed $border-color;
    @include rounded(4px);
    padding: 24px;
    input {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
    }
}
