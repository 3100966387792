a.edit-icon {
    width: 22px;
    height: 22px;
    @include rounded(50%);
    background: $dark-transparent;
    color: $gray-500;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    &:hover {
        background: darken($dark-transparent, 7%);
        color: $gray-500;
    }
}
.emp-dept {
    .dept-name {
        margin-right: 8px;
    }
    .emp-progress {
        position: relative;
        padding-left: 10px;
        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background: $gray-200;
            width: 1px;
            height: 100%;
        }
        .pro-wrap {
            padding: 12px 0;
            border-bottom: 1px dashed $border-color;
        }
    }
}
.emp-stack {
    &.progress-stacked {
        height: 24px;
        & > .progress {
            height: 24px;
        }
    }
}
.recent-item {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.todo-item {
    &.todo-strike {
        background: $primary-transparent;
        .form-check-label {
            text-decoration: line-through;
        }
    }
    .form-check-label {
        color: $gray-900;
    }
}
.attendance-canvas {
    top: 50%;
    left: 50%;
    @include transform(translateX(-50%));
}
#myRadarChart {
    height: 150px;
}