.incoming-call {
    position: relative;
    height: calc(100vh - 174px);
    overflow-y: auto;
    @include respond-below(md) {
        height: calc(100vh - 160px);
    }
}
.voice-call-img {
    img {
        width: 150px;
        height: 150px;
        animation: ripple 2s infinite;
        @include respond-below(lg) {
            width: 120px;
            height: 120px;
        }
        @include respond-below(md) {
            width: 80px;
            height: 80px;
        }
    }
}
.call-item {
    width: 40px;
    height: 40px;
    @include rounded(50%);
}
@keyframes ripple {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    }
    100% {
        -webkit-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
        -moz-box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
    }
}
