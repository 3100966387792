.icon-list {
    .btn.btn-icon {
        color: $gray-500;
        &:hover {
            background: $white;
        }
        &.active {
            box-shadow: $box-shadow-sm;
        }
    }
}
.card-bg-1 {
    padding-top: 50px;
    position: relative;
    &::before {
        content: "";
        background-image: url(../../../../public/assets/img/bg/card-bg.png);
        background-repeat: no-repeat;
        background-position: top;
        position: absolute;
        border-radius: 5px;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 90px;
        background-size: cover;
    }
}
.hover-border {
    transition: 0.5s all;
    &:hover {
        border-color: $primary !important;
        transition: 0.5s all;
    }
}
.note-edit-wrap {
    display: none;
}

.file-upload {
    input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
    &.drag-file {
        height: 170px;
    }
}
.add-details-wizard {
    li {
        border-bottom: 1px solid $white;
        &.active {
            border-color: $primary;
        }
    }
}
.add-info-fieldset {
    fieldset {
        display: none;
        &#first-field-file {
            display: block;
        }
        &#second-field-file {
            display: block;
        }
    }
}
.icons-social {
    a {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        i {
            color: $gray-500;
            font-size: $font-size-16;
        }
        &:hover {
            background: $danger-transparent;
            color: $primary;
            i {
                color: $primary;
            }
        }
    }
}
.pipeline-list {
    border-bottom: 1px solid $light-900;
    margin-bottom: 8px;
    ul {
        margin-bottom: 15px;
        li {
            display: inline-block;
            &:first-child {
                a {
                    border-radius: 10px 0 0 10px;
                }
            }
            &:last-child {
                a {
                    border-radius: 0 10px 10px 0;
                    &::after {
                        display: none;
                    }
                }
            }
            a {
                margin: 0 0 3px;
                padding: 12px 25px 12px 30px;
                font-size: $font-size-14;
                font-weight: $font-weight-medium;
                display: inline-block;
                text-align: center;
                background: $light-300;
                color: $title-color;
                position: relative;
                min-width: 150px;
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -23px;
                    width: 45px;
                    height: 45px;
                    -webkit-transform: scale(.707) rotate(45deg);
                    -ms-transform: scale(.707) rotate(45deg);
                    -moz-transform: scale(.707) rotate(45deg);
                    -transform: scale(.707) rotate(45deg);
                    z-index: 1;
                    background: $light-300;
                    -webkit-box-shadow: 2px -2px 0 1px #fff,3px -3px 0 2px rgba(255,255,255,.1);
                    box-shadow: 2px -2px 0 1px #fff,3px -3px 0 2px rgba(255,255,255,.1);
                    border-radius: 0 5px 0 50px;
                }
                &.bg-info {
                    color: $white;
                    &::after {
                        background: $info;
                    }
                    &:hover {
                        background: $info !important;
                    }
                }
                &.bg-warning {
                    color: $white;
                    &::after {
                        background: $warning;
                    }
                    &:hover {
                        background: $warning !important;
                    }
                }
                &.bg-purple {
                    color: $white;
                    background: $purple !important;
                    &::after {
                        background: $purple;
                    }
                    &:hover {
                        background: $purple !important;
                    }
                }
                &.bg-pink {
                    color: $white;
                    &::after {
                        background: $pink;
                    }
                    &:hover {
                        background: $pink !important;
                    }
                }
                &.bg-success {
                    color: $white;
                    &::after {
                        background: $success;
                    }
                    &:hover {
                        background: $success !important;
                    }
                }
                &.bg-danger {
                    color: $white;
                    &::after {
                        background: $danger;
                    }
                    &:hover {
                        background: $danger !important;
                    }
                }
                &.bg-gray {
                    color: $white;
                    // background: $background-3;
                    &::after {
                        // background: $background-3;
                    }
                    &:hover {
                        // background: $background-3 !important;
                    }
                }
                @include respond-below(sm) {
                    width: calc(100% - 25px);
                }
            }
            @include respond-below(sm) {
                display: block;
            }
        }
    }
    &.pipeline-list-two {
        ul {
            li {
                &:last-child {
                    a {
                        border-radius: 0;
                        &::after {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}
.text-truncate {
    white-space: normal;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    &.line-clamb-3 {
        -webkit-line-clamp: 3;
    }
    &.line-clamb-2 {
        -webkit-line-clamp: 2;
    }
}
.no-datatable-length {
    .dataTables_length,.dataTables_filter {
        display: none;
    }
}
.custom-accordion-items {
    .accordions-items-seperate {
        .accordion-item {
            margin-top: 0;
            margin-bottom: 24px;
            .accordion-header {
                .accordion-button {
                    padding: 1.25rem;
                    background: $white;
                    &::after {
                        display: none;
                    }
                    .collapse-arrow {
                        i {
                            transition: 0.5s all;
                        }
                    }
                    .collapse-arrow:not(.collapsed) {
                        i {
                            transform: rotate(180deg);
                            transition: 0.5s all;
                        }
                    }
                }
            }
            .accordion-body {
                padding: 1.25rem;
            }
        }
    }
    &.client-accordion {
        .accordion-button {
            &::after {
                display: flex !important;
            }
        }

    }
}
.dropdown-menu-md {
    min-width: 250px;
}
.dropdown-menu-dark {
    li {
        a {
            &:hover {
                background: $gray-700 !important;
            }
        }
    }
}
.details-list-group {
    .list-group-item {
        background: #FAFBFB;
        border-color: $gray-200 !important;
    }
}
.delete-popup {
    width: 600px;
    margin: auto;
    position: fixed;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.5s all;
    &.show {
        opacity: 1;
        bottom: 50px;
    }
}
