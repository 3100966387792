.content{
    .call-img{
        position: relative;
        z-index: 0;
        .call-overlay-top{
            position: absolute;
            padding: 24px;
            z-index: 2;
            top: 0;
            .pin-icon{
                width: 24px;
                height: 24px;
                background: $primary;
                color: $white;
            }
        }
        .call-overlay-bottom{
            position: absolute;
            padding: 24px;
            z-index: 2;
            bottom: 0;
            @include respond-below(sm){
                padding: 10px;
            }
            .call-option{
                padding: 16px;
                background: #F8F9FA66;
               
            }
            .options-icon{
                width: 32px;
                height: 32px;
                @include respond-below(sm){
                    width: 25px;
                    height: 25px;
                }
            }
            .call-icon{
                width: 48px;
                height: 48px;
                @include respond-below(md){
                    width: 32px;
                    height: 32px;
                }
            }
            .guest-off{
                background: $light;
                color: $dark;
            }
            
        }
        .call-overlay-sidetop{
            position: absolute;
            padding-top: 9px;
            padding-right: 12px;
            top: 0;
            right: 0;
        }
        .call-overlay-sidedown{
            position: absolute;
            bottom: 8px;
        }
    }
    .others{
        display: block;
        @include respond-below(sm){
            display: block;
            .call-img{
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
}
.chat-active-users {
    width: 0;
    opacity: 0;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    &.show-active-users {
        margin-left: 24px;
        width: 170px;
        opacity: 1;
    }
}
.guest-off {
    &.activate {
        background: $primary !important;
        color: $white !important;
    }
}
.right-user-side.chat-rooms.open-chats {
    margin-left: 24px;
}
.right-user-side.chat-rooms.open-chats {
    @include respond-below(xl){
        width: 80%;
    }
    @include respond-below(md){
        width: 100%;
        margin-left: 0;
    }
}
.main-img {
    &.main-img-hide {
        @include respond-below(md){
            display: none;
            width: 0;
        }
    }
}
.join-video {
    &.main-img-hide {
        @include respond-below(md){
            display: none;
            width: 0;
        }
    } 
}
.chat-user-tabs {
    .nav-item {
        .nav-link {
            &.active {
                background: $secondary !important;
                border-color: $secondary !important;
            }
        }
    }
}
