.settings-page {
  background-color: #f5f7f9;
}

.settings-card {
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
}

.settings-tabs {
  min-height: 400px;
}

.tab-content {
  padding: 16px 24px;
}

.section-title {
  margin-bottom: 8px;
}

.section-description {
  display: block;
  margin-bottom: 24px;
  opacity: 0.7;
}

.setting-card {
  height: 100%;
  transition: all 0.3s;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.03);
}

.setting-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.info-icon {
  color: #1890ff;
  margin-left: 5px;
  cursor: help;
}

.days-checkbox-group {
  margin-top: 8px;
}

.day-checkbox {
  display: flex;
  align-items: center;
  height: 32px;
}

.settings-actions {
  margin-top: 30px;
  padding: 16px;
  border-top: 1px solid #f0f0f0;
  text-align: center;
}

.save-settings-btn {
  padding: 0 40px;
  height: 42px;
  font-weight: 500;
}

.breadcrumb-container {
  display: flex;
  align-items: center;
}

.breadcrumb-text {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
}

.breadcrumb-text.active {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}

@media (max-width: 768px) {
  .settings-tabs {
    min-height: auto;
  }
  
  .setting-card {
    margin-bottom: 16px;
  }
} 