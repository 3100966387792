.email-sidebar {
  max-width: 288px;
  .slimScrollDiv {
    height: calc(100vh - 105px) !important;
  }
  @include respond-below(md) {
    max-width: 100%;
    margin-bottom: 24px;
  }
}
.mail-notifications {
  .slimScrollDiv {
    height: calc(100vh - 105px) !important;
  }
}
.mail-detail {
  .slimScrollDiv {
    height: calc(100vh - 137px) !important;
  }
}
.email-tags {
  a {
    &.active {
      background: $dark-transparent;
      color: $gray-900;
    }
    &:hover {
      background: $dark-transparent;
      color: $gray-900;
    }
    &.viewall-button {
      &:hover {
        background: transparent;
        color: $primary;
      }
    }
  }
}
.more-menu {
  display: none;
}
.box-bg {
  .bg-right {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .bg-left {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}
.custom-nav {
  display: flex;
  align-items: center;
  button {
    width: 16px;
    height: 16px;
    background: $white;
    border: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: $dark;
      color: $white;
    }
  }
}
.mails-list {
  .list-group-item {
    cursor: pointer;
    &.activate {
      background: $dark-transparent;
    }
  }
}
.mail-notifications {
  width: 50%;
  transition: 0.5s all;
  &.compress-width {
    max-width: 400px;
    transition: 0.5s all;
    @include respond-below(md) {
      display: none;
    }
  }
  @include respond-below(md) {
    width: 100%;
    max-width: 100%;
  }
}
.input-icon {
  .form-control {
    padding-left: 30px;
  }
}
.tag-with-img {
  .bootstrap-tagsinput {
    border: 0;
    padding: 0;
      .label-info {
          padding: 5px 10px 5px 30px;
          position: relative;
          background: $dark-transparent;
          border-radius: 20px;
          &::before {
              content: "";
              background-image: url(../../../../public/assets/img/profiles/mail-user.jpg);
              background-repeat: no-repeat;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              position: absolute;
              left: 10px;
              top: 50%;
              transform: translateY(-50%);
          }
      }
  }
}
.email-cc {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.todo-tabs {
  .nav-link {
    &.active {
      background: $white;
      box-shadow: 0px 2px 5px 0px #0000001A;
      color: $dark;
    }
  }
}
.list-item-hover {
  border: 1px solid $white !important;
  transition: 0.5s all;
  &:hover {
    border-color: $primary !important;
    transition: 0.5s all;
  }
}
.w-120 {
  width: 120px;
}
.project-status {
  min-width: 265px;
}
.kanban-drag-wrap {
  min-width: 230px;
  min-height: 50px;
}
.files-list {
  a.active {
    background: $dark-transparent;
  }
}
.social-gallery-slider,.email-attach {
  a {
    position: relative;
    transition: 0.5s all;
    span {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translatex(-50%);
      transition: 0.5s all;
      background: $white;
      opacity: 0;
      i {
        color: $dark;
        opacity: 1;
      }
    }
    &:hover {
      transition: 0.5s all;
      span {
        top: 50%;
        transform: translate(-50%,-50%);
        transition: 0.5s all;
        opacity: 0.5;

      }
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background: $gray-900;
        opacity: 0.8;
        position: absolute;
        transition: 0.5s all;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
    img {
      transition: 0.5s all;

    }
  }
}
.card-img-hover {
  position: relative;
  overflow: hidden;
  transition: 0.5s all;
  img {
    transition: 0.5s all;
  }
  &:hover {
    transition: 0.5s all;
    img {
      transform: scale(1.14);
      transition: 0.5s all;
    }
  }
}
.template-more-links {
  a {
    position: relative;
    & + a {
      margin-left: 10px;
      padding-left: 10px;
      &::before {
        content: "/";
        position: absolute;
        left: -2px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.post-textarea {
  padding-top: 60px;
  padding-left: 68px;
}
.text-area-avatar {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
a {
  &.link-hover {
    &:hover {
      color: $primary !important;
    }
  }
}
.note-editable {
  height: 100px;
}
.list-group a:hover {
  background: transparent;
}

.drag-upload-btn {
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  background: $white;
  border: 1px dashed $light-900;
  @include rounded(5px);
  padding:6px 16px ;
  position: relative;
  transition: all 0.5s;
  input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
  }
  &:hover {
      background: $primary;
      border-color: $primary;
      color: $white;
      transition: all 0.5s;
  }
}
#compose-view {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  position: fixed;
  z-index: 9999;
  display: none;
  transform: scale(0.9);
 transition: opacity 0.3s ease, transform 0.3s ease;
 align-items: end;
 justify-content: end;
  &.show {
    display: flex;
    transform: scale(1);
  }
}
.compose-view {
  position: relative;
  top: auto;
  bottom: 0;
    right: 20px;
    max-width: 500px;
    width: 100%;
    left: auto;
    margin: 20px 0 20px auto;
    transform: scale(0.9);
   transition: opacity 0.3s ease, transform 0.3s ease;
}
.compose-header {
  @include rounded(4px 4px 0 0);
}
.compose-footer {
  @include rounded(0 0 4px 4px);
}
.otp-input {
	input{
		border: 2px solid $gray-200;
		&.active{
			border: 2px solid $primary ;
		}
	}
}
.todo-tabs .nav-link:active {
  color: $white;
}
